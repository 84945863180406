import clsx from "clsx";
import CircularSpinner from "../../Components/Theme/Spinners/CircularSpinner";

export const GovGRCTAButton = ({
  className,
  disabled,
  label,
  onClick,
  loading,
}) => {
  const buttonClass = clsx([
    "govgr-btn mb-0 mt-5 bg-[#003375] text-[#FFFF] govgr-btn-cta hover:bg-blue-700 w-80 relative",
    disabled && "bg-gray-600 hover:bg-gray-700 cursor-not-allowed",
    className,
  ]);

  return (
    <button className={buttonClass} disabled={disabled} onClick={onClick}>
      <p className="flex justify-center items-center w-full">{label}</p>
      {loading ? (
        <CircularSpinner
          size="20px"
          color="inherit"
          sx={{ marginRight: "15px" }}
        />
      ) : (
        <svg
          viewBox="0 0 24 24"
          className="govgr-arrow--right govgr-svg-icon"
          focusable="false"
          aria-hidden="true"
        >
          <path
            d="M8.5,2L6.1,4.3l7.6,7.7l-7.6,7.6L8.5,22l10-10L8.5,2z"
            fill="white"
          />
        </svg>
      )}
    </button>
  );
};
