import React from "react";
import clsx from "clsx";

const GovSelect = ({
  className,
  containerClass,
  disabled = false,
  name,
  label,
  value,
  options,
  onChange,
}) => {
  const selectClass = clsx("govgr-select", className);
  const containerClasses = clsx(["govgr-field", containerClass]);

  return (
    <div className={containerClasses}>
      <select
        className={selectClass}
        name={name}
        label={label}
        value={value}
        onChange={onChange}
        disabled={disabled}
      >
        <option value="" disabled>
          {label || "Επιλέξτε..."}
        </option>

        {options?.map((option) => (
          <option key={option?.value} value={option?.value}>
            {option?.label}
          </option>
        ))}
      </select>
    </div>
  );
};

export default GovSelect;
