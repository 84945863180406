import React, { useEffect, useState } from "react";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import dayjs from "dayjs";
import "dayjs/locale/el";

const MyDatePicker = ({
  defaultValue,
  value,
  onChange,
  placeholder,
  format = "DD-MM-YYYY",
  className,
  error,
  helperText,
  disabled,
  isBirthDate = false,
}) => {
  const initialDate = value ? dayjs(value).toDate() : null;
  const [selectedDate, setSelectedDate] = useState(initialDate);
  const currentYear = new Date().getFullYear();
  const maxYear = currentYear - 16;
  const maxDate = dayjs(`${maxYear}-12-31`).startOf("day");

  const handleChange = (date) => {
    setSelectedDate(date);
    const formattedDate = date ? dayjs(date).format("DD-MM-YYYY") : null;
    onChange(formattedDate);
  };

  useEffect(() => {
    dayjs.locale("el");
  }, []);

  return (
    <>
      <LocalizationProvider dateAdapter={AdapterDayjs} locale="el">
        <DatePicker
          defaultValue={defaultValue ? dayjs(defaultValue, "DD-MM-YYYY") : null}
          disabled={disabled}
          placeholder={placeholder}
          selectedDate={selectedDate}
          onChange={handleChange}
          format={format}
          className={className}
          maxDate={isBirthDate ? maxDate : null}
        />
        {error && (
          <div
            style={{
              color: "#D32F2F",
              fontSize: "10px",
              marginTop: "6px",
              marginLeft: "17px",
            }}
          >
            {helperText}
          </div>
        )}
      </LocalizationProvider>
    </>
  );
};

export default MyDatePicker;
