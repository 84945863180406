// theme constant
export const gridSpacing = 3;
export const drawerWidth = 0;
export const appDrawerWidth = 320;
export const defaultLanguage = "el";

export const encryptionKey = "niebwfi&2e2dDW(#435";
export const baseUrl = process.env.REACT_APP_BASE_URL;
export const authUrl = "Auth";

export const tokenKey = "token";
export const accessToken = "accessToken";
export const refreshTokenKey = "refresh_token";
export const languageKey = "language";
export const organizationId = process.env.REACT_APP_ORGANIZATION_ID;

export function removeDiacritics(text) {
  return text?.normalize("NFD")?.replace(/[\u0300-\u036f]/g, "");
}

export function removeSingleQuotes(text) {
  return text?.replace(/'/g, "");
}

export function normalizeSearchQuery(query) {
  return removeSingleQuotes(removeDiacritics(query))?.toLowerCase()?.trim();
}
