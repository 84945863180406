import { action, makeObservable, observable } from "mobx";
import { createContext, useContext } from "react";
import AuthSetAgent from "../Api/AuthSetAgent";

class DepartmentStore {
  seatsOptions = [];
  certificatesSeatsOptions = [];
  selectedSeat = undefined;

  constructor() {
    makeObservable(this, {
      seatsOptions: observable,
      selectedSeat: observable,
      certificatesSeatsOptions: observable,
      setSelectedSeat: action,
      setSeatOptions: action,
      getDepartmentsBySubOrgAndAppCategory: action,
      getDepartmentsBySubOrganizationAndCertificateId: action,
    });
  }

  setSelectedSeat(seat) {
    this.selectedSeat = seat;
  }

  setSeatOptions(options) {
    this.selectedSeat = options;
  }

  getDepartmentsBySubOrganizationAndCertificateId(
    subOrganizationId,
    certificateId,
    pageNumber = null,
    pageSize = null
  ) {
    return AuthSetAgent.department
      .getDepartmentsBySubOrganizationAndCertificateId(
        subOrganizationId,
        certificateId,
        pageNumber,
        pageSize
      )
      .then((response) => {
        this.certificatesSeatsOptions = response?.result?.items;
      })
      .catch((error) => {});
  }

  getDepartmentsBySubOrgAndAppCategory(
    subOrganizationId,
    applicationCategoryId,
    pageNumber = null,
    pageSize = null
  ) {
    return AuthSetAgent.department
      .getDepartmentsBySubOrgAndAppCategory(
        subOrganizationId,
        applicationCategoryId,
        pageNumber,
        pageSize
      )
      .then((response) => {
        this.seatsOptions = response.result.items;
      })
      .catch((error) => {});
  }
}

export const departmentStore = new DepartmentStore();
export const DepartmentStoreContext = createContext(departmentStore);
export const useDepartmentStore = () => useContext(DepartmentStoreContext);
