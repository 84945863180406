import { action, makeObservable, observable } from "mobx";
import { createContext, useContext } from "react";
import AuthSetAgent from "../Api/AuthSetAgent";

class CertificatesStore {
  certificatesSubmissionList = [];

  certificatesList = [];

  formFieldsList = [];

  formFieldsValues = [];

  currentCertificate = {};

  subOrganizationId = null;

  certificateId = null;

  totalSubmissions = null;

  totalSubmittedCertificates = null;

  uploadedFileResp = {};

  statusTranslations = {
    0: "Αποθηκευμένη",
    1: "Υποβληθείσα",
    2: "Ακυρωμένη",
    3: "Σε εξέλιξη",
    4: "Ολοκληρωμένη",
    5: "Σε εκκρεμότητα",
    6: "Απορριφθείσα",
  };

  constructor() {
    makeObservable(this, {
      certificatesSubmissionList: observable,
      certificatesList: observable,
      formFieldsList: observable,
      subOrganizationId: observable,
      certificateId: observable,
      totalSubmissions: observable,
      totalSubmittedCertificates: observable,
      statusTranslations: observable,
      formFieldsValues: observable,

      getCertificateSubmission: action,
      startCertificateSubmission: action,
      getCertificatesUnderSubOrganization: action,
      setSubOrganizationId: action,
      setCertificateId: action,
      getTotalSubmissions: action,
      getFormFieldsByCertificateSubmissionId: action,
      getTotalSubmittedCertificates: action,
      setFormFieldsList: action,
      setCurrentCertificate: action,
      getCurrentCertificate: action,
      getFormFieldsValues: action,
      getEmptyFormFields: action,
      getCertificateSubmissionById: action,
      completeCertificateSubmission: action,
      uploadFile: action,
      setUploadedFileResp: action,
      removeFile: action,
      dowloadFile: action,
    });
  }

  getCertificatesUnderSubOrganization(id) {
    AuthSetAgent.certificates
      .getCertificatesUnderSubOrganization(id)
      .then((certificates) => {
        this.certificatesList = certificates.result;
      })
      .catch((e) => {});
  }

  getCertificateById(certificateId) {
    return AuthSetAgent.certificates.getCertificateById(certificateId);
  }

  setSubOrganizationId(subOrganizationId) {
    this.subOrganizationId = subOrganizationId;
  }

  setCertificateId(certificateId) {
    this.certificateId = certificateId;
  }

  setFormFieldsList(formFieldsList) {
    this.formFieldsList = formFieldsList;
  }

  setCurrentCertificate(certificate) {
    this.currentCertificate = certificate;
  }

  getCurrentCertificate() {
    return this.currentCertificate;
  }

  getTotalSubmissions = () => {
    AuthSetAgent.certificateSubmission
      .getTotalSubmissions()
      .then((totalSubmissions) => {
        this.totalSubmissions = totalSubmissions.result;
      })
      .catch((e) => {});
  };

  getTotalSubmittedCertificates = (pageNumber = null, pageSize = null) => {
    AuthSetAgent.certificateSubmission
      .getCertificateSubmission(pageNumber, pageSize)
      .then((certificateSubmissions) => {
        this.totalSubmittedCertificates =
          certificateSubmissions.result.items.filter(
            (certificateSubmission) =>
              certificateSubmission.status !== 0 &&
              certificateSubmission.status !== 2
          ).length;
      })
      .catch((error) => {
        console.error(error);
      });
  };

  getFormFieldsByCertificateSubmissionId = async (certificateSubmissionId) => {
    try {
      const formFields =
        await AuthSetAgent.certificateSubmission.getFormFieldsByCertificateSubmissionId(
          certificateSubmissionId
        );
      if (
        formFields?.result.components !== null &&
        formFields?.result.components !== undefined
      )
        this.formFieldsList = formFields?.result;
      else
        this.formFieldsList = {
          components: [],
          name: formFields?.result.name,
        };
      return formFields?.result.components !== null;
    } catch (error) {
      console.error(error);
      return false;
    }
  };

  getFormFieldsValues = async (certificateSubmissionId) => {
    try {
      const formFields =
        await AuthSetAgent.certificateSubmission.getFormFieldsValues(
          certificateSubmissionId
        );
      this.formFieldsValues = formFields?.result?.formSubmittedValues;
      return true;
    } catch (error) {
      console.error(error);
      return false;
    }
  };

  getEmptyFormFields = async (certificateSubmissionId) => {
    try {
      const formFields =
        await AuthSetAgent.certificateSubmission.getEmptyFormFields(
          certificateSubmissionId
        );
      this.formFieldsList = formFields?.result;
      return true;
    } catch (error) {
      console.error(error);
      return false;
    }
  };

  getCertificateSubmission = (
    pageNumber = null,
    pageSize = null,
    status = null
  ) => {
    return AuthSetAgent.certificateSubmission
      .getCertificateSubmission(pageNumber, pageSize, status)
      .then((certificateSubmissions) => {
        this.certificatesSubmissionList = certificateSubmissions.result.items;
        return certificateSubmissions;
      })
      .catch((error) => {
        console.error(error);
      });
  };

  getCertificateSubmissionById = async (certificateSubmissionId) => {
    return await AuthSetAgent.certificateSubmission
      .getCertificateSubmissionById(certificateSubmissionId)
      .then((certificateSubmission) => {
        this.currentCertificate = certificateSubmission.result;
      });
  };

  startCertificateSubmission = async (certificateId, departmentId) => {
    return await AuthSetAgent.certificateSubmission.startCertificateSubmission(
      certificateId,
      departmentId
    );
  };

  completeCertificateSubmission = async (certificateSubmissionId, data) => {
    return await AuthSetAgent.certificateSubmission.completeCertificateSubmission(
      certificateSubmissionId,
      data
    );
  };

  uploadFile = (businessKey, fieldName, certificateSubmissionId, formData) => {
    return AuthSetAgent.certificateSubmission
      .uploadFile(businessKey, fieldName, certificateSubmissionId, formData)
      .then((response) => {
        return response;
      })
      .catch((error) => {});
  };

  setUploadedFileResp = (response) => {
    this.uploadedFileResp = response?.result;
  };

  removeFile = (fileName) => {
    return AuthSetAgent.certificateSubmission
      .removeFile(fileName)
      .then((response) => {
        return response;
      })
      .catch((error) => {});
  };

  dowloadFile = (fileName) => {
    return AuthSetAgent.certificateSubmission
      .dowloadFile(fileName)
      .then((response) => {
        return response;
      })
      .catch((error) => {});
  };
}

export const certificatesStore = new CertificatesStore();
export const CertificatesStoreContext = createContext(certificatesStore);
export const useCertificatesStore = () => useContext(CertificatesStoreContext);
