import { observer } from "mobx-react";
import GovGrToolTipHeader from "../../../Components/GovGrToolTipHeader/GovGrToolTipHeader";
import { Box, CircularProgress, TextField } from "@mui/material";
import { useRantevouStore } from "../../../Stores/RantevouStore";
import { useNavigate } from "react-router";
import { useEffect, useState } from "react";
import Accordions from "../../../Components/Accordion/Accordion";
import IconText from "../../../Components/IconText/IconText";
import { normalizeSearchQuery, organizationId } from "../../../Config/constant";
import { departmentStore } from "../../../Stores/DepartmentStore";
import { useServicesStore } from "../../../Stores/ServicesStore";

const CertificationSelect = () => {
  const rantevouStore = useRantevouStore();
  const navigate = useNavigate();
  const [selectedCertificateId, setSelectedCertificateId] = useState(null);
  const [loading, setLoading] = useState(false);
  const [selectedSubOrganization, setSelectedSubOrganization] = useState(null);
  const [isAccordionOpen, setIsAccordionOpen] = useState(true);
  const [searchApplicationCategory, setSearchApplicationCategory] =
    useState("");
  const servicesStore = useServicesStore();
  const organizationName =
    parseInt(organizationId) === servicesStore.organizationById?.id
      ? servicesStore.organizationById?.name
      : "---";

  const loader = (
    <Box className="flex justify-center items-center p-20">
      <CircularProgress size={50} />
    </Box>
  );

  const onApplicationCategoryClick = (
    applicationCategoryId,
    subOrganizationId
  ) => {
    setSelectedCertificateId(applicationCategoryId);
    setSelectedSubOrganization(subOrganizationId);
  };

  const handleAccordionToggle = (isExpanded) => {
    setIsAccordionOpen(isExpanded);
  };

  const handleNext = () => {
    // clear selected seat in case one is already stored from previous "seat-selection" visit
    //
    departmentStore.setSelectedSeat(undefined);

    navigate("/seat-selection", {
      state: {
        selectedSubOrganization,
        selectedCertificateId,
      },
    });
  };

  const filteredApplicationCategories =
    rantevouStore.applicationCategories?.map((subOrg) => ({
      ...subOrg,
      applicationCategories: subOrg.applicationCategories?.filter(
        (certificate) => {
          const certificateTitleNormalized = normalizeSearchQuery(
            certificate?.title || ""
          );
          const searchQueryNormalized = normalizeSearchQuery(
            searchApplicationCategory
          );
          return certificateTitleNormalized.includes(searchQueryNormalized);
        }
      ),
    }));

  useEffect(() => {
    if (searchApplicationCategory && !isAccordionOpen) {
      setIsAccordionOpen(true);
    }
  }, [searchApplicationCategory, isAccordionOpen]);

  useEffect(() => {
    if (organizationId) {
      rantevouStore.getGroupedApplicationCategories(organizationId);
    }
  }, [rantevouStore]);

  useEffect(() => {
    if (organizationId) {
      setLoading(true);
      servicesStore
        .getOrganizationById(organizationId)
        .then((resp) => {})
        .catch((error) => {})
        .finally(() => {
          setLoading(false);
        });
    }
  }, [servicesStore]);

  return (
    <>
      <div>
        <GovGrToolTipHeader />
      </div>
      {loading ? (
        loader
      ) : (
        <>
          <div className="ml-40">
            <IconText
              onClick={() => navigate(-1)}
              text={"Ηλεκτρονικό αίτημα ραντεβού"}
            />
            <p className="govgr-body-l">
              Προγραμματίστε ηλεκτρονικά το ραντεβού σας για επίσκεψη στις
              αρμόδιες υπηρεσίες της <br /> {organizationName}.
            </p>
            <p className="govgr-body-l mt-12">
              Επιλέξτε την υπηρεσία για την οποία επιθυμείτε να προγραμματίσετε
              το ραντεβού σας απο την <br /> ακόλουθη{" "}
              <b>λίστα των υπηρεσιών:</b>
            </p>

            <div className="mt-6 mr-12">
              <TextField
                fullWidth
                placeholder="Πληκτρολογήστε για αναζήτηση ..."
                value={searchApplicationCategory}
                onChange={(e) => setSearchApplicationCategory(e.target.value)}
                sx={{
                  "& .MuiOutlinedInput-root": {
                    fontSize: "1.2rem",
                  },
                }}
              />
            </div>

            {filteredApplicationCategories?.map(
              (applicationCategory, index) => (
                <div key={index}>
                  <div className="flex items-center">
                    <Accordions
                      isOpen={isAccordionOpen}
                      text={applicationCategory?.name}
                      onToggle={handleAccordionToggle}
                    >
                      {applicationCategory?.applicationCategories?.map(
                        (certificate, index) => (
                          <p
                            onClick={() =>
                              onApplicationCategoryClick(
                                certificate?.id,
                                applicationCategory?.id
                              )
                            }
                            className={`p-1 text-[1.2rem] cursor-pointer ${
                              selectedCertificateId === certificate?.id
                                ? "ring-1 ring-transparent ring-offset-1 ring-blue-500 ring-offset-blue-200"
                                : ""
                            }`}
                            key={index}
                          >
                            ● {certificate?.title}
                          </p>
                        )
                      )}
                    </Accordions>
                  </div>
                </div>
              )
            )}

            {!selectedCertificateId ? (
              <button
                disabled
                className="govgr-btn bg-gray-200 text-[#FFFF] govgr-btn-cta mt-12"
              >
                <div className="mb-1">Συνέχεια</div>
                <svg
                  viewBox="0 0 24 24"
                  className="govgr-arrow--right govgr-svg-icon"
                  focusable="false"
                  aria-hidden="true"
                >
                  <path
                    d="M8.5,2L6.1,4.3l7.6,7.7l-7.6,7.6L8.5,22l10-10L8.5,2z"
                    fill="white"
                  />
                </svg>
              </button>
            ) : (
              <button
                onClick={handleNext}
                className="govgr-btn bg-[#003375] text-[#FFFF] govgr-btn-cta mt-12"
              >
                <div className="mb-1">Συνέχεια</div>
                <svg
                  viewBox="0 0 24 24"
                  className="govgr-arrow--right govgr-svg-icon"
                  focusable="false"
                  aria-hidden="true"
                >
                  <path
                    d="M8.5,2L6.1,4.3l7.6,7.7l-7.6,7.6L8.5,22l10-10L8.5,2z"
                    fill="white"
                  />
                </svg>
              </button>
            )}
          </div>
        </>
      )}
    </>
  );
};

export default observer(CertificationSelect);
