import { useCallback, useState, useEffect } from "react";
import { CloseOutlined } from "@mui/icons-material";
import { IconButton } from "@mui/material";
import { v4 as uuid } from "uuid";
import AuthSetAgent from "../../Api/AuthSetAgent";

const ImageGrid = ({ editable = false, images, isBase64, onRemove }) => {
  const [imageUrl, setImageUrl] = useState(null);
  const [imagesFetched, setImagesFetched] = useState(false);

  const downloadImage = (filename) => {
    return AuthSetAgent.tickets
      .dowloadTicketImage(filename)
      .then((response) => {
        if (!response?.data) {
          return null;
        }
        const blob = new Blob([response?.data], {
          type: response?.headers["content-type"],
        });
        const imageUrl = URL.createObjectURL(blob);
        setImageUrl((prevUrls) => ({
          ...prevUrls,
          [filename]: imageUrl,
        }));
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleRemove = useCallback(
    (image) => {
      if (!onRemove) {
        return;
      }

      onRemove(image);
    },
    [onRemove]
  );

  const getImageSrc = useCallback(
    (image) => {
      if (isBase64) {
        const base64Prefix = "data:image/png;base64,";
        if (image?.base64Value) {
          return base64Prefix + image.base64Value;
        } else if (imageUrl && imageUrl[image.fileName]) {
          return imageUrl[image.fileName];
        } else {
          return null;
        }
      }

      if (image?.src) {
        return image.src;
      }

      return URL?.createObjectURL(image);
    },
    [isBase64, imageUrl]
  );

  useEffect(() => {
    if (isBase64 && !imagesFetched) {
      images?.forEach((image) => {
        if (!image.base64Value && image.fileName) {
          downloadImage(image.fileName);
        }
      });
      setImagesFetched(true);
    }
  }, [images, isBase64, imagesFetched]);

  if (!images?.length) {
    return (
      <div className="flex items-center">
        <p className="govgr-body-s">Δεν υπάρχουν εικόνες διαθέσιμες.</p>
      </div>
    );
  }

  return (
    <div className="flex flex-wrap gap-5 mb-4">
      {images.map((image) => (
        <div
          className="border-4 border-dashed p-6 relative"
          key={image?.name || image?.fileName || uuid()}
        >
          <img
            className="object-contain mb-3"
            src={getImageSrc(image)}
            width="500px"
            alt={image?.name || image?.fileName}
          />
          {editable && onRemove && (
            <div className="absolute top-[-10px] right-[-10px] bg-white border-blue-400 border-[1px] rounded-full">
              <IconButton
                aria-label="delete"
                onClick={() => handleRemove(image)}
                variant="outlined"
              >
                <CloseOutlined />
              </IconButton>
            </div>
          )}
        </div>
      ))}
    </div>
  );
};

export default ImageGrid;
