import { action, makeObservable, observable } from "mobx";
import { createContext, useContext } from "react";
import AuthSetAgent from "../Api/AuthSetAgent";

class ServicesStore {
  services = [
    {
      title: "Ηλεκτρονικά ραντεβού",
      helperText: `Κλείστε ηλεκτρονικά το ραντεβού σας για την επίσκεψη στην αρμόδια υπηρεσία`,
    },
    {
      title: "Πιστοποιητικά / Βεβαιώσεις",
      helperText:
        "Ηλεκτρονική υποβολή αιτήσεων για έκδοση πιστοποιητικών, βεβαιώσεων, αδειών κλπ",
    },
    {
      title: "Αιτήματα πολιτών",
      helperText: `Ηλεκτρονική δήλωση προβλημάτων, βλαβών κλπ`,
    },
  ];

  organizationById = {};

  constructor() {
    makeObservable(this, {
      organizationById: observable,
      getOrganizationById: action,
    });
  }

  getOrganizationById(id) {
    return AuthSetAgent.organization
      .getOrganizationById(id)
      .then((resp) => {
        this.organizationById = resp?.result;
      })
      .catch((error) => {})
      .finally(() => {});
  }
}

export const servicesStore = new ServicesStore();
export const ServicesStoreContext = createContext(servicesStore);
export const useServicesStore = () => useContext(ServicesStoreContext);
