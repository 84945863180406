// material-ui
import { styled } from "@mui/material/styles";

// ==============================|| AUTHENTICATION 1 WRAPPER ||============================== //

const GuestContent = styled("div")(({ theme }) => ({
  backgroundColor: "#FFFFFF",
  minHeight: "100vh",
}));

export default GuestContent;
