import { Route, Routes, Navigate } from "react-router-dom";
import { observer } from "mobx-react";
import CertificatesDetails from "../../Pages/CertificatesDetails/CertificatesDetails";
import UserDetailsOverview from "../../Pages/Authenticated/UserDetailsOverview/UserDetailsOverview";
import ContactInfoConfirmation from "../../Pages/Authenticated/ContantInfoConfirmation/ContactInfoConfirmation";
import NoAccessCard from "../../Components/NoAccessCard/NoAccessCard";
import DashBoard from "../../Pages/Authenticated/DashBoard/DashBoard";
import RantevouResults from "../../Pages/Authenticated/RantevouResults/RantevouResults";
import RantevouDetails from "../../Pages/Authenticated/RantevouDetails/RantevouDetails";
import Rantevou from "../../Pages/Authenticated/Rantevou/Rantevou";
import MyRantevouList from "../../Pages/Authenticated/MyRantevouList/MyRantevouList";
import SeatSelection from "../../Pages/Authenticated/SeatSelection/SeatSelection";
import CertificationSelect from "../../Pages/Authenticated/CertificationSelect/CertificationSelect";
import Certificates from "../../Pages/Authenticated/Certificates/Certificates";
import CertificatesSubmissionList from "../../Pages/Authenticated/CertificatesSubmissionList/CertificatesSubmissionList";
import CertificatesSubmissionActionList from "../../Pages/Authenticated/CertificatesSubmissionActionList/CertificatesSubmissionActionList";
import Profile from "../../Pages/Authenticated/Profile/Profile";
import CertificateResults from "../../Pages/Authenticated/CertificateResults/CertificateResults";
import CertificatesSelection from "../../Pages/Authenticated/CertificatesSelection/CertificatesSelection";
import CertificatesSeatSelection from "../../Pages/Authenticated/CertificatesSeatSelection/CertificatesSeatSelection";
import CertificatesCreateForm from "../../Pages/Authenticated/CertificatesCreateForm/CertificatesCreateForm";
import Tickets from "../../Pages/Authenticated/Tickets/Tickets";
import TicketsMy from "../../Pages/Authenticated/TicketsMy/TicketsMy";
import TicketCreate from "../../Pages/Authenticated/TicketCreate/TicketCreate";
import TicketConfirm from "../../Pages/Authenticated/TicketConfirm/TicketConfirm";
import ContactInfoEdit from "../../Pages/Authenticated/ContactInfoEdit/ContactInfoEdit";
import EmailConfirmation from "../../Components/EmailConfirmation";
import TicketView from "../../Pages/Authenticated/TicketsMy/TicketView";
import MyRantevouTables from "../../Pages/Authenticated/MyRantevouTables/MyRantevouTables";

const AuthenticatedRoutes = () => {
  return (
    <Routes>
      <Route path="/" element={<UserDetailsOverview />} />
      <Route path="/dashboard" element={<DashBoard />} />
      <Route path="/user-confirmation" element={<ContactInfoConfirmation />} />
      <Route path="/email-confirmation" element={<EmailConfirmation />} />
      <Route exacth path="/profile" element={<Profile />} />
      <Route path="/schedule" element={<Rantevou />} />
      <Route path="/rantevou-details" element={<RantevouResults />} />
      <Route path="/rantevou" element={<RantevouDetails />} />
      <Route path="/my-rantevous" element={<MyRantevouTables />} />
      <Route path="/my-rantevous-list" element={<MyRantevouList />} />
      <Route path="/seat-selection" element={<SeatSelection />} />
      <Route path="/my-certificates" element={<Certificates />} />
      <Route
        path="/certificate-submissions"
        element={<CertificatesSubmissionList />}
      />
      <Route
        path="/certificates-submissions/:id"
        element={<CertificatesDetails />}
      />
      <Route
        path="/certificates-submissions-action"
        element={<CertificatesSubmissionActionList />}
      />
      <Route path="/certificate-results" element={<CertificateResults />} />
      <Route path="/certificates" element={<CertificatesSelection />} />
      <Route path="/appointment-services" element={<CertificationSelect />} />
      <Route
        path="/certificates-seat-selection"
        element={<CertificatesSeatSelection />}
      />
      <Route path="/certificates-create" element={<CertificatesCreateForm />} />

      <Route path="/my-tickets" element={<Tickets />} />
      <Route path="/tickets" element={<TicketsMy />} />
      <Route path="/tickets/:id" element={<TicketView />} />
      <Route path="/ticket-create" element={<TicketCreate />} />
      <Route path="/ticket-confirmation" element={<TicketConfirm />} />

      <Route path="/contact-info" element={<ContactInfoEdit />} />

      <Route path="/noAccessCard" element={<NoAccessCard />} />
      <Route path="*" element={<Navigate to="/noAccessCard" />} />
    </Routes>
  );
};

export default observer(AuthenticatedRoutes);
