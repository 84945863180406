import { observer } from "mobx-react";
import { useEffect, useState } from "react";
import GovGrHeader from "../../../Components/GovGrHeader/GovGrHeader";
import { useNavigate } from "react-router";
import ReactCodeInput from "react-code-input";
import {
  Box,
  CircularProgress,
  Modal,
  TextField,
  Typography,
} from "@mui/material";
import MainCard from "../../../Components/Theme/Cards/MainCard";
import { phoneRegex, emailRegex } from "../../../Common/Regex";
import { organizationId } from "../../../Config/constant";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { error, success } from "../../../Common/Toast/Toast";
import { useAuthStore } from "../../../Stores/AuthStore";
import AuthSetAgent from "../../../Api/AuthSetAgent";
import { useForm, Controller } from "react-hook-form";
import ArrowCircleLeftOutlinedIcon from "@mui/icons-material/ArrowCircleLeftOutlined";
import GovGrToolTipHeader from "../../../Components/GovGrToolTipHeader/GovGrToolTipHeader";
import { useServicesStore } from "../../../Stores/ServicesStore";
import CircularSpinner from "../../../Components/Theme/Spinners/CircularSpinner";
import { getCookie, setCookie } from "../../../Common/Storage/Cookies";
import { setBearerToken } from "../../../Common/HttpClient/HttpClient";

const codeInputStylingProps = {
  inputStyle: {
    fontFamily: "monospace",
    margin: "0 20px",
    MozAppearance: "textfield",
    width: "60px",
    borderRadius: "3px",
    fontSize: "2rem",
    height: "60px",
    paddingLeft: "20px",
    backgroundColor: "#F2F2F2",
    color: "#000000",
    border: "2px solid #13139F",
  },
};

const ContactInfoConfirmation = ({ isEdit = false }) => {
  const navigate = useNavigate();
  const authStore = useAuthStore();
  const [codeValue, setCodeValue] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [emailValue, setEmailValue] = useState("");
  const [phoneValue, setPhoneValue] = useState("");
  const [showEmailError, setShowEmailError] = useState(false);
  const [showPhoneError, setShowPhoneError] = useState(false);
  const [showEmailInfoText, setShowEmailInfoText] = useState(false);
  const [showPhoneInfoText, setShowPhoneEmailInfoText] = useState(false);
  const [isEmailSubmitted, setIsEmailSubmitted] = useState(false);
  const [isPhoneSubmitted, setIsPhoneSubmitted] = useState(false);
  const [isButtonClickable, setIsButtonClickable] = useState(false);
  const [cooldownTimer, setCooldownTimer] = useState(null);
  const [isCooldown, setIsCooldown] = useState(false);
  const [isEmailEditing, setIsEmailEditing] = useState(false);
  const [isphoneEditing, setIsPhoneEditing] = useState(false);
  const [emailLoading, setEmailLoading] = useState(false);
  const [phoneLoading, setPhoneLoading] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [profileResp, setProfileResp] = useState({});
  const [profileResp2, setProfileResp2] = useState({});
  const [emailState, setEmailState] = useState(
    authStore.authUser?.emailConfirmed
  );
  // const [emailNotVerified, setEmailNotVerified] = useState(false);
  const [phoneState, setPhoneState] = useState(
    authStore.authUser?.phoneNumberConfirmed
  );

  const [confirmationSmsResp, setConfirmationSmsResp] = useState(null);

  const isCodeComplete = codeValue?.length === 6;
  const threeMinutesInMilliseconds = 3 * 60 * 1000;

  const { control, setValue } = useForm();

  const servicesStore = useServicesStore();
  const organizationName =
    parseInt(organizationId) === servicesStore.organizationById?.id
      ? servicesStore.organizationById?.name
      : "---";

  const loader = (
    <Box className="flex justify-center items-center pl-4">
      <CircularProgress size={30} />
    </Box>
  );

  const onContinue = async () => {
    setIsLoading(true);
    const token = getCookie("token");
    const refreshToken = getCookie("refreshToken");
    const params = {
      token,
      refreshToken,
    };
    await AuthSetAgent.user
      .refreshToken(params)
      .then((resp) => {
        setCookie("token", resp?.token, 90);
        setCookie("refreshToken", resp?.refreshToken, 90);
        setBearerToken(resp?.token);
        navigate("/dashboard");
      })
      .catch((err) => {})
      .finally(() => {
        setIsLoading(false);
      });
  };

  const handleEditEmail = () => {
    setIsEmailEditing(true);
  };

  const handleEditPhone = () => {
    setIsPhoneEditing(true);
  };

  const handleCancelEditEmail = () => {
    setIsEmailEditing(false);
  };

  const handleCancelEditPhone = () => {
    setIsPhoneEditing(false);
  };

  const validateEmail = (value) => {
    const isValidEmail = emailRegex.test(value);
    return isValidEmail;
  };

  const handleResendSms = () => {
    const afm = authStore.authUser?.afm;
    const phoneNumber = phoneValue;

    const payload = {
      phoneNumber: phoneNumber,
    };

    setPhoneLoading(true);
    authStore
      .sendVerificationSms(afm, payload)
      .then((resp) => {
        if (resp?.success) {
          success("Στάλθηκε sms επιβεβαίωσης");
        } else {
          error("Κάτι πήγε στραβά κατά την αποστολή sms επιβεβαίωσης");
        }
        setIsButtonClickable(false);
      })
      .catch(() => {})
      .finally(() => {
        setPhoneLoading(false);
      });
  };

  const validatePhone = (value) => {
    const isValidPhone = phoneRegex.test(value);
    return isValidPhone;
  };

  const handleEmailSubmit = () => {
    setShowEmailError(true);

    if (!validateEmail(emailValue)) {
      return;
    }

    const payload = {
      afm: authStore.authUser?.afm,
      email: emailValue,
    };

    setEmailLoading(true);
    authStore
      .updateContactDetails(payload)
      .then((resp) => {
        if (resp?.result) {
          setEmailSubmitSuccess(true);
          setIsEmailSubmitted(true);
          setIsCooldown(true);
          setIsEmailEditing(false);
          success("Το email υποβλήθηκε και στάλθηκε email επιβεβαίωσης");

          return AuthSetAgent.user.getProfile();
        }
      })
      .then((profileResponse) => {
        setProfileResp(profileResponse?.userDto);
        authStore.setUserData(profileResponse?.userDto);
        setEmailState(profileResp?.emailConfirmed);
        sessionStorage.setItem("emailState", emailState);
      })
      .catch((error) => {})
      .finally(() => {
        setEmailLoading(false);
      });
  };

  const setEmailSubmitSuccess = (success) => {
    const button = document.getElementById("emailSubmitButton");
    if (success) {
      button.innerText = "Υποβολή";
      button.style.backgroundColor = "#636363";
    }
  };

  const handlePhoneSubmit = () => {
    setShowPhoneError(true);

    if (!validatePhone(phoneValue)) {
      return;
    }

    const payload = {
      afm: authStore.authUser?.afm,
      phoneNumber: phoneValue,
    };

    setPhoneLoading(true);
    authStore
      .updateContactDetails(payload)
      .then((resp) => {
        if (resp?.result) {
          handleModalOpen();
          setPhoneSubmitSuccess(true);
          setIsPhoneSubmitted(true);
          setIsPhoneEditing(false);

          if (resp?.success) {
            success("Το τηλέφωνο υποβλήθηκε και στάλθηκε sms επιβεβαίωσης");
          } else {
            error("Κάτι πήγε στραβά κατά την υποβολή του τηλεφώνου");
          }

          return AuthSetAgent.user.getProfile();
        }
      })
      .then((profileResponse) => {
        setProfileResp2(profileResponse?.userDto);
        if (isEdit) {
          authStore.setUserDataPhone(profileResponse?.userDto);
        }
        setPhoneState(profileResp2?.phoneNumberConfirmed);
        sessionStorage.setItem("phoneState", phoneState);
      })
      .catch((error) => {})
      .finally(() => {
        setPhoneLoading(false);
      });
  };

  const setPhoneSubmitSuccess = (success) => {
    const button = document.getElementById("phoneSubmitButton");

    if (success) {
      button.innerText = "Υποβλήθηκε";
      button.style.backgroundColor = "#006736";
      button.classList.add("cursor-not-allowed");
    } else {
      button.innerText = "Υποβολή";
      button.style.backgroundColor = "#636363";
    }
  };

  const handleCodeSubmission = () => {
    const afm = authStore.authUser?.afm;
    const code = codeValue;

    setPhoneLoading(true);
    authStore
      .confirmPhoneNumber(afm, code)
      .then((resp) => {
        if (resp?.phoneNumberConfirmed === true) {
          success("Επαληθεύσατε των κωδικό με επιτυχία");
          if (isEdit && resp?.phoneNumberConfirmed === true) {
            window.location.reload();
          }
        } else if (resp?.phoneNumberConfirmed === false) {
          error("Λάθος κωδικός επαλήθευσης παρακαλώ προσπαθήστε ξανά");
        } else if (!isEdit && resp?.phoneNumberConfirmed === true) {
          window.location.reload();
        } else {
        }
        setConfirmationSmsResp(resp?.phoneNumberConfirmed);
        setIsModalOpen(false);
        setCodeValue("");
      })
      .catch(() => {
        error("Κάτι πήγε στραβά κατά την επαλήθευση του κωδικού");
      })
      .finally(() => {
        setPhoneLoading(false);
      });
  };

  const handleModalOpen = () => {
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
    setCodeValue("");
  };

  const resendEmail = () => {
    const afm = authStore.authUser?.afm;
    const email = emailValue;

    const payload = {
      email: email,
    };

    setEmailLoading(true);
    authStore
      .sendVerificationEmail(afm, payload)
      .then((resp) => {
        success("Στάλθηκε email επιβεβαίωσης");
        setIsCooldown(true);
      })
      .catch(() => {})
      .finally(() => {
        setEmailLoading(false);
      });
  };

  const renderSmsVerificationModal = () => {
    return (
      <Modal
        className="flex items-center justify-center"
        open={isModalOpen}
        onClose={handleCloseModal}
      >
        <MainCard
          style={{
            borderRadius: "3rem",
            borderColor: "#0F3F7D",
            borderWidth: "2px",
            borderStyle: "solid",
          }}
          sx={{ backgroundColor: "#F2F2F2" }}
          className="m-4 max-w-[51vw] sm:min-w-[51vw] md:min-w-[51vw] lg:min-w-[51vw] sm:max-w-[51vw] md:max-w-[51vw] lg:max-w-[51vw]"
        >
          <Box className="flex items-center justify-center mb-10">
            <Typography
              className="govgr-body-l pt-4"
              variant="h4"
              fontWeight="bold"
            >
              Εισάγετε τον κωδικό επαλήθευσης:
            </Typography>
          </Box>
          <Box>
            <Box className="flex justify-center">
              <ReactCodeInput
                value={codeValue}
                onChange={(value) => setCodeValue(value)}
                type="text"
                fields={6}
                {...codeInputStylingProps}
              />
            </Box>
            <Box className="flex justify-center mt-12">
              <button
                onClick={handleCodeSubmission}
                disabled={!isCodeComplete}
                className={`govgr-btn bg-[#0E3D8B] text-[#FFFF] ${
                  isCodeComplete
                    ? ""
                    : "bg-gray-300 text-gray-500 cursor-not-allowed"
                }`}
              >
                <div className="mb-1">Επαλήθευση</div>
                {phoneLoading && loader}
              </button>

              <button
                onClick={handleResendSms}
                disabled={!isButtonClickable}
                className={`govgr-btn ${
                  isButtonClickable
                    ? "bg-[#0E3D8B]"
                    : "bg-[#636363] cursor-not-allowed"
                } text-[#FFFF] ml-4`}
              >
                <div className="mb-1">Επαναποστολή sms επαλήθευσης</div>
                {phoneLoading && loader}
              </button>
            </Box>
          </Box>
        </MainCard>
      </Modal>
    );
  };

  useEffect(() => {
    AuthSetAgent.user
      .getProfile()
      .then((resp) => {
        if (resp.userDto?.emailConfirmed === true) {
          setEmailState(true);
          setIsEmailSubmitted(true);
          // setEmailNotVerified(false);
        } else {
          setEmailState(false);
          setIsEmailSubmitted(false);
          // setEmailNotVerified(true);
        }
      })
      .catch((error) => {})
      .finally(() => {});
  }, []);

  useEffect(() => {
    if (organizationId) {
      setIsLoading(true);
      servicesStore
        .getOrganizationById(organizationId)
        .then((resp) => {})
        .catch((error) => {})
        .finally(() => {
          setIsLoading(false);
        });
    }
  }, [servicesStore]);

  useEffect(() => {
    if (isModalOpen && !isButtonClickable) {
      const timerId = setTimeout(() => {
        setIsButtonClickable(true);
      }, threeMinutesInMilliseconds);
      setCooldownTimer(timerId);
    }

    return () => {
      if (cooldownTimer) {
        clearTimeout(cooldownTimer);
      }
    };
  }, [isModalOpen, isButtonClickable]);

  useEffect(() => {
    let cooldownTimer;

    if (isCooldown) {
      cooldownTimer = setTimeout(() => {
        setIsCooldown(false);
      }, threeMinutesInMilliseconds);
    }

    return () => {
      if (cooldownTimer) {
        clearTimeout(cooldownTimer);
      }
    };
  }, [isCooldown]);

  useEffect(() => {
    if (authStore.authUser?.emailConfirmed) {
      setIsEmailSubmitted(true);
    }
  }, [authStore.authUser?.emailConfirmed]);

  useEffect(() => {
    if (authStore.authUser?.phoneNumberConfirmed) {
      setIsPhoneSubmitted(true);
    }
  }, [authStore.authUser?.phoneNumberConfirmed]);

  useEffect(() => {
    if (confirmationSmsResp) {
      setIsPhoneSubmitted(true);
    }
  }, [confirmationSmsResp]);

  useEffect(() => {
    if (authStore.authUser?.emailConfirmed) {
      setEmailState(true);
    }
  }, [authStore.authUser?.emailConfirmed]);

  useEffect(() => {
    if (authStore.authUser?.phoneNumberConfirmed) {
      setPhoneState(true);
    }
  }, [authStore.authUser?.phoneNumberConfirmed]);

  useEffect(() => {
    if (confirmationSmsResp) {
      setPhoneState(true);
    }
  }, [confirmationSmsResp]);

  useEffect(() => {
    const storedEmailState = sessionStorage.getItem("emailState");

    if (storedEmailState) {
      setEmailState(storedEmailState);
    }
  }, []);

  useEffect(() => {
    const storedPhoneState = sessionStorage.getItem("phoneState");

    if (storedPhoneState) {
      setPhoneState(storedPhoneState);
    }
  }, []);

  useEffect(() => {
    setValue("email", emailState ? authStore.authUser?.email : null);
    setValue(
      "phoneNumber",
      phoneState ? authStore.authUser?.phoneNumber : null
    );
  }, [
    setValue,
    authStore.authUser?.email,
    emailState,
    authStore.authUser?.phoneNumber,
    phoneState,
  ]);

  return (
    <>
      {isLoading ? (
        loader
      ) : (
        <>
          {renderSmsVerificationModal()}
          {!isEdit && (
            <div className="w-full">
              <GovGrHeader />
            </div>
          )}
          {isEdit && (
            <div className="w-full">
              <GovGrToolTipHeader />
            </div>
          )}
          <div className="ml-40">
            <div className="flex items-center">
              {isEdit && (
                <ArrowCircleLeftOutlinedIcon
                  onClick={() =>
                    !isEmailSubmitted ||
                    !isPhoneSubmitted ||
                    !phoneState ||
                    !emailState
                      ? null
                      : navigate(-1)
                  }
                  style={{
                    color: "#FFFF",
                    cursor:
                      !isEmailSubmitted ||
                      !isPhoneSubmitted ||
                      !phoneState ||
                      !emailState
                        ? "not-allowed"
                        : "pointer",
                    fill:
                      isEmailSubmitted &&
                      isPhoneSubmitted &&
                      phoneState &&
                      emailState
                        ? "#004a75"
                        : "#636363",
                    marginRight: "14px",
                    marginBottom: "10px",
                    fontSize: "50px",
                  }}
                  disabled={
                    !isEmailSubmitted ||
                    !isPhoneSubmitted ||
                    !phoneState ||
                    !emailState
                  }
                  fontSize="large"
                />
              )}

              {!isEdit && (
                <h1 className="govgr-heading-l mt-6">
                  Ηλεκτρονικές Υπηρεσίες {organizationName}
                </h1>
              )}

              {isEdit && (
                <h1 className="govgr-heading-l mt-6">Στοιχεία επικοινωνίας</h1>
              )}
            </div>

            {!isEdit && (
              <p className="govgr-body-l">
                Για να ολοκληρωθεί η εγγραφή σας στην εφαρμογή και να μπορέσετε
                να χρησιμοποιήσετε τις <br />
                υπηρεσίες, θα πρέπει να συμπληρωθούν και επιβεβαιωθούν τα
                παρακάτω στοιχεία <br />
                επικοινωνίας σας:
              </p>
            )}

            {isEdit && (
              <p className="govgr-body-l">
                Αν αλλάξετε τα στοιχεία επικοινωνίας σας (email, τηλέφωνο), θα
                πρέπει να επιβεβαιωθούν εκ νέου για την <br />
                πρόσβαση στις υπηρεσίες της εφαρμογής:
              </p>
            )}
            <div className="flex items-start mt-12">
              <div className="flex items-start w-[5.5rem]">
                <p className="govgr-body-l ml-2 mt-3">Email:</p>
              </div>

              <div className="ml-4 flex-grow flex items-start">
                <Controller
                  name="email"
                  control={control}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      defaultValue={
                        emailState ? authStore.authUser?.email : null
                      }
                      className="bg-[#F2F2F2] w-[25%]"
                      type="email"
                      onChange={(e) => {
                        field.onChange(e);
                        setEmailValue(e.target.value);
                      }}
                      error={showEmailError && !validateEmail(emailValue)}
                      helperText={
                        showEmailError &&
                        !validateEmail(emailValue) && (
                          <p className="text-red-500">
                            Καταχωρήστε έγκυρο email
                          </p>
                        )
                      }
                      disabled={!isEmailEditing && isEmailSubmitted}
                    />
                  )}
                />

                <div>
                  {!isEmailSubmitted && (
                    <button
                      id="emailSubmitButton"
                      className="govgr-btn bg-[#003375] text-[#FFFF] mt-1 ml-6"
                      onClick={handleEmailSubmit}
                      disabled={isEmailSubmitted}
                    >
                      <div className="flex items-center">
                        <div> Υποβολή</div>
                        {emailLoading && loader}
                      </div>
                    </button>
                  )}
                </div>

                <div>
                  {isEmailSubmitted && !isEmailEditing && (
                    <button
                      className="govgr-btn bg-[#003375] text-[#FFFF] mt-1 ml-5"
                      onClick={handleEditEmail}
                    >
                      <div>Επεξεργασία</div>
                    </button>
                  )}
                </div>

                {isEmailSubmitted && isEmailEditing && (
                  <>
                    <button
                      id="emailSubmitButton"
                      className="govgr-btn bg-[#003375] text-[#FFFF] mt-1 ml-6"
                      onClick={handleEmailSubmit}
                      disabled={emailLoading}
                    >
                      <div className="flex items-center">
                        <div> Υποβολή</div>
                        {emailLoading && loader}
                      </div>
                    </button>

                    <button
                      className="govgr-btn bg-red-600 text-[#FFFF] mt-1 ml-6"
                      onClick={handleCancelEditEmail}
                    >
                      <div>Ακύρωση</div>
                    </button>
                  </>
                )}

                <div>
                  {isEmailSubmitted && !emailState && !isEmailEditing && (
                    <button
                      className={`govgr-btn ${
                        !isCooldown
                          ? "bg-[#003375] w-[112%]"
                          : "bg-[#636363] cursor-not-allowed"
                      } w-[96%] text-[#FFFF] mt-1 ml-8`}
                      onClick={resendEmail}
                      disabled={isCooldown}
                    >
                      <div className="flex items-center">
                        <div>Επαναποστολή email επαλήθευσης</div>
                        {emailLoading && loader}
                      </div>
                    </button>
                  )}
                </div>

                {isEmailSubmitted && !emailState && !isEmailEditing && (
                  <div className="flex justify-center items-center">
                    <InfoOutlinedIcon
                      fontSize="large"
                      className={`ml-${
                        authStore.authUser?.emailConfirmed ? 20 : 5
                      } mt-3 mb-6 cursor-pointer`}
                      onClick={() => setShowEmailInfoText((prev) => !prev)}
                    />
                  </div>
                )}

                {showEmailInfoText && (
                  <p className="bg-gray-200 p-2 ml-6 mr-6">
                    Σε περίπτωση που δεν ενεργοποιήσετε το σύνδεσμο επαλήθευσης
                    στο email <br />
                    σας, μέσα σε 24 ώρες, τότε δεν επιβεβαιώνεται και θα πρέπει
                    να επιλέξετε <br />
                    "Επαναποστολή email" για να σας σταλεί εκ νέου email
                    επαλήθευσης
                  </p>
                )}
              </div>
            </div>

            {isEmailSubmitted && !emailState && (
              <label className="text-[#636363] text-[1rem] relative top-[-17px] ml-[129px]">
                Αναμονή Επαλήθευσης
              </label>
            )}

            {/* {emailNotVerified && !isEmailSubmitted && (
    <label className="text-[#FF0000] text-[1rem] relative top-[-17px] ml-[105px]">
      Κάτι πήγε στραβά κατά την επιβεβαίωση του email παρακαλώ προσπαθήστε
      ξανά
    </label>
  )} */}

            {isEmailSubmitted && emailState && (
              <label className="text-[#00A36C] text-[1rem] relative top-[-17px] ml-[129px]">
                Email επαληθευμένο
              </label>
            )}

            <div className="flex items-start mt-8">
              <div className="flex  items-start w-[5.5rem]">
                <p className="govgr-body-l ml-2 mt-3">Τηλ:</p>
              </div>

              <div className="ml-4 flex-grow flex items-start">
                <Controller
                  name="phoneNumber"
                  control={control}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      defaultValue={
                        phoneState ? authStore.authUser?.phoneNumber : null
                      }
                      className="bg-[#F2F2F2] w-[25%]"
                      type="text"
                      onChange={(e) => {
                        field.onChange(e);
                        setPhoneValue(e.target.value);
                      }}
                      error={showPhoneError && !validatePhone(phoneValue)}
                      helperText={
                        showPhoneError &&
                        !validatePhone(phoneValue) && (
                          <p className="text-red-500">
                            Ο αριθμός τηλεφώνου που καταχωρήσατε δεν είναι
                            έγκυρος
                          </p>
                        )
                      }
                      disabled={!isphoneEditing && isPhoneSubmitted}
                    />
                  )}
                />

                <div>
                  {!isPhoneSubmitted && (
                    <button
                      id="phoneSubmitButton"
                      className="govgr-btn bg-[#003375]  text-[#FFFF] mt-1 ml-6"
                      onClick={handlePhoneSubmit}
                      disabled={isPhoneSubmitted}
                    >
                      <div className="flex items-center">
                        <div> Υποβολή</div>
                        {phoneLoading && loader}
                      </div>
                    </button>
                  )}
                </div>

                <div>
                  {isPhoneSubmitted && !isphoneEditing && (
                    <button
                      className="govgr-btn bg-[#003375] text-[#FFFF] mt-1 ml-5"
                      onClick={handleEditPhone}
                    >
                      <div>Επεξεργασία</div>
                    </button>
                  )}
                </div>

                {isPhoneSubmitted && isphoneEditing && (
                  <>
                    <button
                      id="phoneSubmitButton"
                      className="govgr-btn bg-[#003375]  text-[#FFFF] mt-1 ml-6"
                      onClick={handlePhoneSubmit}
                      disabled={phoneLoading}
                    >
                      <div className="flex items-center">
                        <div> Υποβολή</div>
                        {phoneLoading && loader}
                      </div>
                    </button>

                    <button
                      className="govgr-btn bg-red-600 text-[#FFFF] mt-1 ml-6"
                      onClick={handleCancelEditPhone}
                    >
                      <div>Ακύρωση</div>
                    </button>
                  </>
                )}

                <div>
                  {isPhoneSubmitted && !phoneState && !isphoneEditing && (
                    <button
                      className="govgr-btn bg-[#003375] w-[98%] text-[#FFFF] mt-1 ml-8"
                      onClick={handleModalOpen}
                    >
                      <div>Επαλήθευση OTP</div>
                    </button>
                  )}
                </div>

                {isPhoneSubmitted && !phoneState && !isphoneEditing && (
                  <div className="flex justify-center items-center">
                    <InfoOutlinedIcon
                      fontSize="large"
                      className="ml-[37px] mt-3 mb-6 cursor-pointer"
                      onClick={() => setShowPhoneEmailInfoText((prev) => !prev)}
                    />
                  </div>
                )}

                {showPhoneInfoText && (
                  <p className="bg-gray-200 p-2 ml-6 mr-6">
                    Πληκτρολογήστε τον κωδικό επαλήθευσης που λάβατε με <br />
                    sms στο αντίστοιχο πεδίο και πατήστε "Επαλήθευση" για <br />
                    να επιβεβαιώσετε τα στοιχεία επικοινωνίας.
                  </p>
                )}
              </div>
            </div>

            {isPhoneSubmitted && !phoneState && (
              <label className="text-[#636363] text-[1rem] relative top-[-17px] ml-[129px]">
                Αναμονή Επαλήθευσης
              </label>
            )}

            {isPhoneSubmitted && phoneState && (
              <label className="text-[#00A36C] text-[1rem] relative top-[-17px] ml-[129px]">
                Τηλέφωνο επαληθευμένο
              </label>
            )}

            {!isEdit && (
              <div>
                <button
                  className={`govgr-btn bg-[${
                    isEmailSubmitted &&
                    isPhoneSubmitted &&
                    phoneState &&
                    emailState
                      ? "#0E3D8B"
                      : "#636363"
                  }] text-[#FFFF] govgr-btn-cta mt-12 ${
                    !isEmailSubmitted ||
                    !isPhoneSubmitted ||
                    !phoneState ||
                    !emailState
                      ? "cursor-not-allowed"
                      : ""
                  }`}
                  disabled={
                    !isEmailSubmitted ||
                    !isPhoneSubmitted ||
                    !phoneState ||
                    !emailState
                  }
                  onClick={onContinue}
                >
                  <div className="mb-1">Συνέχεια</div>
                  {isLoading ? (
                    <CircularSpinner
                      size="20px"
                      color="inherit"
                      sx={{ marginRight: "15px" }}
                    />
                  ) : (
                    <svg
                      viewBox="0 0 24 24"
                      className="govgr-arrow--right govgr-svg-icon"
                      focusable="false"
                      aria-hidden="true"
                    >
                      <path
                        d="M8.5,2L6.1,4.3l7.6,7.7l-7.6,7.6L8.5,22l10-10L8.5,2z"
                        fill="white"
                      />
                    </svg>
                  )}
                </button>
              </div>
            )}
          </div>
        </>
      )}
    </>
  );
};

export default observer(ContactInfoConfirmation);
