import userExclamation from "../../../Icons/userExclamation.svg";
import { formatDateInDateMonthYear } from "../../../Common/formatDate/formatDate";
import { getTicketStatusLiteral } from "../../../Config/TicketHelpers/TicketHelpers";
import CustomPagination from "../../../Components/CustomPagination/CustomPagination";
import { useNavigate } from "react-router-dom";

const RowDivider = () => <span className="mx-3 text-lg mb-1">|</span>;

const TicketsTable = ({
  onSelect,
  tickets,
  currentPage,
  setCurrentPage,
  totalPages,
}) => {
  const navigate = useNavigate();
  return (
    <section>
      <div className="pb-10 w-full border-b-2">
        {tickets?.map((item, index) => (
          <div
            key={item?.id}
            className="flex justify-between items-center my-3 w-full"
          >
            <div className="flex items-center">
              <img
                className="mr-5"
                width="55px"
                src={userExclamation}
                alt="ticket"
              />
              <span className="govgr-body-l  min-w-[60px]">{`Κωδ. ${item?.id}`}</span>
              <RowDivider />
              <span className="govgr-body-l">
                {!!item?.entityCreatedAt &&
                  formatDateInDateMonthYear(item.entityCreatedAt)}
              </span>
              <RowDivider />
              <span className="govgr-body-l">{item?.ticketCategoryTitle}</span>
              <RowDivider />
              {item?.ticketServiceTitle && (
                <>
                  <span className="govgr-body-l">
                    {item.ticketServiceTitle}
                  </span>
                  <RowDivider />
                </>
              )}
              <span className="govgr-body-l">
                {getTicketStatusLiteral(item?.status)}
              </span>
            </div>
            <button
              className="govgr-btn bg-[#003375] text-[#FFFF] mb-0 py-[4px] min-h-0"
              onClick={() => {
                onSelect(item?.id);
                navigate(`/tickets/${item?.id}`);
              }}
            >
              Προβολή
            </button>
          </div>
        ))}

        {currentPage && totalPages && (
          <CustomPagination
            className={"mt-4 flex justify-center items-center"}
            color={"#003375"}
            currentPage={currentPage}
            totalPages={totalPages}
            onPageChange={(page) => setCurrentPage(page)}
          />
        )}
      </div>
    </section>
  );
};

export default TicketsTable;
