import { observer } from "mobx-react";
import GovGrToolTipHeader from "../../../Components/GovGrToolTipHeader/GovGrToolTipHeader";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { useState, useEffect } from "react";
import { useServicesStore } from "../../../Stores/ServicesStore";
import { useNavigate } from "react-router";
import { useCertificatesStore } from "../../../Stores/CertificatesStore";
import { organizationId } from "../../../Config/constant";
import { useRantevouStore } from "../../../Stores/RantevouStore";
import AuthSetAgent from "../../../Api/AuthSetAgent";
import { Box, CircularProgress } from "@mui/material";

const DashBoard = () => {
  const certificatesStore = useCertificatesStore();
  const navigate = useNavigate();
  const servicesStore = useServicesStore();
  const rantevouStore = useRantevouStore();
  const [showHelperText, setShowHelperText] = useState([]);
  const [myTickets, setMyTickets] = useState([]);
  const [loading, setLoading] = useState(false);

  const loader = (
    <Box className="flex justify-center items-center p-20">
      <CircularProgress size={50} />
    </Box>
  );

  const organizationName =
    parseInt(organizationId) === servicesStore.organizationById?.id
      ? servicesStore.organizationById?.name
      : "---";

  const handleHelperClick = (index) => {
    setShowHelperText((prev) => {
      const updatedShowHelperText = [...prev];
      updatedShowHelperText[index] = !updatedShowHelperText[index];
      return updatedShowHelperText;
    });
  };

  useEffect(() => {
    certificatesStore.getTotalSubmittedCertificates();
  }, [certificatesStore]);

  useEffect(() => {
    rantevouStore.getRantevouAppointments();
  }, [rantevouStore.appointments?.length]);

  useEffect(() => {
    const getData = async () => {
      await AuthSetAgent.tickets
        .getMyTickets()
        .then((res) => {
          setMyTickets(res?.result.items || []);
        })
        .catch((e) => {
          console.error("Error in getMyTickets: ", e);
        });
    };

    getData();
  }, []);

  useEffect(() => {
    if (organizationId) {
      setLoading(true);
      servicesStore
        .getOrganizationById(organizationId)
        .then((resp) => {})
        .catch((error) => {})
        .finally(() => {
          setLoading(false);
        });
    }
  }, [servicesStore]);

  return (
    <div>
      <GovGrToolTipHeader />
      {loading ? (
        loader
      ) : (
        <>
          <div className="flex flex-col lg:flex-row w-full h-screen">
            <div className="w-full lg:w-2/3 md:ml-40 sm:ml-20 ml-10">
              <h1 className="govgr-heading-l mt-6">
                Ηλεκτρονικές Υπηρεσίες {organizationName}
              </h1>
              <p className="govgr-body-l mt-4">
                Καλώς ήρθατε στις ηλεκτρονικές υπηρεσίες της {organizationName}.
              </p>
              <div className="flex items-center">
                <p className="govgr-body-l mt-6 mb-6">
                  Επιλέξτε την <b>υπηρεσία</b> που σας ενδιαφέρει για να
                  συνεχίσετε:
                </p>
              </div>
              {servicesStore.services.map(({ title, helperText }, index) => (
                <div
                  key={index}
                  className="lg:relative w-full lg:w-11/12 flex flex-wrap mt-6"
                >
                  <div className="flex flex-col lg:flex-row w-full lg:w-[500px] items-start lg:items-center justify-center lg:justify-between">
                    <div className="flex items-center justify-end w-[500px]">
                      <button
                        onClick={() => {
                          if (title === "Ηλεκτρονικά ραντεβού") {
                            navigate("/my-rantevous");
                          } else if (title === "Πιστοποιητικά / Βεβαιώσεις") {
                            navigate("/my-certificates");
                          } else if (title === "Αιτήματα πολιτών") {
                            navigate("/my-tickets");
                          }
                        }}
                        className="govgr-btn justify-end w-full mb-0 bg-[#003375] text-[#FFFF] govgr-btn-cta hover:bg-blue-700"
                      >
                        <p className="flex justify-end items-right">{title}</p>
                        <svg
                          viewBox="0 0 24 24"
                          className="govgr-arrow--right govgr-svg-icon"
                          focusable="false"
                          aria-hidden="true"
                        >
                          <path
                            d="M8.5,2L6.1,4.3l7.6,7.7l-7.6,7.6L8.5,22l10-10L8.5,2z"
                            fill="white"
                          />
                        </svg>
                      </button>
                      <InfoOutlinedIcon
                        className="ml-4 cursor-pointer hover:text-blue-700"
                        fontSize="large"
                        onClick={() => handleHelperClick(index)}
                      />
                    </div>
                    {showHelperText[index] && (
                      <p className="bg-gray-200 p-2 mt-4 lg:mt-0 lg:absolute lg:ml-[510px]">
                        {helperText}
                      </p>
                    )}
                  </div>
                </div>
              ))}
            </div>
            <div className="w-full lg:w-[600px] px-12 lg:px-0 mb-6 lg-mb-0">
              <p className="govgr-body-l mt-12 lg:mt-56 flex justify-center items-center">
                Έχετε υποβάλλει:
              </p>
              <div className="p-4 pr-0 mt-2 w-full">
                <div className="flex flex-col space-y-8 rounded-l-full rounded-r-full lg:rounded-r-none bg-gray-200 pl-8 py-4 pr-4 w-full">
                  <div className="flex items-center ml-14 gap-4">
                    <div className="govgr-heading-l mb-0 text-[#003375] w-12">
                      {
                        rantevouStore.appointments?.filter(
                          (appointment) => appointment.status !== 4
                        ).length
                      }
                    </div>
                    <p className="govgr-body-l">Αιτήσεις για ραντεβού</p>
                  </div>
                  <div className="flex items-center ml-14 gap-4">
                    <div className="govgr-heading-l mb-0 text-[#003375] w-12">
                      {certificatesStore.totalSubmittedCertificates}
                    </div>
                    <p className="govgr-body-l">
                      Αιτήσεις έκδοσης πιστοποιητικών
                    </p>
                  </div>
                  <div className="flex items-center ml-14 gap-4">
                    <div className="govgr-heading-l mb-0 text-[#003375] w-12">
                      {myTickets?.length}
                    </div>
                    <p className="govgr-body-l">
                      Δηλώσεις διόρθωσης βλαβών κλπ
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default observer(DashBoard);
