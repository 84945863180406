import { useEffect, useState } from "react";
import { observer } from "mobx-react";
import { useNavigate } from "react-router-dom";
import { useAuthStore } from "../Stores/AuthStore";
import { Box, CircularProgress } from "@mui/material";

function Callback() {
  const navigate = useNavigate();
  const authStore = useAuthStore();
  const [loading, setLoading] = useState(false);

  const loader = (
    <Box className="flex justify-center items-center p-20">
      <CircularProgress size={50} />
    </Box>
  );

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const code = urlParams.get("code");
    // const state = urlParams.get("state");

    if (code) {
      setLoading(true);
      authStore
        .callback(code)
        .then((response) => {
          if (response) {
            navigate("/details-overview");
          }
        })
        .catch((error) => {})
        .finally(() => {
          setLoading(false);
        });
    } else {
      navigate("/login");
    }
  }, [navigate, authStore]);

  if (loading) {
    return loader;
  }
}

export default observer(Callback);
