import { observer } from "mobx-react";
import { useEffect } from "react";
import { useCertificatesStore } from "../../../Stores/CertificatesStore";
import GovGrToolTipHeader from "../../../Components/GovGrToolTipHeader/GovGrToolTipHeader";
import { useNavigate } from "react-router";
import IconText from "../../../Components/IconText/IconText";
import Loader from "../../../Components/Theme/Loader";
import { toJS } from "mobx";
import { useState } from "react";
import pistopoiitika from "../../../assets/pistopoiitika.png";
import { formatDateInDateMonthYear } from "../../../Common/formatDate/formatDate";
import CustomPagination from "../../../Components/CustomPagination/CustomPagination";

const PAGE_SIZE = 15;

const CertificatesSubmissionActionList = () => {
  const certificatesStore = useCertificatesStore();
  const navigate = useNavigate();
  const [
    certificatesSubmissionFilteredList,
    setCertificatesSubmissionFilteredList,
  ] = useState([]);
  const [loading, setLoading] = useState(false);
  const [totalPages, setTotalPages] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);

  const onViewClick = (certificate) => {
    navigate(`/certificates-submissions/${certificate.id}`, {
      state: {
        certificate: certificate,
        backToLink: "/certificates-submissions-action",
      },
    });
  };

  useEffect(() => {
    setCertificatesSubmissionFilteredList(
      certificatesStore.certificatesSubmissionList.filter(
        (item) => item.status === 5
      )
    );
    setLoading(false);
  }, [certificatesStore.certificatesSubmissionList]);

  useEffect(() => {
    setLoading(true);
    certificatesStore
      .getCertificateSubmission(currentPage, PAGE_SIZE, [5])
      .then((res) => {
        const totalPages = Math.ceil(
          res.result.totalCount / res.result.pageSize
        );
        setTotalPages(totalPages);
      });
  }, [certificatesStore, currentPage]);

  return (
    <>
      <div>
        <GovGrToolTipHeader />
      </div>
      <div className="ml-5 md:ml-40 flex flex-col">
        <IconText
          navigation={"/my-certificates"}
          text={"Τα πιστοποιητικά μου"}
        />
        <b>
          <h1 className="govgr-body-l ml-16 flex">
            Απαιτούμενες ενέργειες - Εκκρεμότητες{" "}
            <img
              src="info-icon-red.png"
              className="ml-4"
              width="33"
              alt="info icon"
            />
          </h1>
        </b>

        <div className="max-h-[600px] overflow-y-auto pr-4 mt-4 max-w-fit">
          {loading ? (
            <Loader />
          ) : certificatesSubmissionFilteredList?.length === 0 ? (
            <div className="flex items-center justify-center mt-4 ml-16">
              <div className="flex items-center">
                <div>
                  <p className="text-lg mb-2">Δεν υπάρχουν εκκρεμότητες</p>
                </div>
              </div>
            </div>
          ) : (
            <>
              {certificatesSubmissionFilteredList?.map((item) => (
                <div
                  key={item.id}
                  className="flex flex-col sm:flex-row items-center sm:items-center justify-start sm:justify-between mt-4 sm:ml-12"
                >
                  <div className="flex items-center sm:mr-32">
                    <img
                      className="w-16 h-16 mr-4"
                      src={pistopoiitika}
                      alt="pistopoiitika"
                    />
                    <div>
                      <p className="text-lg mb-2">
                        Κωδ. {item.id} | {item?.certificate?.name} | Ημερ.{" "}
                        {formatDateInDateMonthYear(
                          item?.createdAt.slice(0, 10)
                        )}
                      </p>
                      <p className="text-lg mb-2 font-bold">
                        Σχόλια: {item?.adminComment ?? "-"}{" "}
                      </p>
                    </div>
                  </div>
                  <div className="flex flex-col justify-self-start ml-20 mt-4 sm:mt-6">
                    <button
                      className="govgr-btn bg-[#003375] text-[#FFFF]"
                      onClick={() => onViewClick(toJS(item))}
                    >
                      Προβολή
                    </button>
                  </div>
                </div>
              ))}
              <CustomPagination
                className={"mt-4 flex justify-center items-center"}
                color={"#003375"}
                currentPage={currentPage}
                totalPages={totalPages}
                onPageChange={(page) => setCurrentPage(page)}
              />
            </>
          )}
        </div>
      </div>
    </>
  );
};

export default observer(CertificatesSubmissionActionList);
