import React from "react";

const Checkbox = ({ value, onChange, label, disabled }) => {
  const handleChange = (event) => {
    onChange(event.target.checked ? "ΝΑΙ" : "ΟΧΙ");
  };

  return (
    <div class="govgr-checkboxes__item">
      <label class="govgr-label text-base govgr-checkboxes__label">
        {label}
        <input
          className="govgr-checkboxes__input"
          type="checkbox"
          value={value}
          checked={value === "ΝΑΙ"}
          disabled={disabled}
          onChange={handleChange}
        />
      </label>
    </div>
  );
};

export default Checkbox;
