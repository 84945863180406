import { observer } from "mobx-react";
// material-ui
import { Box, CssBaseline } from "@mui/material";
import { styled, useTheme } from "@mui/material/styles";

// styles
const Main = styled("main", { shouldForwardProp: (prop) => prop !== "open" })(
  ({ theme, open }) => ({
    ...theme.typography.mainContent,
    ...(!open && {
      borderBottomLeftRadius: 0,
      borderBottomRightRadius: 0,
      transition: theme.transitions.create("margin", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      [theme.breakpoints.up("md")]: {
        // marginLeft: "20px",
        // marginRight: "20px",
        width: `100%`,
      },
      [theme.breakpoints.down("md")]: {
        // marginLeft: "20px",
        width: `100%`,
        // padding: "16px",
      },
      [theme.breakpoints.down("sm")]: {
        // marginLeft: "10px",
        width: `100%`,
        // padding: "16px",
        // marginRight: "10px",
      },
    }),
    ...(open && {
      transition: theme.transitions.create("margin", {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
      marginLeft: 0,
      borderBottomLeftRadius: 0,
      borderBottomRightRadius: 0,
      width: `100%`,
      // [theme.breakpoints.down("md")]: {
      //   marginLeft: "20px",
      // },
      // [theme.breakpoints.down("sm")]: {
      //   marginLeft: "10px",
      // },
    }),
  })
);

// ==============================|| MAIN LAYOUT ||============================== //

const AuthenticatedContent = ({ children }) => {
  const theme = useTheme();

  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />
      <Main theme={theme}>{children}</Main>
    </Box>
  );
};

export default observer(AuthenticatedContent);
