import { useState, useRef, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useTheme } from "@mui/material/styles";
import {
  Box,
  Chip,
  ClickAwayListener,
  Grid,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Paper,
  Popper,
} from "@mui/material";
import HomeOutlinedIcon from "@mui/icons-material/HomeOutlined";
import MainCard from "../Theme/Cards/MainCard";
import Transitions from "../Theme/Extended/Transitions";
import { observer } from "mobx-react";
import { useThemeStore } from "../../Stores/ThemeStore";
import { useAuthStore } from "../../Stores/AuthStore";
import pistopoiitika from "../../assets/pistopoiitika.png";
import contact from "../../assets/contact.png";
import AuthSetAgent from "../../Api/AuthSetAgent";

// ==============================|| PROFILE MENU ||============================== //

const ProfileToolTip = () => {
  const theme = useTheme();
  const themeStore = useThemeStore();
  const authStore = useAuthStore();
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const anchorRef = useRef(null);
  const handleLogout = () => {
    navigate("/");
    authStore.logout();
  };

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }
    setOpen(false);
  };

  const handleListItemClick = (event, route = "") => {
    themeStore.openMenuItem(route);
    handleClose(event);

    if (route && route !== "") {
      navigate(route);
    }
  };
  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const prevOpen = useRef(open);
  useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current.focus();
    }

    prevOpen.current = open;
  }, [open]);

  useEffect(() => {
    AuthSetAgent.user.getProfile().then(({ userDto, roles, claims }) => {
      const userObject = {
        ...userDto,
        roles,
        claims,
      };
      authStore.setAuthUser(userObject);
    });
  }, [authStore]);

  return (
    <>
      <div
        className="cursor-pointer"
        ref={anchorRef}
        aria-controls={open ? "menu-list-grow" : undefined}
        aria-haspopup="true"
        onClick={handleToggle}
      >
        <img width="35px" src="/user.png" alt="user" />
      </div>
      <Popper
        className="z-[1] rounded-[4px]"
        placement="bottom-end"
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        disablePortal
        popperOptions={{
          modifiers: [
            {
              name: "offset",
              options: {
                offset: [0, 14],
              },
            },
          ],
        }}
      >
        {({ TransitionProps }) => (
          <Transitions in={open} {...TransitionProps}>
            <Paper className="mt-2">
              <ClickAwayListener onClickAway={handleClose}>
                <MainCard
                  border={false}
                  elevation={16}
                  content={false}
                  boxShadow
                  shadow={theme.shadows[16]}
                >
                  <Box
                    sx={{ p: 1, paddingTop: 0, backgroundColor: "lightgray" }}
                  >
                    <List
                      component="nav"
                      sx={{
                        width: "100%",
                        maxWidth: 350,
                        minWidth: 300,
                        backgroundColor: "lightgray",
                        borderRadius: "10px",
                        [theme.breakpoints.down("md")]: {
                          minWidth: "100%",
                        },
                        "& .MuiListItemButton-root": {
                          mt: 0.5,
                        },
                      }}
                    >
                      {!authStore.authUser?.emailConfirmed ||
                      !authStore.authUser?.phoneNumberConfirmed ||
                      authStore.userDto?.emailConfirmed ||
                      authStore.userDto?.phoneNumberConfirmed ||
                      authStore.userDto2?.emailConfirmed ||
                      authStore.userDto2?.phoneNumberConfirmed ? null : (
                        <ListItemButton
                          sx={{ borderRadius: `4px` }}
                          selected={themeStore.isMenuItemOpen("/dashboard")}
                          onClick={(event) =>
                            handleListItemClick(event, "/dashboard")
                          }
                        >
                          <ListItemIcon>
                            <HomeOutlinedIcon fontSize="large" />
                          </ListItemIcon>
                          <ListItemText
                            primary={
                              <Grid item>
                                <span className="govgr-caption-l">
                                  Η αρχική μου
                                </span>
                              </Grid>
                            }
                          />
                        </ListItemButton>
                      )}

                      {!authStore.authUser?.emailConfirmed ||
                      !authStore.authUser?.phoneNumberConfirmed ||
                      authStore.userDto?.emailConfirmed ||
                      authStore.userDto?.phoneNumberConfirmed ||
                      authStore.userDto2?.emailConfirmed ||
                      authStore.userDto2?.phoneNumberConfirmed ? null : (
                        <ListItemButton
                          sx={{ borderRadius: `4px` }}
                          selected={themeStore.isMenuItemOpen("/profile")}
                          onClick={(event) =>
                            handleListItemClick(event, "/profile")
                          }
                        >
                          <ListItemIcon>
                            <img
                              width="35px"
                              src="/profile.png"
                              alt="profile"
                            />
                          </ListItemIcon>
                          <ListItemText
                            primary={
                              <Grid item>
                                <span className="govgr-caption-l">
                                  Το προφίλ μου
                                </span>
                              </Grid>
                            }
                          />
                        </ListItemButton>
                      )}

                      {!authStore.authUser?.emailConfirmed ||
                      !authStore.authUser?.phoneNumberConfirmed ||
                      authStore.userDto?.emailConfirmed ||
                      authStore.userDto?.phoneNumberConfirmed ||
                      authStore.userDto2?.emailConfirmed ||
                      authStore.userDto2?.phoneNumberConfirmed ? null : (
                        <ListItemButton
                          sx={{ borderRadius: `4px` }}
                          selected={themeStore.isMenuItemOpen("/my-rantevous")}
                          onClick={(event) =>
                            handleListItemClick(event, "/my-rantevous")
                          }
                        >
                          <ListItemIcon>
                            <img
                              width="35px"
                              src="/rantevou.png"
                              alt="rantevou"
                            />
                          </ListItemIcon>
                          <ListItemText
                            primary={
                              <Grid item>
                                <span className="govgr-caption-l">
                                  Τα ραντεβού μου
                                </span>
                              </Grid>
                            }
                          />
                        </ListItemButton>
                      )}

                      {!authStore.authUser?.emailConfirmed ||
                      !authStore.authUser?.phoneNumberConfirmed ||
                      authStore.userDto?.emailConfirmed ||
                      authStore.userDto?.phoneNumberConfirmed ||
                      authStore.userDto2?.emailConfirmed ||
                      authStore.userDto2?.phoneNumberConfirmed ? null : (
                        <ListItemButton
                          sx={{ borderRadius: `4px` }}
                          selected={themeStore.isMenuItemOpen(
                            "/my-certificates"
                          )}
                          onClick={(event) =>
                            handleListItemClick(event, "/my-certificates")
                          }
                        >
                          <ListItemIcon>
                            <img
                              width="35px"
                              src={pistopoiitika}
                              alt="pistopoiitika"
                            />
                          </ListItemIcon>
                          <ListItemText
                            primary={
                              <Grid item>
                                <span className="govgr-caption-l">
                                  Τα πιστοποιητικά μου
                                </span>
                              </Grid>
                            }
                          />
                        </ListItemButton>
                      )}

                      {!authStore.authUser?.emailConfirmed ||
                      !authStore.authUser?.phoneNumberConfirmed ||
                      authStore.userDto?.emailConfirmed ||
                      authStore.userDto?.phoneNumberConfirmed ||
                      authStore.userDto2?.emailConfirmed ||
                      authStore.userDto2?.phoneNumberConfirmed ? null : (
                        <ListItemButton
                          sx={{ borderRadius: `4px` }}
                          selected={themeStore.isMenuItemOpen("/my-tickets")}
                          onClick={(event) =>
                            handleListItemClick(event, "/my-tickets")
                          }
                        >
                          <ListItemIcon>
                            <img
                              width="35px"
                              src="/aithmata.png"
                              alt="aithmata"
                            />
                          </ListItemIcon>
                          <ListItemText
                            primary={
                              <Grid item>
                                <span className="govgr-caption-l">
                                  Τα αιτήματά μου
                                </span>
                              </Grid>
                            }
                          />
                        </ListItemButton>
                      )}

                      {!authStore.authUser?.emailConfirmed ||
                      !authStore.authUser?.phoneNumberConfirmed ||
                      authStore.userDto?.emailConfirmed ||
                      authStore.userDto?.phoneNumberConfirmed ||
                      authStore.userDto2?.emailConfirmed ||
                      authStore.userDto2?.phoneNumberConfirmed ? null : (
                        <ListItemButton
                          sx={{ borderRadius: `4px` }}
                          selected={themeStore.isMenuItemOpen("/contact-info")}
                          onClick={(event) =>
                            handleListItemClick(event, "/contact-info")
                          }
                        >
                          <ListItemIcon>
                            <img src={contact} width="28px" alt="contact" />
                          </ListItemIcon>
                          <ListItemText
                            primary={
                              <Grid item>
                                <span className="govgr-caption-l">
                                  Στοιχεία επικοινωνίας
                                </span>
                              </Grid>
                            }
                          />
                        </ListItemButton>
                      )}

                      <ListItemButton
                        sx={{ borderRadius: `4px` }}
                        selected={false}
                        onClick={handleLogout}
                      >
                        <ListItemIcon>
                          <img width="35px" src="/exodos.png" alt="exodos" />
                        </ListItemIcon>
                        <ListItemText
                          primary={
                            <span className="govgr-caption-l">Έξοδος</span>
                          }
                        />
                      </ListItemButton>
                    </List>
                  </Box>
                </MainCard>
              </ClickAwayListener>
            </Paper>
          </Transitions>
        )}
      </Popper>
    </>
  );
};

export default observer(ProfileToolTip);
