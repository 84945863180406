import { observer } from "mobx-react";
import GovGrToolTipHeader from "../../../Components/GovGrToolTipHeader/GovGrToolTipHeader";
import { organizationId } from "../../../Config/constant";
import { useLocation, useNavigate } from "react-router-dom";
import { formatDateInDateMonthYear } from "../../../Common/formatDate/formatDate";
import { useServicesStore } from "../../../Stores/ServicesStore";
import { useEffect } from "react";

const RantevouResults = () => {
  const { state } = useLocation();
  const navigate = useNavigate();
  const rantevouId = state?.appointmentId || (
    <span className="text-gray-500">Μη διαθέσιμο</span>
  );

  const appointmentData = state?.rantevouData;

  const servicesStore = useServicesStore();
  const organizationName =
    parseInt(organizationId) === servicesStore.organizationById?.id
      ? servicesStore.organizationById?.name
      : "---";

  useEffect(() => {
    if (organizationId) {
      servicesStore
        .getOrganizationById(organizationId)
        .then((resp) => {})
        .catch((error) => {})
        .finally(() => {});
    }
  }, [servicesStore]);

  return (
    <>
      <GovGrToolTipHeader />
      <div>
        <div className="flex justify-center items-center mt-16">
          <img className="w-20" src="success.png" alt="success" />
        </div>
        <div className="flex justify-center items-center mt-16 pl-8">
          <p className="govgr-body-l">
            Το ραντεβού σας έχει προγραμματιστεί για{" "}
            <b>
              {" "}
              {formatDateInDateMonthYear(
                appointmentData?.timeFrom.slice(0, 10)
              )}
              ,{" "}
            </b>{" "}
            ώρα{" "}
            <b>
              {" "}
              {appointmentData?.timeFrom.slice(11, 16)} -{" "}
              {appointmentData?.timeTo.slice(11, 16)},
            </b>{" "}
            <br />{" "}
            <div className="ml-[110px] pt-4">
              {" "}
              για την υπηρεσία <b>
                {" "}
                {appointmentData?.appointmentService}.{" "}
              </b>{" "}
            </div>
          </p>
        </div>
        <div className="flex justify-center items-center">
          {" "}
          <br />{" "}
          <p className="govgr-body-l mt-4">
            {" "}
            Θα λάβετε σχετικό email επιβεβαίωσης.{" "}
          </p>{" "}
        </div>
        <div className="flex justify-center items-center mt-6 pr-8">
          <b>
            {" "}
            <p className="govgr-body-l ml-4">
              Κωδικός Ραντεβού: {rantevouId}{" "}
            </p>{" "}
          </b>{" "}
        </div>
        <div className="flex justify-center items-center mt-6 pr-8">
          <p className="govgr-body-l ml-4">
            Για επιστροφή στην αρχική σελίδα των υπηρεσιών της{" "}
            {organizationName}, πατήστε
          </p>
          <div
            className="govgr-masthead ml-2"
            onClick={() => {
              navigate("/dashboard");
            }}
          >
            <span className="govgr-link text-[#003375] text-[21px]">εδώ</span>
          </div>
        </div>
      </div>
    </>
  );
};

export default observer(RantevouResults);
