import { useState } from "react";
import { useCertificatesStore } from "../../Stores/CertificatesStore";
import { error, success } from "../../Common/Toast/Toast";
import pistopoiitika from "../../assets/pistopoiitika.png";
import { useDropzone } from "react-dropzone";
import { Typography } from "@mui/material";
import CircularSpinner from "../Theme/Spinners/CircularSpinner";
import SubmitButton from "../Theme/Extended/SubmitButton";
import { observer } from "mobx-react";

const FileUploader = ({
  businessKey,
  fieldName,
  certificateSubmissionId,
  isEditMode = false,
  errors,
  helperText,
  onFileUploaded,
  formFieldsValues = {},
  certificateSubmissionAttachments = [],
  onLoadingChange,
}) => {
  const [uploadedFile, setUploadedFile] = useState(null);
  // const [fetchFile, setFetchFile] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isDeleted, setIsDeleted] = useState(false);
  const certificatesStore = useCertificatesStore();

  const handleUploadSuccess = (uploadedFileResp) => {
    setUploadedFile(uploadedFileResp);
    onFileUploaded(uploadedFileResp?.result?.fileName);
    certificatesStore.setUploadedFileResp(uploadedFileResp);
    if (uploadedFileResp?.success === true) {
      success("Το αρχείο ανέβηκε επιτυχώς");
    } else {
      error("Κάτι πήγε στραβά κατά την μεταφόρτωση του αρχείου");
    }
    setIsLoading(false);
    onLoadingChange(false);
  };

  const handleUploadError = (errorType) => {
    switch (errorType) {
      case "upload":
        error("Κάτι πήγε στραβά κατά τη μεταφόρτωση του αρχείου");
        break;
      case "delete":
        error("Kάτι πήγε στραβά κατά τη διαγραφή του αρχείου");
        break;
      default:
        error("Παρουσιάστηκε σφάλμα");
        break;
    }
    setIsLoading(false);
  };

  const onDrop = (acceptedFiles) => {
    const allowedFileTypes = ["application/pdf", "image/jpeg", "image/png"];

    if (!allowedFileTypes.includes(acceptedFiles[0].type)) {
      error("Επιτρέπονται μόνο αρχεία τύπου pdf, jpeg, και png.");
      return;
    }

    setIsLoading(true);
    onLoadingChange(true);

    const formData = new FormData();
    formData.append("file", acceptedFiles[0]);

    certificatesStore
      .uploadFile(businessKey, fieldName, certificateSubmissionId, formData)
      .then((uploadedFileResp) => {
        handleUploadSuccess(uploadedFileResp);
        certificatesStore.setUploadedFileResp(uploadedFileResp);
      })
      .catch((error) => {
        handleUploadError("upload");
      });
  };

  const handleRemoveFile = () => {
    const uploadedFileAdjusted = certificateSubmissionAttachments?.filter(
      (att) => att?.fileName?.includes(`${businessKey}_${fieldName}`)
    );

    const adjustedFileName = uploadedFileAdjusted[0]?.fileName;

    if (uploadedFile || !isEditMode || uploadedFileAdjusted) {
      setIsLoading(true);
      onLoadingChange(true);

      certificatesStore
        .removeFile(uploadedFile?.result?.fileName || adjustedFileName)
        .then((resp) => {
          if (resp?.success === true) {
            success("Το αρχείο διαγράφηκε με επιτυχία");
          } else {
            error("Κάτι πήγε στραβά κατά την διαγραφή του αρχείου");
          }
          setUploadedFile(null);
          onFileUploaded("");
          setIsDeleted(true);
          setIsLoading(false);
          onLoadingChange(false);
        })
        .catch((error) => {
          handleUploadError("delete");
        });
    }
  };

  const handleDownloadFile = () => {
    const uploadedFileAdjusted = certificateSubmissionAttachments?.filter(
      (att) => att?.fileName?.includes(`${businessKey}_${fieldName}`)
    );

    const adjustedFileName = uploadedFileAdjusted[0]?.fileName;

    if (uploadedFileAdjusted) {
      setIsLoading(true);

      certificatesStore
        .dowloadFile(uploadedFile?.result?.fileName || adjustedFileName)
        .then((resp) => {
          const dispositionHeader = resp?.headers["content-disposition"];
          const filenameMatch = dispositionHeader?.match(
            /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/
          );
          const fileName =
            filenameMatch && filenameMatch[1]?.replace(/['"]/g, "");

          const blob = new Blob([resp?.data], {
            type: resp?.headers["content-type"],
          });

          const url = URL.createObjectURL(blob);
          const a = document.createElement("a");
          a.href = url;
          a.download = fileName || `file`;
          document.body.appendChild(a);
          a.click();

          URL.revokeObjectURL(url);
        })
        .catch(() => {})
        .finally(() => {
          setIsLoading(false);
        });
    }
  };

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept: ".pdf, .jpg, .jpeg, .png",
    multiple: false,
  });

  return (
    <div className="min-h-[200px] flex flex-col justify-center items-center border-2 border-dashed p-4 cursor-pointer h-full">
      {isEditMode ? (
        <div className="flex justify-center items-center">
          <img src={pistopoiitika} width={80} alt="pistopiitika" />
        </div>
      ) : (
        <div {...getRootProps()}>
          {errors && (
            <Typography
              className="pt-2"
              variant="h5"
              justifyContent={"center"}
              alignItems={"center"}
              color="error"
            >
              {helperText}
            </Typography>
          )}
          <input {...getInputProps()} />
          <div className="flex justify-center items-center">
            <img src={pistopoiitika} width={80} alt="pistopiitika" />
          </div>
        </div>
      )}
      {isLoading ? (
        <div className="flex justify-center items-center">
          <CircularSpinner />
        </div>
      ) : (
        uploadedFile?.result && (
          <div className="flex flex-col items-center justify-center w-full">
            <div className="flex justify-center items-center flex-wrap gap-1 w-full">
              {/* <Typography
                className="pt-2"
                variant="h5"
                justifyContent={"center"}
                alignItems={"center"}
              >
                Όνομα αρχείου:
              </Typography>
              <Typography
                className="pt-2"
                variant="h5"
                justifyContent={"center"}
                alignItems={"center"}
                overflow={"hidden"}
              >
                {uploadedFile.result?.fileName}
              </Typography> */}
            </div>
            <div className="flex justify-center items-center gap-4 pt-2 flex-wrap">
              <SubmitButton
                sx={{
                  backgroundColor: "#0965D4",
                  borderRadius: "99px",
                  textTransform: "none",
                }}
                size={"small"}
                type="button"
                title={"Λήψη"}
                loading={isLoading}
                onClick={handleDownloadFile}
              />

              <SubmitButton
                loading={isLoading}
                sx={{
                  backgroundColor: "#B60202",
                  borderRadius: "99px",
                  textTransform: "none",
                }}
                size={"small"}
                type="button"
                title={"Διαγραφή"}
                onClick={handleRemoveFile}
              />
            </div>
          </div>
        )
      )}

      {!isEditMode && formFieldsValues && !isDeleted && !isLoading && (
        <div className="flex flex-col items-center justify-center w-full gap-4 min-h-[120px]">
          <div className="flex justify-center items-center flex-wrap gap-1 w-full">
            {certificateSubmissionAttachments?.map((attachment, index) => {
              const fileNameParts = attachment.fileName?.split("_");
              const extractedPart = fileNameParts.slice(1).join("_");

              if (extractedPart.includes(fieldName)) {
                return (
                  <div key={index}>
                    <SubmitButton
                      sx={{
                        backgroundColor: "#0965D4",
                        borderRadius: "99px",
                        textTransform: "none",
                      }}
                      size={"small"}
                      type="button"
                      title={"Λήψη"}
                      loading={isLoading}
                      onClick={handleDownloadFile}
                    />
                    <SubmitButton
                      loading={isLoading}
                      sx={{
                        backgroundColor: "#B60202",
                        borderRadius: "99px",
                        textTransform: "none",
                      }}
                      size={"small"}
                      type="button"
                      title={"Διαγραφή"}
                      onClick={handleRemoveFile}
                    />
                  </div>
                );
              } else {
                return null;
              }
            })}
          </div>
        </div>
      )}

      {isEditMode && !isLoading && (
        <div className="flex flex-col items-center justify-center w-full gap-4 min-h-[120px]">
          <div className="flex justify-center items-center flex-wrap gap-1 w-full">
            {certificateSubmissionAttachments?.map((attachment, index) => {
              const fileNameParts = attachment.fileName?.split("_");
              const extractedPart = fileNameParts.slice(1).join("_");

              if (extractedPart.includes(fieldName)) {
                return (
                  <div key={index}>
                    <SubmitButton
                      sx={{
                        backgroundColor: "#0965D4",
                        borderRadius: "99px",
                        textTransform: "none",
                      }}
                      size={"small"}
                      type="button"
                      title={"Λήψη"}
                      loading={isLoading}
                      onClick={handleDownloadFile}
                    />
                  </div>
                );
              } else {
                return null;
              }
            })}
          </div>
        </div>
      )}
    </div>
  );
};

export default observer(FileUploader);
