import { useCallback } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import GovGrToolTipHeader from "../../../Components/GovGrToolTipHeader/GovGrToolTipHeader";

const TicketConfirm = () => {
  const { state } = useLocation();
  const ticketId = state?.ticketId || (
    <span className="text-gray-500">Μη διαθέσιμος</span>
  );
  const ticketCategory = state?.ticketCategory || (
    <span className="text-gray-500">Μη διαθέσιμη</span>
  );

  return (
    <div>
      <GovGrToolTipHeader />
      <section className="w-screen mt-[150px] flex flex-col justify-center items-center text-center">
        <div className="flex justify-center items-center mb-5">
          <img className="w-20" src="success.png" alt="success" />
        </div>
        <p className="govgr-body-l mb-3">
          {" "}
          Η αίτηση σας για την κατηγορία <b>{ticketCategory} </b> έχει υποβληθεί
          επιτυχώς.
        </p>
        <h1 className="govgr-body-l mb-3">
          <p className="govgr-body-l">Θα λάβετε σχετικό email επιβεβαίωσης.</p>
        </h1>
        <h1 className="font-bold govgr-body-l mb-3">
          Κωδικός αιτήματος: {ticketId}
        </h1>
        <p className="govgr-body-l mb-3 max-w-[700px]">
          Μπορείτε να παρακολουθήσετε την εξέλιξη του αιτήματός σας επιλέγοντας
          από το μενού{" "}
          <a
            className="font-bold text-blue-700 hover:underline"
            href="/my-tickets"
          >
            "Tα αιτήματά μου"
          </a>
          .
        </p>
        <p className="govgr-body-l mb-3">
          Για επιστροφή στην αρχική σελίδα των υπηρεσιών της ΠΔΕ, πατήστε{" "}
          <a
            className="font-bold text-blue-700 hover:underline"
            href="/dashboard"
          >
            εδώ
          </a>
        </p>
      </section>
    </div>
  );
};

export default TicketConfirm;
