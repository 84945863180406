import { action, makeObservable, observable } from "mobx";
import { createContext, useContext } from "react";
import AuthSetAgent from "../Api/AuthSetAgent";
import { error } from "../../src/Common/Toast/Toast";

class ScheduleStore {
  scheduleData = [];
  appointmentServiceId = null;
  slotGroupId = null;

  constructor() {
    makeObservable(this, {
      scheduleData: observable,
      appointmentServiceId: observable,
      slotGroupId: observable,
      getScheduleByAppointmentServiceId: action,
      setAppointmentServiceId: action,
      setSlotGroupId: action,
      resetScheduleData: action,
    });
  }

  setSlotGroupId(slotGroupId) {
    this.slotGroupId = slotGroupId;
  }

  setAppointmentServiceId(appointmentServiceId) {
    this.appointmentServiceId = appointmentServiceId;
  }

  resetScheduleData() {
    this.scheduleData = [];
  }

  getScheduleByAppointmentServiceId(appointmentServiceId) {
    return AuthSetAgent.schedule
      .getScheduleByAppointmentServiceId(appointmentServiceId)
      .then((response) => {
        this.scheduleData = response.result;
      });
  }
}

export const scheduleStore = new ScheduleStore();
export const ScheduleStoreContext = createContext(scheduleStore);
export const useScheduleStore = () => useContext(ScheduleStoreContext);
