import { observer } from "mobx-react";
import ProfileToolTip from "../ProfileToolTip/ProfileToolTip";
import { Box, CircularProgress, IconButton } from "@mui/material";
import { useAuthStore } from "../../Stores/AuthStore";
import { organizationId } from "../../Config/constant";
import { useEffect, useState } from "react";
import { useServicesStore } from "../../Stores/ServicesStore";
import { useNavigate } from "react-router-dom";

const GovGrToolTipHeader = () => {
  const authStore = useAuthStore();
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const loader = (
    <Box className="flex justify-center items-center p-20">
      <CircularProgress size={50} />
    </Box>
  );

  const servicesStore = useServicesStore();
  const organizationName =
    parseInt(organizationId) === servicesStore.organizationById?.id
      ? servicesStore.organizationById?.name
      : " ";

  useEffect(() => {
    if (organizationId) {
      setLoading(true);
      servicesStore
        .getOrganizationById(organizationId)
        .then((resp) => {})
        .catch((error) => {})
        .finally(() => {
          setLoading(false);
        });
    }
  }, [servicesStore]);

  const navigateToHome = () => {
    navigate("/dashboard");
  };

  return (
    <header className="govgr-header">
      {loading ? (
        loader
      ) : (
        <>
          <Box display="flex">
            <div className="govgr-header__container md:ml-44 sm:ml-24 ml-14">
              <div
                className="govgr-header__content cursor-pointer"
                onClick={navigateToHome}
              >
                {/* <span>
                  <img
                    className="govgr-header-logo"
                    src="https://www.gov.gr/gov_gr_logo.svg"
                    alt="gov.gr logo"
                  />
                </span>
                <div className="govgr-header-divider w-1 h-12 bg-white mx-2" /> */}
                <div className="govgr-header-title mb-2">
                  {organizationName}
                </div>
              </div>
            </div>
            <div className="govgr-header-title mt-2">
              {authStore.authUser?.isCompany ? null : (
                <div className="govgr-header-name mr-6">
                  {authStore.authUser.name}
                </div>
              )}
              <div className="text-[20px]">{authStore.authUser.surname}</div>
            </div>
            <div className="flex justify-center items-center mr-[60px]">
              <ProfileToolTip />
            </div>
          </Box>
        </>
      )}
    </header>
  );
};

export default observer(GovGrToolTipHeader);
