import { observer } from "mobx-react";
import GovGrHeader from "../../../Components/GovGrHeader/GovGrHeader";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { useEffect, useState } from "react";
import { organizationId } from "../../../Config/constant";
import { useAuthStore } from "../../../Stores/AuthStore";
import { Box, CircularProgress } from "@mui/material";
import { useServicesStore } from "../../../Stores/ServicesStore";
import CircularSpinner from "../../../Components/Theme/Spinners/CircularSpinner";

const ElectronicServices = () => {
  const authStore = useAuthStore();
  const [showHelperText, setShowHelperText] = useState([]);
  const [loading, setLoading] = useState(false);
  const servicesStore = useServicesStore();
  const organizationName =
    parseInt(organizationId) === servicesStore.organizationById?.id
      ? servicesStore.organizationById?.name
      : "---";

  useEffect(() => {
    if (organizationId) {
      setLoading(true);
      servicesStore
        .getOrganizationById(organizationId)
        .then((resp) => {})
        .catch((error) => {})
        .finally(() => {
          setLoading(false);
        });
    }
  }, [servicesStore]);

  const loader = (
    <Box className="flex justify-center items-center p-20">
      <CircularProgress size={50} />
    </Box>
  );

  const handleHelperClick = (index) => {
    setShowHelperText((prev) => {
      const updatedShowHelperText = [...prev];
      updatedShowHelperText[index] = !updatedShowHelperText[index];
      return updatedShowHelperText;
    });
  };

  const initiateLogin = async () => {
    setLoading(true);
    await authStore
      .initiateTaxisLogin()
      .then((response) => {
        window.location.href = response;
      })
      .catch((error) => {
        console.error(error);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const services = [
    {
      title: "Ηλεκτρονικά ραντεβού",
      helperText: `Κλείστε ηλεκτρονικά το ραντεβού σας για την επίσκεψη στην ${organizationName}`,
    },
    {
      title: "Πιστοποιητικά / Βεβαιώσεις",
      helperText: `Ηλεκτρονική υποβολή αιτήσεων για έκδοση πιστοποιητικών, βεβαιώσεων, αδειών κλπ από την ${organizationName}`,
    },
    {
      title: "Αιτήματα πολιτών",
      helperText: `Ηλεκτρονική δήλωση προβλημάτων, βλαβών κλπ στην ${organizationName}`,
    },
  ];

  return (
    <div>
      <GovGrHeader />
      {loading ? (
        loader
      ) : (
        <div className="ml-40">
          <h1 className="govgr-heading-l mt-6">
            Ηλεκτρονικές Υπηρεσίες {organizationName}
          </h1>
          <div className="flex">
            <p className="govgr-body-l">
              Η {organizationName} προσφέρει τις ακόλουθες
            </p>
            <h1 className="govgr-heading-m ml-2">ηλεκτρονικές υπηρεσίες:</h1>
          </div>
          <ul>
            {services.map(({ title, helperText }, index) => (
              <li key={index} className="relative">
                <div className="flex items-center flex-wrap">
                  <div className="flex items-center">
                    <span className="govgr-heading-m text-base">●</span>
                    <h1 className="govgr-heading-m ml-4">{title}</h1>
                  </div>
                  <InfoOutlinedIcon
                    className="ml-4 mb-6 cursor-pointer"
                    fontSize="large"
                    onClick={() => handleHelperClick(index)}
                  />
                  {showHelperText[index] && (
                    <label className="bg-gray-200 p-2 mb-4 ml-6 mr-6">
                      {helperText}
                    </label>
                  )}
                </div>
              </li>
            ))}
          </ul>
          <p className="govgr-body-l mt-4">
            Για πρόσβαση στις υπηρεσίες απαιτούνται:
          </p>
          <ul className="ml-4">
            <li>
              <div className="flex items-center my-4">
                <span className="govgr-body-l text-base">●</span>
                <p className="govgr-body-l ml-2">προσωπικοί κωδικοί taxisnet</p>
              </div>
            </li>
            <li>
              <div className="flex items-center my-4">
                <span className="govgr-body-l text-base">●</span>
                <p className="govgr-body-l ml-2">
                  διεύθυνση ηλεκτρονικού ταχυδρομείου (email)
                </p>
              </div>
            </li>
            <li>
              <div className="flex items-center my-4">
                <span className="govgr-body-l text-base">●</span>
                <p className="govgr-body-l ml-2">αριθμός κινητού τηλεφώνου</p>
              </div>
            </li>
          </ul>
          <p className="govgr-body-l mt-12">
            Επιλέξτε "Είσοδος στην εφαρμογή" για να συνεχίσετε:
          </p>
          <button
            // onClick={() => navigate("/login")}
            onClick={initiateLogin}
            className="govgr-btn bg-[#003375] text-[#FFFF] govgr-btn-cta mt-12"
          >
            <div className="mb-1">Είσοδος στην εφαρμογή</div>
            {loading ? (
              <CircularSpinner
                size="20px"
                color="inherit"
                sx={{ marginRight: "15px" }}
              />
            ) : (
              <svg
                viewBox="0 0 24 24"
                className="govgr-arrow--right govgr-svg-icon"
                focusable="false"
                aria-hidden="true"
              >
                <path
                  d="M8.5,2L6.1,4.3l7.6,7.7l-7.6,7.6L8.5,22l10-10L8.5,2z"
                  fill="white"
                />
              </svg>
            )}
          </button>
        </div>
      )}
    </div>
  );
};

export default observer(ElectronicServices);
