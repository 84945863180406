import { useEffect, useState } from "react";
import { observer } from "mobx-react";
import { useAuthStore } from "../Stores/AuthStore";
import { Box, CircularProgress } from "@mui/material";
import { error, success } from "../Common/Toast/Toast";
import GovGrHeader from "./GovGrHeader/GovGrHeader";
import { useServicesStore } from "../Stores/ServicesStore";
import { organizationId } from "../Config/constant";
import { useNavigate } from "react-router-dom";

const EmailConfirmation = () => {
  const navigate = useNavigate();
  const authStore = useAuthStore();
  const servicesStore = useServicesStore();
  const [loading, setLoading] = useState(false);
  const [emailVerificationResponse, setEmailVerificationResponse] = useState();

  const onContinue = () => {
    navigate("/user-confirmation");
  };

  const organizationName =
    parseInt(organizationId) === servicesStore.organizationById?.id
      ? servicesStore.organizationById?.name
      : "---";

  useEffect(() => {
    if (organizationId) {
      servicesStore
        .getOrganizationById(organizationId)
        .then((resp) => {})
        .catch((error) => {})
        .finally(() => {});
    }
  }, [servicesStore]);

  const loader = (
    <Box className="flex justify-center items-center p-20">
      <CircularProgress size={50} />
    </Box>
  );

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const code = urlParams.get("code");
    const encodedCode = encodeURIComponent(code);
    const afm = urlParams.get("afm");

    if (afm && code) {
      setLoading(true);
      authStore
        .confirmEmail(afm, encodedCode)
        .then((resp) => {
          setEmailVerificationResponse(resp);
          if (resp?.status === 200) {
            success("Το email επιβεβαιώθηκε επιτυχώς");
          } else {
            error(
              "Κάτι πήγε στραβά κατα την επαλήθευση του email παρακαλώ προσπαθήστε ξανά"
            );
          }
        })
        .catch((err) => {})
        .finally(() => {
          setLoading(false);
        });
    }
  }, [authStore]);

  if (loading) {
    return loader;
  }

  return (
    <>
      <div className="w-full">
        <GovGrHeader />
      </div>

      <div className="ml-40">
        <h1 className="govgr-heading-l mt-6">
          Ηλεκτρονικές Υπηρεσίες {organizationName}
        </h1>
      </div>

      <div>
        <div className="flex justify-center items-center mt-16">
          {emailVerificationResponse?.status === 200 ? (
            <img className="w-20" src="success.png" alt="success" />
          ) : (
            <img className="w-16" src="failure.png" alt="failure" />
          )}
        </div>

        <div className="flex justify-center items-center">
          {" "}
          <br />{" "}
          <p className="govgr-body-l mt-4">
            {" "}
            {emailVerificationResponse?.status === 200
              ? "Το email επιβεβαιώθηκε επιτυχώς"
              : "Κάτι πήγε στραβά κατα την επαλήθευση του email παρακαλώ προσπαθήστε ξανά"}
          </p>
        </div>

        <div className="flex justify-center items-center">
          {" "}
          <br />
          <button
            onClick={onContinue}
            className="govgr-btn bg-[#003375] text-[#FFFF] govgr-btn-cta mt-12"
          >
            <div className="mb-1">Συνέχεια</div>
            <svg
              viewBox="0 0 24 24"
              className="govgr-arrow--right govgr-svg-icon"
              focusable="false"
              aria-hidden="true"
            >
              <path
                d="M8.5,2L6.1,4.3l7.6,7.7l-7.6,7.6L8.5,22l10-10L8.5,2z"
                fill="white"
              />
            </svg>
          </button>
        </div>
      </div>
    </>
  );
};

export default observer(EmailConfirmation);
