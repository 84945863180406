import { observer } from "mobx-react";
import { useEffect, useState } from "react";
import { useCertificatesStore } from "../../../Stores/CertificatesStore";
import { useNavigate } from "react-router";
import { formatDateInDateMonthYear } from "../../../Common/formatDate/formatDate";

const CertificateSubmissionTable = () => {
  const certificatesStore = useCertificatesStore();
  const [
    certificatesSubmissionFilteredList,
    setCertificatesSubmissionFilteredList,
  ] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    certificatesStore
      .getCertificateSubmission(1, 3, [0, 1, 2, 3, 4, 6])
      .then((res) => {
        setCertificatesSubmissionFilteredList(
          res.result.items.sort(
            (a, b) => new Date(b.createdAt) - new Date(a.createdAt)
          )
        );
      });
  }, [certificatesStore]);

  return (
    <div className="w-1/2 pb-full rounded-xl bg-lightgray">
      <div className="bg-[#003375] min-w-[90%] w-[90%] px-4 py-2 flex justify-center items-center text-white text-lg">
        <p>Οι αιτήσεις μου</p>
      </div>
      <div className="bg-gray-300 px-4 pt-4 min-w-[90%] w-[90%] justify-center items-center text-lg">
        <div className="ml-4">
          {certificatesSubmissionFilteredList?.length > 0 ? (
            <div>
              {certificatesSubmissionFilteredList?.map((item) => (
                <div key={item?.id}>
                  <p className="text-lg font-bold">
                    Κωδ. {item.id} | {item?.certificate?.name}
                  </p>
                  <p className="text-lg mb-4">
                    Ημερ.{" "}
                    {formatDateInDateMonthYear(item?.createdAt.slice(0, 10))} |{" "}
                    {certificatesStore?.statusTranslations[item?.status]}
                  </p>
                </div>
              ))}
              <div
                onClick={() => navigate("/certificate-submissions")}
                className="flex justify-end items-end pb-4 pr-6 text-blue-400 cursor-pointer hover:underline"
              >
                περισσότερα ...
              </div>
            </div>
          ) : (
            <p className="text-lg text-center pb-4">Δεν υπάρχουν αιτήσεις</p>
          )}
        </div>
      </div>
    </div>
  );
};

export default observer(CertificateSubmissionTable);
