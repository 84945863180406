import { Navigate, Route, Routes, useLocation } from "react-router-dom";
import { observer } from "mobx-react";
import ElectronicServices from "../../Pages/Guest/ElectronicServices/ElectronicServices";
import Callback from "../../Components/Callback";
// import LoginForm from "../../Pages/Guest/LoginForm/LoginForm";

const GuestRoutes = () => {
  const location = useLocation();

  return (
    <Routes>
      <Route exact path="/login" element={<ElectronicServices />} />
      {/* <Route exact path="/" element={<ElectronicServices />} /> */}
      {/* <Route exact path="/simple-login" element={<LoginForm />} /> */}
      <Route path="/" element={<Callback />} />
      <Route
        path="*"
        element={<Navigate to="/" state={{ from: location }} />}
      />
    </Routes>
  );
};

export default observer(GuestRoutes);
