import { useState } from "react";
import { useCertificatesStore } from "../../Stores/CertificatesStore";
import pistopoiitika from "../../assets/pistopoiitika.png";
import { Typography } from "@mui/material";
import CircularSpinner from "../Theme/Spinners/CircularSpinner";
import SubmitButton from "../Theme/Extended/SubmitButton";
import { observer } from "mobx-react";

const FileViewer = ({
  businessKey,
  fieldName,
  certificateSubmissionId,
  title,
  buttonOnly = false,
  buttonTitle,
  buttonColor,
  certificateSubmissionAttachments = [],
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const certificatesStore = useCertificatesStore();

  const handleDownloadFile = () => {
    const uploadedFileAdjusted = certificateSubmissionAttachments?.filter(
      (att) => att?.fileName?.includes(`${businessKey}_${fieldName}`)
    );

    const adjustedFileName = uploadedFileAdjusted[0]?.fileName;

    if (uploadedFileAdjusted) {
      setIsLoading(true);

      certificatesStore
        .dowloadFile(adjustedFileName)
        .then((resp) => {
          const dispositionHeader = resp?.headers["content-disposition"];
          const filenameMatch = dispositionHeader?.match(
            /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/
          );
          const fileName =
            filenameMatch && filenameMatch[1]?.replace(/['"]/g, "");

          const blob = new Blob([resp?.data], {
            type: resp?.headers["content-type"],
          });

          const url = URL.createObjectURL(blob);
          const a = document.createElement("a");
          a.href = url;
          a.download = fileName || `file`;
          document.body.appendChild(a);
          a.click();

          URL.revokeObjectURL(url);
        })
        .catch(() => {})
        .finally(() => {
          setIsLoading(false);
        });
    }
  };

  return (
    <div className="flex flex-col justify-center items-center pt-4">
      {!isLoading && certificateSubmissionAttachments && (
        <div className="flex flex-col justify-center items-center">
          {!buttonOnly && (
            <Typography
              variant="h5"
              justifyContent={"center"}
              alignItems={"center"}
            >
              {title}
            </Typography>
          )}

          <div className="flex flex-col items-center justify-center w-full gap-1">
            {!buttonOnly && (
              <div className="flex justify-center items-center flex-wrap gap-1 w-full">
                <div className="flex justify-center items-center">
                  <img src={pistopoiitika} width={60} alt="pistopiitika" />
                </div>
              </div>
            )}
            <SubmitButton
              sx={{
                backgroundColor: buttonColor || "#0965D4",
                borderRadius: "99px",
                textTransform: "none",
                "&:hover": {
                  backgroundColor: buttonColor || "#0965D4",
                  opacity: 0.8,
                },
              }}
              size={"large"}
              type="button"
              title={buttonTitle || "Λήψη"}
              loading={isLoading}
              onClick={handleDownloadFile}
            />
          </div>
        </div>
      )}

      {isLoading && (
        <div className="flex justify-center items-center">
          <CircularSpinner
            sx={{
              color: "#0965D4",
            }}
          />
        </div>
      )}
    </div>
  );
};

export default observer(FileViewer);
