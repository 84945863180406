import { observer } from "mobx-react";
import GovGrToolTipHeader from "../../../Components/GovGrToolTipHeader/GovGrToolTipHeader";
import RantevouCalendar from "../../../Components/RantevouCalendar/RantevouCalendar";
import { useLocation, useNavigate } from "react-router-dom";
import { useScheduleStore } from "../../../Stores/ScheduleStore";
import { useEffect } from "react";
import { useState } from "react";
import { Box, CircularProgress, Typography } from "@mui/material";
import { rantevouStore } from "../../../Stores/RantevouStore";
import IconText from "../../../Components/IconText/IconText";

const Rantevou = () => {
  const { state } = useLocation();
  const navigate = useNavigate();
  const scheduleStore = useScheduleStore();
  const [scheduleAvailable, setScheduleAvailable] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    scheduleStore
      .getScheduleByAppointmentServiceId({
        appointmentServiceId: state?.appointmentServiceId,
      })
      .then(() => {
        setScheduleAvailable(true);
      })
      .catch((error) => {
        setScheduleAvailable(false);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, [scheduleStore, state?.appointmentServiceId]);

  useEffect(() => {
    rantevouStore.setApplicationCategoryId(state?.applicationCategoryId);
    rantevouStore.setAppointmentServiceId(state?.appointmentServiceId);
    rantevouStore.setSubOrganizationId(state?.selectedSubOrganization);
  }, [
    state?.applicationCategoryId,
    state?.appointmentServiceId,
    state?.selectedSubOrganization,
  ]);

  const loader = (
    <Box className="flex justify-center items-center p-20">
      <CircularProgress size={40} />
    </Box>
  );

  const content = !scheduleAvailable ? (
    <Box className="flex justify-center items-center p-10">
      <Typography variant="h4">
        Δεν υπάρχουν διαθέσιμα ραντεβού γα την επιλεγμένη υπηρεσία.
      </Typography>
    </Box>
  ) : (
    <>
      <div className="flex flex-col gap-2">
        <p className="govgr-body-l">
          Επιλέξτε την <b>ημέρα</b> απο το ημερολόγιο και εν συνεχεία την{" "}
          <b>ώρα</b> απο την διαθέσιμη λίστα και κλείστε το ραντεβού σας.
        </p>
        <p className="govgr-body-l">
          Εμφανίζεται η διαθεσιμότητα των ραντεβού για τις επόμενες δύο
          εβδομάδες.
        </p>
      </div>
      <div className="flex flex-col justify-center items-center">
        <div className="flex justify-center items-center w-full mt-2">
          <RantevouCalendar />
        </div>
      </div>
    </>
  );

  return (
    <>
      <div>
        <GovGrToolTipHeader />
        <div className="ml-40">
          <IconText
            onClick={() => {
              scheduleStore.resetScheduleData();
              navigate(-1, {
                state: {
                  appointmentServiceId: state?.appointmentServiceId,
                },
              });
            }}
            text={`Ηλεκτρονικό αίτημα ραντεβού ${
              state?.selectedSeatName ? `| ${state?.selectedSeatName}` : ""
            }`}
          />
          {isLoading ? loader : content}
        </div>
      </div>
    </>
  );
};

export default observer(Rantevou);
