import { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import GovGrToolTipHeader from "../../../Components/GovGrToolTipHeader/GovGrToolTipHeader";
import userExclamation from "../../../Icons/userExclamation.svg";
import { GovGRCTAButton } from "../../../Components/GovGrCTAButton/GovGrCTAButton";
import { ChevronRight } from "@mui/icons-material";
import { IconButton } from "@mui/material";
import ArrowCircleLeftOutlinedIcon from "@mui/icons-material/ArrowCircleLeftOutlined";
import AuthSetAgent from "../../../Api/AuthSetAgent";
import { formatDateInDateMonthYear } from "../../../Common/formatDate/formatDate";
import { getTicketStatusLiteral } from "../../../Config/TicketHelpers/TicketHelpers";
import { organizationId } from "../../../Config/constant";
import { useServicesStore } from "../../../Stores/ServicesStore";

const Tickets = () => {
  const navigate = useNavigate();
  const [myTickets, setMyTickets] = useState([]);
  const servicesStore = useServicesStore();
  const organizationName =
    parseInt(organizationId) === servicesStore.organizationById?.id
      ? servicesStore.organizationById?.name
      : "---";

  useEffect(() => {
    if (organizationId) {
      servicesStore
        .getOrganizationById(organizationId)
        .then((resp) => {})
        .catch((error) => {})
        .finally(() => {});
    }
  }, [servicesStore]);

  useEffect(() => {
    const getData = async () => {
      await AuthSetAgent.tickets
        .getMyTickets(1, 3)
        .then((res) => {
          setMyTickets(res?.result.items || []);
        })
        .catch((e) => {
          console.error("Error in getMyTickets: ", e);
        });
    };

    getData();
  }, []);

  return (
    <div>
      <GovGrToolTipHeader />
      <div className="flex flex-col lg:flex-row w-full">
        <div className="w-full lg:w-2/3 md:ml-40 sm:ml-20 ml-10">
          <div className="flex items-center">
            <ArrowCircleLeftOutlinedIcon
              onClick={() => {
                navigate(-1);
              }}
              style={{
                fill: "#004a75",
                marginRight: "14px",
                marginBottom: "14px",
                fontSize: "50px",
                cursor: "pointer",
              }}
              fontSize="large"
            />
            <h1 className="govgr-heading-l mt-6">Ηλεκτρονικά Αιτήματα</h1>
          </div>
          <p className="govgr-body-l mt-4">
            Υπηρεσία{" "}
            <b>
              ηλεκτρονικής υποβολής αιτημάτων πολιτών για δήλωση προβλημάτων,
              βλαβών, παραπόνων
            </b>{" "}
            κλπ στην περιοχή της {organizationName}.
          </p>
          {/* <img
            className="w-[100px] h-[100px] m-auto"
            src={userExclamation}
            alt="ticket"
          /> */}
          <div className="flex justify-start  mt-6">
            <div className="mb-5 inline-block w-[600px]">
              <div className="bg-blue-900 text-white p-3">
                <h4 className="govgr-body-l text-center">Τα αιτήματά μου</h4>
              </div>

              {!myTickets?.length > 0 ? (
                <p className="my-5 text-center">
                  Δεν έχετε καταχωρημένα αιτήματα.
                </p>
              ) : (
                <>
                  {myTickets.map((item, index) => (
                    <div
                      key={index}
                      className="flex justify-between items-center my-5 mx-10"
                    >
                      <div>
                        <p className="font-bold govgr-body-s mb-1">
                          {item.ticketCategoryTitle} | {item.ticketServiceTitle}
                        </p>
                        <p className="govgr-body-s">
                          Ημερ.{" "}
                          {formatDateInDateMonthYear(item?.entityCreatedAt)} |{" "}
                          {getTicketStatusLiteral(item.status)}
                        </p>
                      </div>
                      <div className="ml-10">
                        <IconButton
                          onClick={() => navigate(`/tickets/${item?.id}`)}
                        >
                          <ChevronRight />
                        </IconButton>
                      </div>
                    </div>
                  ))}
                  <div
                    className="flex justify-end cursor-pointer"
                    onClick={() => navigate("/tickets")}
                  >
                    <span className="text-blue-800">περισσότερα...</span>
                  </div>
                </>
              )}
            </div>
          </div>
          <GovGRCTAButton
            className="mb-20"
            label={"Νέο Αίτημα"}
            onClick={() => navigate("/ticket-create")}
          />
        </div>
      </div>
    </div>
  );
};

export default Tickets;
