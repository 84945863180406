import axios from "axios";
import { warning, error } from "../Toast/Toast";
import { baseUrl } from "../../Config/constant";

axios.defaults.baseURL = baseUrl;

export const setBearerToken = (token) =>
  (axios.defaults.headers.common = { Authorization: `Bearer ${token}` });
axios.interceptors.response.use(undefined, async (er) => {
  const { response } = er;

  if (response?.status === 400) {
    warning(response?.data);
  }

  if (response?.status === 401) {
    await handleUnauthorizedResponse();
  }

  if (response?.status === 404) {
    warning("Δεν βρέθηκε η σελίδα που ζητήσατε!");
  }

  if (response?.status === 500) {
    error("Σφάλμα στον server!");
  }

  if (er.code === "ERR_NETWORK") {
    warning("Σφάλμα στην σύνδεση με τον server!");
  }
});

const responseBody = (response) => response?.data;
const responseGeneral = (response) => response;

export const requests = {
  get: (url) => axios.get(url).then(responseBody),
  getGeneral: (url) => axios.get(url).then(responseGeneral),
  getFile: (url, headers) =>
    axios.get(url, { responseType: "blob" }).then(responseGeneral),
  post: (url, body, config = {}) =>
    axios.post(url, body, config).then(responseBody),
  patch: (url, body) => axios.patch(url, body).then(responseBody),
  put: (url, body) => axios.put(url, body).then(responseBody),
  delete: (url, body) => axios.delete(url, body).then(responseBody),
};

async function handleUnauthorizedResponse() {
  warning("Δεν έχετε πρόσβαση σε αυτή την ενέργεια!");

  await delay(1000);

  window.location.href = "/";
}

function delay(ms) {
  return new Promise((resolve) => setTimeout(resolve, ms));
}
