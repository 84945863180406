import { observer } from "mobx-react";
import GovGrToolTipHeader from "../../../Components/GovGrToolTipHeader/GovGrToolTipHeader";
import { useNavigate } from "react-router";
import { useEffect, useState } from "react";
import CertificateSubmissionTable from "../CertificateSubmissionTable/CertificateSubmissionTable";
import CertificateSubmissionActionsTable from "../CertificateSubmissionActionsTable/CertificateSubmissionActionsTable";
import IconText from "../../../Components/IconText/IconText";
import { organizationId } from "../../../Config/constant";
import pistopoiitika from "../../../assets/pistopoiitika.png";
import { useServicesStore } from "../../../Stores/ServicesStore";
import { Box, CircularProgress } from "@mui/material";

const Certificates = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);

  const loader = (
    <Box className="flex justify-center items-center p-20">
      <CircularProgress size={50} />
    </Box>
  );

  const servicesStore = useServicesStore();
  const organizationName =
    parseInt(organizationId) === servicesStore.organizationById?.id
      ? servicesStore.organizationById?.name
      : "---";

  useEffect(() => {
    if (organizationId) {
      setLoading(true);
      servicesStore
        .getOrganizationById(organizationId)
        .then((resp) => {})
        .catch((error) => {})
        .finally(() => {
          setLoading(false);
        });
    }
  }, [servicesStore]);

  return (
    <>
      <div>
        <GovGrToolTipHeader />
      </div>
      {loading ? (
        loader
      ) : (
        <>
          <div className="mr-16 ml-5 md:ml-40 flex flex-col">
            <IconText navigation={"/dashboard"} text={"Τα πιστοποιητικά μου"} />
            <p className="govgr-body-l">
              Υπηρεσία{" "}
              <b>
                ηλεκτρονικής υποβολής αιτημάτων για την έκδοση ψηφιακών
                πιστοποιητικών, βεβαιώσεων
              </b>{" "}
              κλπ στην δικαιοδοσία της {organizationName}.
            </p>
            <p className="govgr-body-l mt-12">
              Επιλέξτε <b>"Δημιουργία νέας αίτησης"</b> για να ξεκινήσετε.
            </p>
            {/* <div className="flex justify-center mt-6 md:mt-12 pr-6 md:pr-12">
          <img width="130px" src={pistopoiitika} alt="pistopoiitika" />
        </div> */}

            <div className="flex items-start justify-center mt-12">
              <CertificateSubmissionActionsTable />
              <CertificateSubmissionTable />
            </div>

            <button
              onClick={() => navigate("/certificates")}
              className="govgr-btn bg-[#003375] text-[#FFFF] govgr-btn-cta mt-8"
            >
              <div className="mb-1">Δημιουργία νέας αίτησης</div>
              <svg
                viewBox="0 0 24 24"
                className="govgr-arrow--right govgr-svg-icon"
                focusable="false"
                aria-hidden="true"
              >
                <path
                  d="M8.5,2L6.1,4.3l7.6,7.7l-7.6,7.6L8.5,22l10-10L8.5,2z"
                  fill="white"
                />
              </svg>
            </button>
          </div>
        </>
      )}
    </>
  );
};

export default observer(Certificates);
