import { encrypt, decrypt } from "../Encryption/Encryption";

export const setCookie = (name, value, minutes) => {
  const expires = new Date();
  expires.setTime(expires.getTime() + minutes * 60 * 1000);

  const encryptedValue = encrypt(value);

  document.cookie = `${name}=${encryptedValue};expires=${expires.toUTCString()};path=/`;
};

export const getCookie = (name) => {
  const cookieName = `${name}=`;
  const cookieArray = document.cookie.split(";");
  for (let i = 0; i < cookieArray.length; i++) {
    let cookie = cookieArray[i].trim();
    if (cookie.indexOf(cookieName) === 0) {
      const encryptedValue = cookie.substring(cookieName.length, cookie.length);

      const decryptedValue = decrypt(encryptedValue);

      return decryptedValue;
    }
  }
  return null;
};

export const clearCookie = (name) => {
  document.cookie = `${name}=;expires=Thu, 01 Jan 1970 00:00:00 GMT;path=/`;
};
