// project imports
import GovGrToolTipHeader from "../../../Components/GovGrToolTipHeader/GovGrToolTipHeader";
import ArrowCircleLeftOutlinedIcon from "@mui/icons-material/ArrowCircleLeftOutlined";
import { useNavigate } from "react-router-dom";
import { observer } from "mobx-react";
import ProfileTable from "./ProfileTable";

const Profile = () => {
  const navigate = useNavigate();

  return (
    <>
      <div>
        <GovGrToolTipHeader />
      </div>
      <div className="ml-40 flex items-center">
        <ArrowCircleLeftOutlinedIcon
          onClick={() => navigate("/dashboard")}
          style={{
            fill: "#004a75",
            marginRight: "14px",
            marginBottom: "10px",
            fontSize: "50px",
            cursor: "pointer",
          }}
          fontSize="large"
        />
        <h1 className="govgr-heading-l mt-6">Το προφίλ μου</h1>
      </div>

      <ProfileTable />
    </>
  );
};

export default observer(Profile);
