import { observer } from "mobx-react";
import { useCertificatesStore } from "../../../Stores/CertificatesStore";
import GovGrToolTipHeader from "../../../Components/GovGrToolTipHeader/GovGrToolTipHeader";
import { useNavigate, useLocation } from "react-router";
import { useForm, Controller } from "react-hook-form";
import {
  Autocomplete,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  TextField,
  Typography,
} from "@mui/material";
import RemoveCircleIcon from "@mui/icons-material/RemoveCircle";
import IconText from "../../../Components/IconText/IconText";
import Accordions from "../../../Components/Accordion/Accordion";
import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { useState, useEffect } from "react";
import { success } from "../../../Common/Toast/Toast";
import CircularSpinner from "../../../Components/Theme/Spinners/CircularSpinner";
import CancelIcon from "@mui/icons-material/Cancel";
import parse from "html-react-parser";
import FileUploader from "../../../Components/FileUploader/FileUploader";
import MyDatePicker from "../../../Components/Theme/Inputs/MyDatePicker";
import Checkbox from "../../../Components/Theme/Inputs/Checkbox";
import { useAuthStore } from "../../../Stores/AuthStore";
import { v4 as uuidv4 } from "uuid";
import AuthSetAgent from "../../../Api/AuthSetAgent";
import { emailRegex, phoneRegex, postalCodeRegex } from "../../../Common/Regex";
// import Button from "@mui/material/Button";

const CertificatesCreateForm = () => {
  const certificatesStore = useCertificatesStore();
  const uniqueId = uuidv4();
  const authStore = useAuthStore();
  const { handleSubmit, control, reset, setValue } = useForm();
  const location = useLocation();
  const navigate = useNavigate();
  const [backResponse, setBackResponse] = useState({});
  const uniqueKeysSet = new Set();
  const [prevSelectedValues, setPrevSelectedValues] = useState({});
  const [
    prevSelectedValuesForAppendedComponents,
    setPrevSelectedValuesForAppendedComponents,
  ] = useState({});
  const [loading, setLoading] = useState(true);
  const [openModal, setOpenModal] = useState(false);
  const [certificateSubmissionId, setCertificateSubmissionId] = useState(null);
  const [isEditingForm, setIsEditingForm] = useState(false);
  const [certificate, setCertificate] = useState(null);
  const [groupComponents, setGroupComponents] = useState({});
  const [groupIndexes, setGroupIndexes] = useState({});
  const [clickedGroupId, setClickedGroupId] = useState(null);
  const [appendedComponents, setAppendedComponents] = useState([]);
  const [textVisibility, setTextVisibility] = useState({});
  const [
    certificateSubmissionAttachments,
    setCertificateSubmissionAttachments,
  ] = useState([]);
  const [certificateName, setCertificateName] = useState(
    JSON.parse(sessionStorage.getItem("certificateName")) || null
  );

  const [certificateSubmission, setCertificateSubmission] = useState(null);
  const [selectedValues, setSelectedValues] = useState({});
  const [selectedAppendedComponentValues, setSelectedAppendedComponentValues] =
    useState({});
  const [uploading, setUploading] = useState(false);

  const handleLoadingChange = (loading) => {
    setUploading(loading);
  };

  const getDisabledValue = (formField) => {
    return formField.disabled === true ? true : null;
  };

  const getValidationRules = (formField) => {
    const required = formField.validate ? formField.validate.required : false;
    const requiredValidation = {
      value: required,
      message: "Το πεδίο αυτό είναι υποχρεωτικό",
    };

    const pattern =
      formField.type === "textfield" ? getValidationPattern(formField) : null;

    return {
      required: requiredValidation,
      pattern: pattern,
    };
  };

  const getValidationPattern = (formField) => {
    if (formField.key.includes("email")) {
      return {
        value: emailRegex,
        message: "Παρακαλώ εισάγετε μια έγκυρη διεύθυνση email",
      };
    } else if (formField.key.includes("phoneNumber")) {
      return {
        value: phoneRegex,
        message: "Παρακαλώ εισάγετε ένα έγκυρο αριθμό τηλεφώνου",
      };
    } else if (formField.key.includes("PostalCode")) {
      return {
        value: postalCodeRegex,
        message: "Παρακαλώ εισάγετε έναν έγκυρο ταχυδρομικό κώδικα",
      };
    }
    return null;
  };

  const valueExistsForFormField = (formField) => {
    return backResponse && backResponse[formField.key];
  };

  const valueExistsForAppendedComponent = (component) => {
    return backResponse && backResponse[component.key];
  };

  const renderComponentsText = (components) => {
    return components.map((component, index) => {
      if (component.type === "text" && component.text) {
        return <p key={index}>{parse(component.text)}</p>;
      }
      return null;
    });
  };

  const appendNestedComponent = (groupId) => {
    const clickedGroup =
      groupComponents?.find((group) => group?.id === groupId) || {};
    const clickedGroupComponents = clickedGroup?.components || [];

    const updatedAppendedComponents = [
      ...appendedComponents,
      ...clickedGroupComponents
        ?.filter(
          (component) =>
            component?.type !== "text" && component?.type !== "button"
        )
        ?.map((component, index) => {
          const newIndex = groupIndexes[groupId] || 0;
          const newKey = `${component.key}_index${newIndex}`;
          const shouldRender =
            !component?.conditional ||
            !component?.conditional?.hide ||
            evaluateCondition(
              component?.conditional,
              selectedAppendedComponentValues
            );

          const appendedComponent = {
            ...component,
            groupId,
            uniqueId,
            index: newIndex,
            key: newKey,
            isVisible: shouldRender,
          };

          if (appendedComponent?.conditional?.hide) {
            appendedComponent.conditional = {
              ...appendedComponent.conditional,
              hide: appendedComponent?.conditional?.hide?.replace(
                /(\w+)\s*([=<>!]+)\s*"([^"]+)"/,
                (match, key, operator, value) =>
                  `${key}_index${newIndex} ${operator} "${value}"`
              ),
            };
          }

          setValue(newKey, "");

          return appendedComponent;
        }),
    ];

    setAppendedComponents(updatedAppendedComponents);

    sessionStorage.setItem(
      "appendedComponents",
      JSON.stringify(updatedAppendedComponents)
    );

    setGroupIndexes((prevIndexes) => ({
      ...prevIndexes,
      [groupId]: (prevIndexes[groupId] || 0) + 1,
    }));

    setClickedGroupId(groupId);

    setTextVisibility((prevVisibility) => ({
      ...prevVisibility,
      [groupId]: true,
    }));
  };

  const handleRemoveAppendedComponent = (uniqueId) => {
    setAppendedComponents((prevAppendedComponents) =>
      prevAppendedComponents?.filter((elm) => !(elm?.uniqueId === uniqueId))
    );

    const updatedAppendedComponents = appendedComponents.filter(
      (elm) => elm.uniqueId !== uniqueId
    );
    sessionStorage.setItem(
      "appendedComponents",
      JSON.stringify(updatedAppendedComponents)
    );

    setGroupIndexes((prevIndexes) => {
      const groupId = appendedComponents?.find(
        (elm) => elm?.uniqueId === uniqueId
      )?.groupId;
      const newGroupIndex = prevIndexes[groupId] - 1;

      return {
        ...prevIndexes,
        [groupId]: newGroupIndex >= 0 ? newGroupIndex : 0,
      };
    });

    setSelectedAppendedComponentValues((prevSelectedValues) => {
      const updatedValues = { ...prevSelectedValues };

      const removedComponent = appendedComponents?.find(
        (elm) => elm?.uniqueId === uniqueId
      );

      if (removedComponent) {
        const { key } = removedComponent;

        delete updatedValues[key];
      }

      return updatedValues;
    });
  };

  const onContinue = async (data) => {
    setLoading(true);

    const payload = {
      variables: {
        ...certificatesStore.formFieldsList?.components?.reduce(
          (acc, formField) => {
            const isFileField = formField.key?.toString()?.includes("file");

            let fieldValue;

            if (isFileField && data[formField.key]?.value) {
              fieldValue =
                certificatesStore.uploadedFileResp?.fileName ||
                backResponse[formField.key];
            } else {
              fieldValue =
                formField.type === "select"
                  ? data[formField.key]?.value
                  : data[formField.key];
            }

            if (
              fieldValue !== undefined &&
              fieldValue !== null &&
              fieldValue !== ""
            ) {
              acc[formField.key] = { value: fieldValue };
            }

            return acc;
          },
          {}
        ),

        ...appendedComponents?.reduce((acc, component, index) => {
          const isFileField = component.key?.toString()?.includes("file");
          const fieldValue =
            isFileField && data[component.key]?.value
              ? certificatesStore.uploadedFileResp?.fileName ||
                backResponse[component.key]
              : component?.type === "select"
              ? data[`${component?.key}`]?.value
              : data[`${component?.key}`];

          if (
            fieldValue !== undefined &&
            fieldValue !== null &&
            fieldValue !== ""
          ) {
            acc[`${component?.key}`] = {
              value: fieldValue,
            };
          }

          return acc;
        }, {}),
      },
      withVariablesInReturn: false,
    };

    payload.variables = {
      ...payload.variables,
      action: { value: "next" },
    };

    try {
      setLoading(true);
      await certificatesStore.completeCertificateSubmission(
        location?.state?.certificateSubmissionId,
        payload
      );
      setLoading(false);
      const prevFormFields = certificatesStore.formFieldsList;
      const result =
        await certificatesStore.getFormFieldsByCertificateSubmissionId(
          location?.state?.certificateSubmissionId
        );

      if (!result) {
        certificatesStore
          .getCertificateSubmissionById(certificateSubmissionId)
          .then(() => {
            navigate(`/certificates-submissions/${certificateSubmissionId}`, {
              state: {
                certificate: certificatesStore.currentCertificate,
                selectedApplicationCategoryId:
                  location.state.selectedApplicationCategoryId,
                isSubmittingForm: true,
              },
            });
          })
          .catch((error) => {
            console.log("error", error);
          });
      } else {
        if (certificatesStore.formFieldsList === undefined) {
          certificatesStore.setFormFieldsList(prevFormFields);
        }

        reset();
      }
    } catch (error) {
      console.error(error);
    }
    setLoading(false);
  };

  const onBack = async (formTitle) => {
    const payload = {
      variables: {
        action: { value: "back" },
      },
      withVariablesInReturn: false,
    };

    if (
      formTitle === "Συμπλήρωση προσωπικών στοιχείων" ||
      formTitle === "Συμπλήρωση Προσωπικών Στοιχείων"
    ) {
      navigate(-1);
      return;
    }
    try {
      setLoading(true);
      const resp = await certificatesStore.completeCertificateSubmission(
        location?.state?.certificateSubmissionId,
        payload
      );
      setBackResponse(resp?.result?.formValues ?? {});
      const result =
        await certificatesStore.getFormFieldsByCertificateSubmissionId(
          location?.state?.certificateSubmissionId
        );
      if (!result) {
        console.log("error", result);
      }
    } catch (error) {
      console.error(error);
    }
    setLoading(false);
  };

  const onCancel = async () => {
    const payload = {
      variables: {
        action: { value: "cancel" },
      },
      withVariablesInReturn: false,
    };

    try {
      setLoading(true);
      await certificatesStore.completeCertificateSubmission(
        location?.state?.certificateSubmissionId,
        payload
      );
      reset();
      sessionStorage.clear();
      success("Η αίτηση ακυρώθηκε επιτυχώς");
      navigate("/my-certificates");
    } catch (error) {
      error("Κάτι πήγε στραβά κατά την ακύρωση της αίτησης");
      console.error(error);
    }
    setLoading(false);
  };

  const handleCloseCertificateSubmission = () => {
    setOpenModal(false);
    navigate("/my-certificates");
  };

  // USE THIS FUNCTION IN CASE YOU WANT TO TRANSFORM STRING TO NUMBER
  // const parseValue = (value) => {
  //   const parsedValue = parseFloat(value);
  //   return isNaN(parsedValue) ? value : parsedValue;
  // };

  const evaluateCondition = (condition, selectedValues) => {
    const { hide } = condition;

    if (!hide) {
      return false;
    }

    const conditions = hide
      ?.split(/\s*(\&\&|\|\|)\s*/)
      ?.map((condition) => condition?.trim());

    if (conditions) {
      let result = false;
      let currentOperator = "||";

      conditions?.forEach((singleCondition) => {
        if (singleCondition === "&&" || singleCondition === "||") {
          currentOperator = singleCondition;
        } else {
          const matchResult = singleCondition?.match(
            /(\w+)\s*([=<>!]+)\s*"([^"]+)"/
          );

          if (matchResult) {
            const [fullCondition, key, operator, value] = matchResult;

            switch (operator) {
              case "=":
                result =
                  currentOperator === "&&"
                    ? result && selectedValues[key] === value
                    : result || selectedValues[key] === value;
                break;
              case "!=":
                result =
                  currentOperator === "&&"
                    ? result && selectedValues[key] !== value
                    : result || selectedValues[key] !== value;
                break;
              default:
                result = false;
                break;
            }
          }
        }
      });

      return !result;
    }

    return false;
  };

  useEffect(() => {
    certificatesStore.formFieldsList?.components?.forEach((formField) => {
      if (formField.type === "group") {
        const groupComponents = formField.components || [];

        const groupFields = groupComponents?.filter(
          (groupField) =>
            groupField?.type !== "text" && groupField?.type !== "button"
        );

        groupFields?.forEach((groupField, groupIndex) => {
          const key = groupField?.key;
          const baseKey = key?.split("_index")[0];

          const formValueKeys = Object?.keys(backResponse)?.filter((valueKey) =>
            valueKey?.startsWith(baseKey)
          );

          formValueKeys?.forEach((formValueKey, formValueIndex) => {
            const submittedValue = backResponse[formValueKey] || "";
            setValue(formValueKey, submittedValue || null);

            if (groupField.key?.toString()?.includes("file")) {
              setValue(
                formValueKey,
                submittedValue || submittedValue?.value || null
              );
            } else if (groupField?.type === "select") {
              setValue(
                formValueKey,
                submittedValue
                  ? groupField?.values?.find(
                      (val) => val.value === submittedValue
                    )
                  : ""
              );
            } else {
              setValue(formValueKey, submittedValue ? submittedValue : "");
            }
          });
        });
      }
    });
  }, [certificatesStore.formFieldsList, backResponse]);

  useEffect(() => {
    const storedAppendedComponents = JSON.parse(
      sessionStorage.getItem("appendedComponents")
    );
    if (storedAppendedComponents) {
      setAppendedComponents(storedAppendedComponents);
    }
  }, []);

  useEffect(() => {
    if (location.state?.certificateSubmissionId) {
      setCertificateSubmissionId(location.state?.certificateSubmissionId);
    }
    if (location.state?.formSubmittedValues) {
      setIsEditingForm(true);
    }
    if (location.state?.backResponse) {
      setBackResponse(location.state.backResponse);
    }
  }, [location]);

  useEffect(() => {
    const componentsArray = certificatesStore.formFieldsList?.components;

    const groupComponents = componentsArray
      ?.filter(
        (formField) => formField?.key === null && formField?.type === "group"
      )
      ?.map((group) => ({ ...group }));

    setGroupComponents(groupComponents || []);
  }, [certificatesStore.formFieldsList?.components]);

  useEffect(() => {
    certificateSubmissionAttachments?.forEach((attachment) => {
      if (attachment.fileName?.toString()?.includes("file")) {
        const fileNameParts = attachment.fileName?.split("_");
        const extractedPart = fileNameParts.slice(1).join("_");
        setBackResponse((prevBackResponse) => ({
          ...prevBackResponse,
          [extractedPart]: attachment?.fileName,
        }));
      }
    });
  }, [certificateSubmissionAttachments]);

  useEffect(() => {
    certificatesStore.formFieldsList?.components?.forEach((formField) => {
      if (formField.key?.toString()?.includes("file")) {
        setValue(
          formField.key,
          backResponse[formField.key] || backResponse?.value || null
        );
      } else if (formField?.type === "select") {
        setValue(
          formField.key,
          backResponse[formField.key]
            ? formField?.values?.find(
                (val) => val.value === backResponse[formField.key]
              )
            : ""
        );
      } else {
        setValue(
          formField.key,
          backResponse[formField.key] ? backResponse[formField.key] : ""
        );
      }
    });
  }, [certificatesStore.formFieldsList]);

  useEffect(() => {
    if (certificateSubmissionId) {
      setLoading(true);
      certificatesStore
        .getFormFieldsByCertificateSubmissionId(certificateSubmissionId)
        .then(() => {
          if (isEditingForm) {
            certificatesStore
              .getFormFieldsValues(certificateSubmissionId)
              .then(() => {})
              .catch((error) => {
                console.log("error", error);
              });
          }
        })
        .catch((error) => {
          console.log("error", error);
        })
        .finally(() => {
          setLoading(false);
        });
    }
  }, [certificateSubmissionId]);

  useEffect(() => {
    if (certificatesStore?.certificateId) {
      certificatesStore
        .getCertificateById(certificatesStore?.certificateId)
        .then((resp) => {
          setCertificate(resp?.result);
          setCertificateName(resp?.result?.name);
          sessionStorage.setItem(
            "certificateName",
            JSON.stringify(resp?.result?.name)
          );
        })
        .catch((error) => {
          console.log("error", error);
        });
    } else if (location?.state?.certificate) {
      setCertificate(location?.state?.certificate);
    }
  }, []);

  useEffect(() => {
    if (certificateSubmissionId) {
      certificatesStore
        .getCertificateSubmissionById(certificateSubmissionId)
        .then(() => {
          setCertificateSubmission(certificatesStore.currentCertificate);
        })
        .catch((error) => {
          console.log("error", error);
        });
    }
  }, [certificateSubmissionId]);

  useEffect(() => {
    if (certificateSubmissionId && certificatesStore.formFieldsList) {
      certificatesStore.formFieldsList.components?.forEach((formField) => {
        if (formField.type === "select" && formField.defaultValue !== null) {
          const isValueSet = valueExistsForFormField(formField);
          const valueToSet = isValueSet
            ? backResponse[formField.key]
            : formField.defaultValue;

          if (!isValueSet) {
            const defaultValue = formField.values?.find(
              (v) => v.value === valueToSet
            );
            setValue(formField.key, defaultValue || null);
          }

          setSelectedValues((prevSelectedValues) => ({
            ...prevSelectedValues,
            [formField.key]: valueToSet,
          }));
        }
      });
    }
  }, [certificateSubmissionId, certificatesStore.formFieldsList]);

  useEffect(() => {
    if (
      certificateSubmissionId &&
      certificatesStore.formFieldsList &&
      appendedComponents
    ) {
      appendedComponents?.forEach((appendedComponent) => {
        if (
          appendedComponent?.type === "select" &&
          appendedComponent?.defaultValue !== null
        ) {
          const key = `${appendedComponent?.key}`;
          const isValueSet = valueExistsForAppendedComponent(appendedComponent);
          const valueToSet = isValueSet
            ? backResponse[appendedComponent.key]
            : appendedComponent.defaultValue;

          if (!selectedAppendedComponentValues[key] || !isValueSet) {
            const defaultValue = appendedComponent.values?.find(
              (v) => v.value === valueToSet
            );
            setValue(appendedComponent.key, defaultValue || null);

            setSelectedAppendedComponentValues((prevSelectedValues) => ({
              ...prevSelectedValues,
              [key]: valueToSet,
            }));
          }
        }

        const isVisible =
          !appendedComponent?.conditional ||
          !appendedComponent?.conditional?.hide ||
          evaluateCondition(
            appendedComponent?.conditional,
            selectedAppendedComponentValues
          );

        setAppendedComponents((prevAppendedComponents) => {
          return prevAppendedComponents?.map((prevComponent) =>
            prevComponent === appendedComponent
              ? { ...appendedComponent, isVisible }
              : prevComponent
          );
        });
      });
    }
  }, [
    certificateSubmissionId,
    certificatesStore.formFieldsList,
    appendedComponents?.length,
    selectedAppendedComponentValues?.splice,
  ]);

  useEffect(() => {
    if (
      certificateSubmissionId &&
      certificatesStore.formFieldsList &&
      authStore.authUser
    ) {
      certificatesStore.formFieldsList?.components?.forEach((formField) => {
        const fieldNameWithPrefix = formField.key?.substring(4);

        if (fieldNameWithPrefix) {
          const authUserValue = authStore.authUser[fieldNameWithPrefix];

          if (authUserValue !== undefined) {
            setValue(formField.key, authUserValue);
          } else {
          }
        } else {
        }
      });
    }
  }, [
    certificateSubmissionId,
    certificatesStore.formFieldsList,
    authStore.authUser,
  ]);

  useEffect(() => {
    if (certificateSubmissionId) {
      setLoading(true);
      AuthSetAgent.certificateSubmission
        .getCertificateSubmissionAttachments(certificateSubmissionId)
        .then((resp) => {
          setCertificateSubmissionAttachments(resp?.result);
        })
        .catch(() => {})
        .finally(() => {
          setLoading(false);
        });
    }
  }, [certificateSubmissionId]);

  return (
    <div>
      <div>
        <GovGrToolTipHeader />
      </div>
      {loading && (
        <div className="fixed top-0 bottom-0 inset-0 z-10 bg-gray-500 bg-opacity-50 flex justify-center items-center">
          <CircularSpinner
            sx={{
              color: "#3498db",
            }}
          />
        </div>
      )}

      {!loading && (
        <form onSubmit={handleSubmit(onContinue)}>
          <div className="ml-40 mr-40">
            <IconText
              onClick={() => onBack(certificatesStore?.formFieldsList?.name)}
              text={`${
                isEditingForm
                  ? "Επεξεργασία αίτησης"
                  : "Δημιουργία νέας αίτησης"
              } | ${certificatesStore?.formFieldsList?.name}`}
            />
            <b>
              <div className="govgr-body-l text-[20px]">
                {certificateName ? certificateName : "-"}
              </div>
            </b>
            <Accordions
              className="mt-4"
              text={`Οδηγίες αίτησης`}
              icon={<InfoOutlinedIcon className="mr-2" fontSize="large" />}
            >
              <div className="py-4 px-2">
                <div className="govgr-body-l text-[20px]">
                  Η αίτηση επέχει θέση υπεύθυνης δήλωσης του άρθρου 8
                  Ν.1599/1986. Η ακρίβεια των στοιχείων που υποβάλλονται με αυτή
                  τη δήλωση μπορεί να ελεγχθεί με βάση το αρχείο άλλων
                  υπηρεσιών.
                </div>

                <div className="govgr-body-l text-[20px] mt-2">
                  Με ατομική μου ευθύνη και γνωρίζοντας τις κυρώσεις (1), που
                  προβλέπεται από τις διατάξεις της παρ.6 του άρθρου 22 του
                  Ν.1599/1986, δηλώνει ότι:
                </div>

                <div className="govgr-body-l text-[20px] mt-2">
                  (1) Όποιος εν γνώσει του δηλώνει ψευδή γεγονότα ή αρνείτε ή
                  αποκρύπτει τα αληθινά με έγγραφη υπεύθυνη δήλωση του άρθρου 8
                  τιμωρείται με φυλάκιση τουλάχιστον τριών μηνών. Εάν ο υπαίτιος
                  αυτών των πράξεων σκόπευε να προσπορίσει στον εαυτόν του ή σε
                  άλλον περιουσιακό όφελος τρίτον ή σκόπευε να βλάψει άλλον,
                  τιμωρείται με κάθειρξη μέχρι 10 ετών.
                </div>
              </div>
            </Accordions>

            <Accordions
              className="mt-4"
              text={certificatesStore?.formFieldsList?.name}
            >
              <div className="govgr-body-l text-[20px] mt-4">
                {certificatesStore.formFieldsList.components
                  ? renderComponentsText(
                      certificatesStore.formFieldsList.components
                    )
                  : ""}
              </div>

              <div className="mt-4 pb-4 grid grid-cols-3 items-end">
                {!loading &&
                  certificatesStore.formFieldsList?.components?.length > 0 &&
                  certificatesStore.formFieldsList?.components
                    ?.filter((formField) => formField.key !== null)
                    ?.map((formField, index) => {
                      const shouldRenderField =
                        !formField?.conditional ||
                        !formField?.conditional?.hide ||
                        evaluateCondition(
                          formField?.conditional,
                          selectedValues
                        );

                      return shouldRenderField ? (
                        <div
                          key={index}
                          className="flex items-center justify-center"
                        >
                          <Controller
                            name={formField.key}
                            control={control}
                            rules={getValidationRules(formField)}
                            render={({
                              field: { onChange, value },
                              fieldState: { error },
                            }) => (
                              <div className="govgr-body-l mt-6 grow">
                                {formField.key?.toString()?.includes("file") ? (
                                  <>
                                    <div className="govgr-body-s ml-6 mb-4 font-bold">
                                      {formField.label}
                                    </div>
                                    <div className="ml-6 pb-2 h-full">
                                      <FileUploader
                                        certificateSubmissionId={
                                          certificateSubmissionId
                                        }
                                        businessKey={
                                          certificateSubmission?.businessKey
                                        }
                                        fieldName={formField?.key}
                                        isEditMode={false}
                                        errors={Boolean(error)}
                                        helperText={error && error.message}
                                        onFileUploaded={onChange}
                                        formFieldsValues={backResponse}
                                        certificateSubmissionAttachments={
                                          certificateSubmissionAttachments
                                        }
                                        onLoadingChange={handleLoadingChange}
                                      />
                                    </div>
                                  </>
                                ) : formField.type === "textfield" ? (
                                  <>
                                    <div className="govgr-body-s ml-6 mb-4 font-bold">
                                      {formField.label}
                                    </div>
                                    <div className="ml-6 pb-2">
                                      <TextField
                                        className="w-[75%]"
                                        value={value || ""}
                                        label=""
                                        placeholder={formField.label}
                                        type="text"
                                        disabled={getDisabledValue(formField)}
                                        onChange={(e) =>
                                          onChange(e.target.value)
                                        }
                                        error={Boolean(error)}
                                        helperText={error && error.message}
                                      />
                                    </div>
                                  </>
                                ) : formField.type === "datetime" &&
                                  !formField.key
                                    ?.toString()
                                    ?.includes("Birthday") ? (
                                  <>
                                    <div className="govgr-body-s ml-6 mb-4 font-bold">
                                      {formField.label}
                                    </div>
                                    <div className="ml-6 w-full">
                                      <MyDatePicker
                                        defaultValue={
                                          backResponse &&
                                          backResponse[formField.key]
                                            ? backResponse[formField.key]
                                            : ""
                                        }
                                        value={value || ""}
                                        onChange={(date) => onChange(date)}
                                        disabled={getDisabledValue(formField)}
                                        placeholder={formField.label}
                                        format="DD-MM-YYYY"
                                        className="w-[72%]"
                                        error={Boolean(error)}
                                        helperText={error && error.message}
                                        isBirthDate={false}
                                      />
                                    </div>
                                  </>
                                ) : formField.type === "datetime" &&
                                  formField.key
                                    ?.toString()
                                    ?.includes("Birthday") ? (
                                  <>
                                    <div className="govgr-body-s ml-6 mb-4 font-bold">
                                      {formField.label}
                                    </div>
                                    <div className="ml-6 w-full">
                                      <MyDatePicker
                                        value={value || ""}
                                        defaultValue={
                                          backResponse &&
                                          backResponse[formField.key]
                                            ? backResponse[formField.key]
                                            : ""
                                        }
                                        onChange={(date) => onChange(date)}
                                        disabled={getDisabledValue(formField)}
                                        placeholder={formField.label}
                                        format="DD-MM-YYYY"
                                        className="w-[72%]"
                                        error={Boolean(error)}
                                        helperText={error && error.message}
                                        isBirthDate={true}
                                      />
                                    </div>
                                  </>
                                ) : formField.type === "checkbox" ? (
                                  <>
                                    <div className="govgr-body-s ml-6 mb-4 font-bold">
                                      {formField.label}
                                    </div>
                                    <div className="ml-6 pb-2">
                                      <Checkbox
                                        disabled={getDisabledValue(formField)}
                                        value={value || ""}
                                        onChange={(checked) =>
                                          onChange(checked)
                                        }
                                        label={formField.label}
                                      />
                                    </div>
                                  </>
                                ) : formField.type === "textarea" ? (
                                  <>
                                    <div className="govgr-body-s ml-6 mb-4 font-bold">
                                      {formField.label}
                                    </div>
                                    <div className="ml-6 pb-2">
                                      <TextField
                                        className="w-[75%]"
                                        value={value || ""}
                                        label=""
                                        placeholder={formField.label}
                                        type="text"
                                        multiline
                                        rows={2}
                                        disabled={getDisabledValue(formField)}
                                        onChange={(e) =>
                                          onChange(e.target.value)
                                        }
                                        error={Boolean(error)}
                                        helperText={error && error.message}
                                      />
                                    </div>
                                  </>
                                ) : formField.type === "number" ? (
                                  <>
                                    <div className="govgr-body-s ml-6 mb-4 font-bold">
                                      {formField.label}
                                    </div>
                                    <div className="ml-6 pb-2">
                                      <TextField
                                        className="w-[75%]"
                                        label=""
                                        placeholder={formField.label}
                                        value={value || ""}
                                        type="number"
                                        disabled={getDisabledValue(formField)}
                                        onChange={(e) =>
                                          onChange(e.target.value)
                                        }
                                        error={Boolean(error)}
                                        helperText={error && error.message}
                                      />
                                    </div>
                                  </>
                                ) : formField.type === "select" ? (
                                  <>
                                    <div className="govgr-body-s ml-6 mb-4 font-bold">
                                      {formField.label}
                                    </div>
                                    <div className="ml-6 pb-2">
                                      <Autocomplete
                                        className="w-[75%]"
                                        value={value || ""}
                                        disabled={getDisabledValue(formField)}
                                        onChange={(e, newValue) => {
                                          onChange(newValue);
                                          certificatesStore.formFieldsList?.components?.forEach(
                                            (otherField) => {
                                              const shouldRenderOtherField =
                                                !otherField?.conditional ||
                                                !otherField?.conditional
                                                  ?.hide ||
                                                evaluateCondition(
                                                  otherField?.conditional,
                                                  {
                                                    ...selectedValues,
                                                    [formField.key]:
                                                      newValue?.value,
                                                  }
                                                );

                                              if (!shouldRenderOtherField) {
                                                setValue(otherField.key, null);
                                              }
                                            }
                                          );

                                          setSelectedValues(
                                            (prevSelectedValues) => ({
                                              ...prevSelectedValues,
                                              [formField.key]: newValue?.value,
                                            })
                                          );

                                          setPrevSelectedValues(
                                            (prevValues) => ({
                                              ...prevValues,
                                              [formField.key]: value,
                                            })
                                          );
                                        }}
                                        isOptionEqualToValue={(option, value) =>
                                          option.value === value.value
                                        }
                                        options={formField.values}
                                        getOptionLabel={(option) =>
                                          option.label || ""
                                        }
                                        renderInput={(params) => (
                                          <TextField
                                            {...params}
                                            disabled={getDisabledValue(
                                              formField
                                            )}
                                            InputLabelProps={{ shrink: false }}
                                            label=""
                                            placeholder={formField.label}
                                            type="text"
                                            error={Boolean(error)}
                                            helperText={error && error.message}
                                          />
                                        )}
                                      />
                                    </div>
                                  </>
                                ) : null}
                              </div>
                            )}
                          />
                        </div>
                      ) : null;
                    })}
              </div>

              <div className="ml-6">
                {groupComponents?.map((group, groupIndex) => (
                  <div key={groupIndex}>
                    <div className="govgr-body-l text-[20px] mt-4 font-bold">
                      {group?.label}
                    </div>

                    {group?.components?.map((formField, fieldIndex) => (
                      <div key={fieldIndex}>
                        {formField?.type === "text" &&
                          (!textVisibility[group?.id] ||
                            appendedComponents?.filter(
                              (component) => component?.groupId === group?.id
                            )?.length === 0) && (
                            <div className="govgr-body-l text-[20px] mt-4">
                              {formField?.text}
                            </div>
                          )}

                        <div>
                          {appendedComponents
                            ?.filter(
                              (appendedComponent) =>
                                appendedComponent?.groupId === group?.id &&
                                !uniqueKeysSet?.has(appendedComponent?.key) &&
                                appendedComponent?.type !== "text" &&
                                appendedComponent?.type !== "button"
                            )
                            ?.map(
                              (appendedComponent, appendedComponentIndex) => {
                                uniqueKeysSet?.add(appendedComponent?.key);

                                const shouldRenderField =
                                  !appendedComponent?.conditional ||
                                  !appendedComponent?.conditional?.hide ||
                                  evaluateCondition(
                                    appendedComponent?.conditional,
                                    selectedAppendedComponentValues
                                  );

                                return shouldRenderField ? (
                                  <div
                                    key={appendedComponentIndex}
                                    className="flex items-center justify-center"
                                  >
                                    <Controller
                                      name={`${appendedComponent?.key}`}
                                      control={control}
                                      rules={getValidationRules(
                                        appendedComponent
                                      )}
                                      render={({
                                        field: { onChange, value },
                                        fieldState: { error },
                                      }) => (
                                        <div className="govgr-body-l mt-6 grow">
                                          {appendedComponent.key
                                            ?.toString()
                                            ?.includes("file") ? (
                                            <>
                                              <div className="govgr-body-s ml-6 mb-4 font-bold">
                                                {appendedComponent.label}
                                              </div>
                                              <div className="ml-6 pb-2 h-full">
                                                <FileUploader
                                                  certificateSubmissionId={
                                                    certificateSubmissionId
                                                  }
                                                  businessKey={
                                                    certificateSubmission.businessKey
                                                  }
                                                  fieldName={
                                                    appendedComponent.key
                                                  }
                                                  isEditMode={false}
                                                  errors={Boolean(error)}
                                                  helperText={
                                                    error && error.message
                                                  }
                                                  onFileUploaded={onChange}
                                                  formFieldsValues={
                                                    backResponse
                                                  }
                                                  certificateSubmissionAttachments={
                                                    certificateSubmissionAttachments
                                                  }
                                                  onLoadingChange={
                                                    handleLoadingChange
                                                  }
                                                />
                                              </div>
                                            </>
                                          ) : appendedComponent.type ===
                                              "textfield" &&
                                            !appendedComponent.key
                                              ?.toString()
                                              ?.includes("reset") ? (
                                            <>
                                              <div className="govgr-body-s ml-6 mb-4 font-bold">
                                                {appendedComponent.label}
                                              </div>
                                              <div className="ml-6 pb-2">
                                                <TextField
                                                  className="w-[74%]"
                                                  value={value || ""}
                                                  label=""
                                                  placeholder={
                                                    appendedComponent.label
                                                  }
                                                  type="text"
                                                  disabled={getDisabledValue(
                                                    appendedComponent
                                                  )}
                                                  onChange={(e) =>
                                                    onChange(e.target.value)
                                                  }
                                                  error={Boolean(error)}
                                                  helperText={
                                                    error && error.message
                                                  }
                                                />
                                              </div>
                                            </>
                                          ) : appendedComponent.key
                                              ?.toString()
                                              ?.includes("reset") ? (
                                            <>
                                              <span className="govgr-body-s ml-6 mb-4 font-bold">
                                                Αφαίρεση
                                              </span>
                                              <div className="ml-[46px] pb-2">
                                                <RemoveCircleIcon
                                                  fontSize="large"
                                                  titleAccess="Αφαίρεση"
                                                  style={{ color: "#0F73EF" }}
                                                  className="cursor-pointer"
                                                  onClick={() =>
                                                    handleRemoveAppendedComponent(
                                                      appendedComponent?.uniqueId
                                                    )
                                                  }
                                                />
                                              </div>
                                            </>
                                          ) : appendedComponent.type ===
                                              "datetime" &&
                                            !appendedComponent.key
                                              ?.toString()
                                              ?.includes("Birthday") ? (
                                            <>
                                              <div className="govgr-body-s ml-6 mb-4 font-bold">
                                                {appendedComponent.label}
                                              </div>
                                              <div className="ml-6 w-full">
                                                <MyDatePicker
                                                  defaultValue={
                                                    backResponse &&
                                                    backResponse[
                                                      appendedComponent.key
                                                    ]
                                                      ? backResponse[
                                                          appendedComponent.key
                                                        ]
                                                      : ""
                                                  }
                                                  value={value || ""}
                                                  onChange={(date) =>
                                                    onChange(date)
                                                  }
                                                  disabled={getDisabledValue(
                                                    appendedComponent
                                                  )}
                                                  placeholder={
                                                    appendedComponent.label
                                                  }
                                                  format="DD-MM-YYYY"
                                                  className="w-[72%]"
                                                  error={Boolean(error)}
                                                  helperText={
                                                    error && error.message
                                                  }
                                                  isBirthDate={false}
                                                />
                                              </div>
                                            </>
                                          ) : appendedComponent.type ===
                                              "datetime" &&
                                            appendedComponent.key
                                              ?.toString()
                                              ?.includes("Birthday") ? (
                                            <>
                                              <div className="govgr-body-s ml-6 mb-4 font-bold">
                                                {appendedComponent.label}
                                              </div>
                                              <div className="ml-6 w-full">
                                                <MyDatePicker
                                                  defaultValue={
                                                    backResponse &&
                                                    backResponse[
                                                      appendedComponent.key
                                                    ]
                                                      ? backResponse[
                                                          appendedComponent.key
                                                        ]
                                                      : ""
                                                  }
                                                  value={value || ""}
                                                  onChange={(date) =>
                                                    onChange(date)
                                                  }
                                                  disabled={getDisabledValue(
                                                    appendedComponent
                                                  )}
                                                  placeholder={
                                                    appendedComponent.label
                                                  }
                                                  format="DD-MM-YYYY"
                                                  className="w-[72%]"
                                                  error={Boolean(error)}
                                                  helperText={
                                                    error && error.message
                                                  }
                                                  isBirthDate={true}
                                                />
                                              </div>
                                            </>
                                          ) : appendedComponent.type ===
                                            "checkbox" ? (
                                            <>
                                              <div className="govgr-body-s ml-6 mb-4 font-bold">
                                                {appendedComponent.label}
                                              </div>
                                              <div className="ml-6 pb-2">
                                                <Checkbox
                                                  disabled={getDisabledValue(
                                                    appendedComponent
                                                  )}
                                                  value={value || ""}
                                                  onChange={(checked) =>
                                                    onChange(checked)
                                                  }
                                                  label={
                                                    appendedComponent.label
                                                  }
                                                />
                                              </div>
                                            </>
                                          ) : appendedComponent.type ===
                                            "textarea" ? (
                                            <>
                                              <div className="govgr-body-s ml-6 mb-4 font-bold">
                                                {appendedComponent.label}
                                              </div>
                                              <div className="ml-6 pb-2">
                                                <TextField
                                                  className="w-[74%]"
                                                  value={value || ""}
                                                  label=""
                                                  placeholder={
                                                    appendedComponent.label
                                                  }
                                                  type="text"
                                                  multiline
                                                  rows={2}
                                                  disabled={getDisabledValue(
                                                    appendedComponent
                                                  )}
                                                  onChange={(e) =>
                                                    onChange(e.target.value)
                                                  }
                                                  error={Boolean(error)}
                                                  helperText={
                                                    error && error.message
                                                  }
                                                />
                                              </div>
                                            </>
                                          ) : appendedComponent.type ===
                                            "number" ? (
                                            <>
                                              <div className="govgr-body-s ml-6 mb-4 font-bold">
                                                {`${appendedComponent.label}
                                                )}`}
                                              </div>
                                              <div className="ml-6 pb-2">
                                                <TextField
                                                  className="w-[74%]"
                                                  label=""
                                                  placeholder={
                                                    appendedComponent.label
                                                  }
                                                  value={value || ""}
                                                  type="number"
                                                  disabled={getDisabledValue(
                                                    appendedComponent
                                                  )}
                                                  onChange={(e) =>
                                                    onChange(e.target.value)
                                                  }
                                                  error={Boolean(error)}
                                                  helperText={
                                                    error && error.message
                                                  }
                                                />
                                              </div>
                                            </>
                                          ) : appendedComponent.type ===
                                            "select" ? (
                                            <>
                                              <div className="govgr-body-s ml-6 mb-4 font-bold">
                                                {appendedComponent.label}
                                              </div>
                                              <div className="ml-6 pb-2">
                                                <Autocomplete
                                                  className="w-[74%]"
                                                  value={value || ""}
                                                  disabled={getDisabledValue(
                                                    appendedComponent
                                                  )}
                                                  onChange={(e, newValue) => {
                                                    onChange(newValue);
                                                    appendedComponents?.forEach(
                                                      (otherField) => {
                                                        const shouldRenderField =
                                                          !otherField?.conditional ||
                                                          !otherField
                                                            ?.conditional
                                                            ?.hide ||
                                                          evaluateCondition(
                                                            otherField?.conditional,
                                                            selectedAppendedComponentValues
                                                          );

                                                        if (
                                                          !shouldRenderField
                                                        ) {
                                                          setValue(
                                                            `${otherField?.key}`,
                                                            null
                                                          );
                                                        }
                                                      }
                                                    );

                                                    setSelectedAppendedComponentValues(
                                                      (prevSelectedValues) => ({
                                                        ...prevSelectedValues,
                                                        [`${appendedComponent?.key}`]:
                                                          newValue?.value,
                                                      })
                                                    );

                                                    setPrevSelectedValuesForAppendedComponents(
                                                      (prevValues) => ({
                                                        ...prevValues,
                                                        [`${appendedComponent?.key}`]:
                                                          value,
                                                      })
                                                    );
                                                  }}
                                                  isOptionEqualToValue={(
                                                    option,
                                                    value
                                                  ) =>
                                                    option.value === value.value
                                                  }
                                                  options={
                                                    appendedComponent.values
                                                  }
                                                  getOptionLabel={(option) =>
                                                    option.label || ""
                                                  }
                                                  renderInput={(params) => (
                                                    <TextField
                                                      {...params}
                                                      disabled={getDisabledValue(
                                                        appendedComponent
                                                      )}
                                                      InputLabelProps={{
                                                        shrink: false,
                                                      }}
                                                      label=""
                                                      placeholder={
                                                        appendedComponent.label
                                                      }
                                                      type="text"
                                                      error={Boolean(error)}
                                                      helperText={
                                                        error && error.message
                                                      }
                                                    />
                                                  )}
                                                />
                                              </div>
                                            </>
                                          ) : null}
                                        </div>
                                      )}
                                    />
                                  </div>
                                ) : null;
                              }
                            )}
                        </div>

                        {formField?.type === "button" && (
                          <div className="mt-6 ml-[31%]">
                            <button
                              type="button"
                              className="govgr-btn bg-[#0F73EF] text-[#FFFF]"
                              onClick={() => appendNestedComponent(group?.id)}
                            >
                              + {formField?.label}
                            </button>
                          </div>
                        )}
                      </div>
                    ))}
                  </div>
                ))}
              </div>

              <div className="flex justify-end mt-4">
                <button
                  type="submit"
                  className={`govgr-btn  text-[#FFFF] ${
                    !uploading
                      ? "bg-[#003375]"
                      : "bg-[#A8A8A8] cursor-not-allowed"
                  }`}
                  disabled={uploading}
                >
                  <p>Συνέχεια</p>
                  <svg
                    viewBox="0 0 24 24"
                    className="govgr-arrow-right govgr-svg-icon"
                    focusable="false"
                    aria-hidden="true"
                  >
                    <path
                      d="M8.5,2L6.1,4.3l7.6,7.7l-7.6,7.6L8.5,22l10-10L8.5,2z"
                      fill="white"
                    />
                  </svg>
                </button>
              </div>
            </Accordions>

            <div className="flex justify-start space-x-2">
              <button
                type="button"
                className="govgr-btn mt-4 bg-[#003375] text-[#FFFF]"
                onClick={() => setOpenModal(true)}
              >
                <p>Προσωρινή αποθήκευση</p>
              </button>
              <button
                type="button"
                className="govgr-btn mt-4 bg-[#b60202] text-[#FFFF]"
                onClick={() => onCancel()}
              >
                <p>Ακύρωση αίτησης</p>
                <div>
                  <CancelOutlinedIcon fontSize="medium" />{" "}
                </div>
              </button>
            </div>
          </div>
        </form>
      )}

      <Dialog
        maxWidth="md"
        open={openModal}
        onClose={() => setOpenModal(false)}
      >
        <DialogTitle sx={{ m: 0, p: 2 }}>
          <IconButton
            aria-label="close"
            onClick={() => setOpenModal(false)}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CancelIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent className="mt-4 mx-4">
          <div className="flex flex-col items-center justify-center space-y-6">
            <p className="govgr-body-l text-center">
              Επιλέγοντας "Προσωρινή αποθήκευση" θα χαθούν τα συμπληρωμένα
              στοιχεία της παρούσας φόρμας. Τα συμπληρωμένα στοιχεία των
              προηγούμενων βημάτων θα αποθηκευτούν.
            </p>
          </div>
        </DialogContent>
        <DialogActions
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <div>
            <p className="govgr-body-l text-center mt-6">
              Θέλετε να αποθηκεύσετε προσωρινά την αίτηση;
            </p>
            <div className="flex justify-center items-center space-x-2">
              <button
                onClick={() => handleCloseCertificateSubmission()}
                className="govgr-btn bg-[#003375] text-[#FFFF] mt-8"
              >
                Ναι
              </button>
              <button
                onClick={() => setOpenModal(false)}
                className="govgr-btn bg-[#003375] text-[#FFFF] mt-8"
              >
                Όχι
              </button>
            </div>
          </div>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default observer(CertificatesCreateForm);
