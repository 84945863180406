import GovGrToolTipHeader from "../../../Components/GovGrToolTipHeader/GovGrToolTipHeader";
import GovSelect from "../../../Components/GovSelect/GovSelect";
import { useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router";
import MapGoogle from "../../../Components/GoogleMap/GoogleMap";
import ImageUploader from "../../../Components/ImageUploader/ImageUploader";
import { Alert, TextField } from "@mui/material";
import AuthSetAgent from "../../../Api/AuthSetAgent";
import { success } from "../../../Common/Toast/Toast";
import { GovGRCTAButton } from "../../../Components/GovGrCTAButton/GovGrCTAButton";
import ArrowCircleLeftOutlinedIcon from "@mui/icons-material/ArrowCircleLeftOutlined";
import { organizationId } from "../../../Config/constant";

const REDIRECT_TIMEOUT_INTERVAL = 1000;

const TicketCreate = () => {
  const navigate = useNavigate();
  const [categories, setCategories] = useState([]);
  const [services, setServices] = useState([]);
  const [mapData, setMapData] = useState(null);
  const [selectedCategory, setSelectedCategory] = useState("");
  const [selectedService, setSelectedService] = useState("");
  const [markerPosition, setMarkerPosition] = useState(null);
  const [images, setImages] = useState([]);
  const [notes, setNotes] = useState("");
  const [loadDataError, setLoadDataError] = useState(false);
  const [positionError, setPositionError] = useState(false);
  const [loadingSubmit, setLoadingSubmit] = useState(false);
  const [address, setAddress] = useState("");
  const [isChecked, setIsChecked] = useState(false);
  const categoryError = !selectedCategory;
  const serviceError =
    selectedCategory && services.length > 0 && !selectedService;
  const submitDisabled =
    categoryError ||
    serviceError ||
    !markerPosition ||
    positionError ||
    !isChecked;

  const handleCheckboxChange = (event) => {
    setIsChecked(event.target.checked);
  };

  const handleCategoryChange = useCallback(
    (event) => {
      setSelectedCategory(event.target.value);
      setSelectedService("");

      const servicesOfCategory =
        categories.find(
          (item) => event.target.value === item?.value?.toString()
        )?.ticketServices || [];
      setServices(
        servicesOfCategory.map((service) => ({
          label: service?.title,
          value: service?.id,
        }))
      );
    },
    [categories]
  );

  const handleProblemChange = useCallback((event) => {
    setSelectedService(event.target.value);
  }, []);

  const handleTextInput = useCallback((e) => {
    setNotes(e.target.value);
  }, []);

  const onSubmit = useCallback(async () => {
    setLoadingSubmit(true);

    try {
      const response = await AuthSetAgent.tickets.createTicket({
        ticketCategoryId: selectedCategory,
        ticketServiceId: selectedService,
        lat: markerPosition?.lat,
        lon: markerPosition?.lng,
        formattedAddress: address,
        notes,
        foo: organizationId,
      });

      if (response?.success) {
        success("Το αίτημά σας υποβλήθηκε επιτυχώς!");

        const backendResponse = response?.result;
        const parts = backendResponse?.split(",");
        const ticketId = parts[0];
        const ticketCategory = parts[1];

        if (images?.length > 0) {
          if (ticketId && response?.success) {
            await AuthSetAgent.tickets.uploadTicketFile({
              ticketId,
              files: images,
            });

            setTimeout(() => {
              navigate("/ticket-confirmation", {
                state: { ticketId, ticketCategory },
              });
            }, REDIRECT_TIMEOUT_INTERVAL);
          }
        } else {
          setTimeout(() => {
            navigate("/ticket-confirmation", {
              state: { ticketId, ticketCategory },
            });
          }, REDIRECT_TIMEOUT_INTERVAL);
        }
      }
    } catch (error) {
      console.error("Error:", error);
    } finally {
      setLoadingSubmit(false);
    }
  }, [
    selectedCategory,
    selectedService,
    markerPosition?.lat,
    markerPosition?.lng,
    address,
    notes,
    images,
    navigate,
  ]);

  useEffect(() => {
    const getData = async () => {
      await AuthSetAgent.tickets
        .getTicketCategoriesAndMap(organizationId)
        .then(async (response) => {
          // set category ans services data
          //
          if (Array.isArray(response?.result?.ticketCategories)) {
            setCategories(
              response.result.ticketCategories.map((item) => ({
                label: item?.title,
                value: item?.id,
                ticketServices: item?.ticketServices,
              }))
            );
          }

          // set map data
          //
          if (response?.result?.geoJsonMap) {
            const polygon = await JSON.parse(response.result.geoJsonMap);
            setMapData(polygon?.coordinates);
          }
        })
        .catch(() => {
          setLoadDataError(true);
        });
    };

    getData();
  }, []);

  // Remove map data on unmount
  //
  useEffect(() => {
    return () => setMapData(null);
  }, []);

  return (
    <div>
      <GovGrToolTipHeader />
      <div className="flex flex-col lg:flex-row w-full mb-20">
        <div className="w-full lg:w-2/3 md:ml-40 sm:ml-20 ml-10">
          <div className="flex items-center">
            <ArrowCircleLeftOutlinedIcon
              onClick={() => {
                navigate(-1);
              }}
              style={{
                fill: "#004a75",
                marginRight: "14px",
                marginBottom: "14px",
                fontSize: "50px",
                cursor: "pointer",
              }}
              fontSize="large"
            />
            <h1 className="govgr-heading-l mt-6">Ηλεκτρονικά Αιτήματα</h1>
          </div>

          <div className="flex justify-between items-center">
            <h2 className="govgr-heading-m font-bold mt-6 mb-6">
              Επιλογή Κατηγορίας / Υπηρεσία
            </h2>
            <span className="font-bold text-red-500">* Υποχρεωτικό πεδίο</span>
          </div>
          <p className="govgr-caption-m">
            Επιλέξτε την <b>Κατηγορία</b> του αιτήματός σας και την{" "}
            <b>Υπηρεσία / Πρόβλημα</b> που θέλετε να δηλώσετε.
          </p>
          <div className={"mt-4 flex"}>
            <div className="grow">
              <GovSelect
                name="select-list"
                label="Επιλέξτε Κατηγορία"
                value={selectedCategory}
                options={categories}
                onChange={handleCategoryChange}
                className="w-[360px]"
              />
            </div>
            <div className="grow">
              <GovSelect
                name="select-list"
                label="Επιλέξτε Υπηρεσία / Πρόβλημα"
                value={selectedService}
                options={services}
                onChange={handleProblemChange}
                className="w-[360px]"
                disabled={!services?.length}
              />
            </div>
          </div>

          <div className="mb-10">
            <div className="flex justify-between items-center">
              <h2 className="govgr-heading-m font-bold mt-6 mb-6">
                Θέση στο χάρτη
              </h2>
              <span className="font-bold text-red-500">
                * Υποχρεωτικό πεδίο
              </span>
            </div>
            <p className="govgr-caption-m mb-5">
              Προσδιορίστε τη <b>θέση του αιτήματός</b> σας στο χάρτη ή
              πληκτρολογήστε τη διεύθυνση στο πεδίο "Αναζήτηση διεύθυνσης στο
              χάρτη"
            </p>
            {mapData && (
              <MapGoogle
                editable
                mapData={mapData}
                setAddress={setAddress}
                markerPosition={markerPosition}
                setMarkerPosition={setMarkerPosition}
                positionError={positionError}
                setPositionError={setPositionError}
                initialZoom={11}
              />
            )}
          </div>

          <h2 className="govgr-heading-m font-bold mt-6 mb-6">Φωτογραφίες</h2>
          <p className="govgr-caption-m mb-3">
            Eπισυνάψτε φωτογραφίες σχετικά με το άιτημά σας και πατήστε{" "}
            <b>"Υποβολή"</b> για να το υποβάλλετε.
          </p>
          <ImageUploader images={images} setImages={setImages} />

          <div className="mt-12">
            <h2 className="govgr-heading-m font-bold">Σχόλια / Παρατηρήσεις</h2>
            <p className="govgr-caption-m mb-3">
              Προσθέσετε σχόλια ή παρατηρήσεις που θα βοηθήσουν να εξυπηρετηθεί
              καλύτερα το αίτημά σας.
            </p>
            <TextField
              fullWidth
              multiline
              placeholder="Προσθήκη κειμένου..."
              value={notes}
              onChange={handleTextInput}
              rows={7}
            />
          </div>

          <div className="mt-15">
            {categoryError && (
              <Alert className="w-full mb-1" severity="error">
                Δεν έχετε επιλέξει κατηγορία αιτήματος!
              </Alert>
            )}
            {serviceError && (
              <Alert className="w-full mb-1" severity="error">
                Δεν έχετε επιλέξει υπηρεσία/πρόβλημα!
              </Alert>
            )}
            {!markerPosition && (
              <Alert className="w-full mb-1" severity="error">
                Δεν έχετε επιλέξει σημείο στον χάρτη!
              </Alert>
            )}
            {!isChecked && (
              <Alert className="w-full mb-1" severity="error">
                Δεν έχετε συμφωνήσει με τους όρους
              </Alert>
            )}
            {positionError && (
              <Alert className="w-full mb-1" severity="error">
                Έχετε επιλέξει σημείο εκτός ορίων!
              </Alert>
            )}

            <div className="govgr-checkboxes__item ml-[2px] mt-6">
              <label className="govgr-label govgr-checkboxes__label mt-6">
                Έχω διαβάσει και συμφωνώ με{" "}
                <span style={{ display: "inline-block" }}>
                  τους
                  <div
                    className="govgr-masthead ml-2"
                    style={{ display: "inline-block" }}
                  >
                    <div className="govgr-link text-[#003375] hover:underline">
                      όρους χρήσης
                    </div>
                  </div>
                  <span> της υπηρεσίας</span>
                </span>
              </label>
              <input
                className="govgr-checkboxes__input mt-6"
                type="checkbox"
                name="none"
                onChange={handleCheckboxChange}
              />
            </div>

            <div className="flex justify-end">
              <GovGRCTAButton
                disabled={submitDisabled}
                label={"Υποβολή"}
                onClick={onSubmit}
                loading={loadingSubmit}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TicketCreate;
