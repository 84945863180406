import { useCallback, useEffect, useRef, useState } from "react";
import { useNavigate, useLocation } from "react-router";
import GovGrToolTipHeader from "../../../Components/GovGrToolTipHeader/GovGrToolTipHeader";
import TicketsTable from "./TicketsTable";
import ArrowCircleLeftOutlinedIcon from "@mui/icons-material/ArrowCircleLeftOutlined";
import AuthSetAgent from "../../../Api/AuthSetAgent";

const PAGE_SIZE = 15;

const TicketsMy = () => {
  const navigate = useNavigate();
  const [myTickets, setMyTickets] = useState([]);
  const [selectedTicket, setSelectedTicket] = useState();
  // const scrollRef = useRef();
  // const location = useLocation();
  // let routeParamId = location?.state?.ticketId;
  const [totalPages, setTotalPages] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);

  // const routeIdRef = useRef(routeParamId);

  // const scrollToTicket = useCallback(() => {
  //   scrollRef?.current?.scrollIntoView &&
  //     scrollRef.current.scrollIntoView({ behavior: "smooth" });
  // }, [scrollRef]);

  const onSelect = useCallback(
    (item) => {
      setSelectedTicket(item);
      // scrollToTicket();
    },
    []
    // [scrollToTicket]
  );

  useEffect(() => {
    const getData = async () => {
      await AuthSetAgent.tickets
        .getMyTickets(currentPage, PAGE_SIZE)
        .then((res) => {
          const totalPages = Math.ceil(
            res.result.totalCount / res.result.pageSize
          );
          setTotalPages(totalPages);
          setMyTickets(res?.result.items || []);
          setSelectedTicket(null);
        })
        .catch((e) => {
          console.error("Error in getMyTickets: ", e);
        });
    };

    getData();
  }, [currentPage]);

  // If route parameter provided, then setSelectedTicket based on provided Id
  //
  // useEffect(() => {
  //   if (myTickets?.length > 0 && routeIdRef.current !== null) {
  //     const ticket = myTickets.find(
  //       (ticket) => ticket?.id === routeIdRef.current
  //     );
  //     if (!ticket) {
  //       currentPage < totalPages && setCurrentPage((prev) => prev + 1);
  //       return;
  //     }
  //     setSelectedTicket(ticket);
  //     routeIdRef.current = null;
  //   }
  // }, [myTickets, routeParamId]);

  // Scroll ticket view container into view on selectedTicket change
  //
  // useEffect(() => {
  //   scrollToTicket();
  // }, [routeParamId, selectedTicket, scrollRef, scrollToTicket]);

  return (
    <div>
      <GovGrToolTipHeader />
      <div className="flex flex-col lg:flex-row w-full">
        <div className="w-full lg:w-2/3 md:ml-40 sm:ml-20 ml-10">
          <div className="flex items-center">
            <ArrowCircleLeftOutlinedIcon
              onClick={() => {
                navigate(-1);
              }}
              style={{
                fill: "#004a75",
                marginRight: "14px",
                marginBottom: "14px",
                fontSize: "50px",
                cursor: "pointer",
              }}
              fontSize="large"
            />
            <h1 className="govgr-heading-l mt-6">Τα Αιτήματά μου</h1>
          </div>
          {myTickets?.length > 0 ? (
            <TicketsTable
              onSelect={onSelect}
              tickets={myTickets}
              currentPage={currentPage}
              setCurrentPage={setCurrentPage}
              totalPages={totalPages}
            />
          ) : (
            <div className="flex justify-center items-center p-10">
              Δεν υπάρχουν αιτήματα
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default TicketsMy;
