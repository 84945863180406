import { observer } from "mobx-react";
import { useRantevouStore } from "../../../Stores/RantevouStore";
import { useNavigate } from "react-router-dom";
import { useState, useEffect } from "react";
import { Box, CircularProgress } from "@mui/material";
import GovGrToolTipHeader from "../../../Components/GovGrToolTipHeader/GovGrToolTipHeader";
import IconText from "../../../Components/IconText/IconText";
import { organizationId } from "../../../Config/constant";
import { useServicesStore } from "../../../Stores/ServicesStore";
import RantevousTable from "../RantevousTable/RantevousTable";
import CancelledRantevousTable from "../CancelledRantevousTable/CancelledRantevousTable";

const MyRantevouTables = () => {
  const rantevouStore = useRantevouStore();
  const servicesStore = useServicesStore();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [appointments, setAppointments] = useState([]);
  const [cancelledAppointments, setCancelledAppointments] = useState([]);

  const organizationName =
    parseInt(organizationId) === servicesStore.organizationById?.id
      ? servicesStore.organizationById?.name
      : "";

  const loader = (
    <Box className="flex justify-center items-center p-20">
      <CircularProgress size={50} />
    </Box>
  );

  useEffect(() => {
    if (organizationId) {
      setLoading(true);
      servicesStore
        .getOrganizationById(organizationId)
        .then((resp) => {})
        .catch((error) => {})
        .finally(() => {
          setLoading(false);
        });
    }
  }, [servicesStore]);

  useEffect(() => {
    setLoading(true);
    rantevouStore
      .getRantevouAppointments()
      .then((res) => {
        const allAppointments = res.result.items;
        const appointmentsWithStatusZeroToThree = allAppointments?.filter(
          (appointment) => appointment?.status >= 0 && appointment?.status <= 3
        );
        const cancelledAppointments = allAppointments?.filter(
          (appointment) => appointment?.status === 4
        );
        setAppointments(appointmentsWithStatusZeroToThree);
        setCancelledAppointments(cancelledAppointments);
      })
      .catch((error) => {})
      .finally(() => {
        setLoading(false);
      });
  }, [rantevouStore]);

  return (
    <>
      <div>
        <GovGrToolTipHeader />
      </div>
      {loading ? (
        loader
      ) : (
        <>
          <div className="mr-16 ml-5 md:ml-40 flex flex-col">
            <IconText navigation={"/dashboard"} text={"Τα ραντεβού μου"} />
            <p className="govgr-body-l">
              Υπηρεσία{" "}
              <b>
                ηλεκτρονικής υποβολής ραντεβού για την εξυπηρέτηση σε διάφορες
                υπηρεσίες
              </b>{" "}
              στην δικαιοδοσία{" "}
              {organizationName === "Περιφέρεια Δυτικής Ελλάδας"
                ? "της"
                : "του"}{" "}
              {organizationName}.
            </p>
            <p className="govgr-body-l mt-12">
              Επιλέξτε <b>"Δημιουργία νέου ραντεβού"</b> για να ξεκινήσετε.
            </p>

            <div className="flex items-start justify-center mt-12">
              <RantevousTable appointments={appointments} />
              <CancelledRantevousTable
                cancelledAppointments={cancelledAppointments}
              />
            </div>

            <button
              onClick={() => navigate("/appointment-services")}
              className="govgr-btn bg-[#003375] text-[#FFFF] govgr-btn-cta mt-8"
            >
              <div className="mb-1">Δημιουργία νέου ραντεβού</div>
              <svg
                viewBox="0 0 24 24"
                className="govgr-arrow--right govgr-svg-icon"
                focusable="false"
                aria-hidden="true"
              >
                <path
                  d="M8.5,2L6.1,4.3l7.6,7.7l-7.6,7.6L8.5,22l10-10L8.5,2z"
                  fill="white"
                />
              </svg>
            </button>
          </div>
        </>
      )}
    </>
  );
};

export default observer(MyRantevouTables);
