import { observer } from "mobx-react";
import { useNavigate } from "react-router-dom";
import { formatDateInDateMonthYear } from "../../../Common/formatDate/formatDate";

const CancelledRantevousTable = ({ cancelledAppointments }) => {
  const navigate = useNavigate();

  return (
    <div className="w-1/2 pb-full rounded-xl bg-lightgray">
      <div className="bg-[#003375] min-w-[90%] w-[90%] px-4 py-2 flex justify-center items-center text-white text-lg">
        <p>Ακυρωμένα Ραντεβού</p>
      </div>
      <div className="bg-gray-300 px-4 pt-4 min-w-[90%] w-[90%] justify-center items-center text-lg">
        <div className="ml-4">
          {cancelledAppointments?.length > 0 ? (
            <div>
              {cancelledAppointments?.slice(0, 3)?.map((item) => (
                <div key={item?.id}>
                  <p className="text-lg font-bold">
                    Κωδ. {item.id} | {item?.appointmentService}
                  </p>
                  <p className="text-lg mb-4">
                    Ημερ.{" "}
                    {formatDateInDateMonthYear(item?.timeFrom.slice(0, 10))} |{" "}
                    {"Ωρα: " + item?.timeFrom.slice(11, 16)} -{" "}
                    {item?.timeTo.slice(11, 16)}
                  </p>
                </div>
              ))}
              <div
                onClick={() => navigate("/my-rantevous-list")}
                className="flex justify-end items-end pb-4 pr-6 text-blue-400 cursor-pointer hover:underline"
              >
                περισσότερα ...
              </div>
            </div>
          ) : (
            <p className="text-lg text-center pb-4">
              Δεν υπάρχουν ακυρωμένα ραντεβού
            </p>
          )}
        </div>
      </div>
    </div>
  );
};

export default observer(CancelledRantevousTable);
