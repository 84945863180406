import { useCallback, useMemo, useState, memo, useEffect } from "react";
import {
  GoogleMap as GMap,
  useJsApiLoader,
  Autocomplete,
  MarkerF,
} from "@react-google-maps/api";
import { Alert, TextField } from "@mui/material";
import {
  convertPolygonToClosedPolygon,
  convertPolygonToGooglePaths,
  isPointInMultiPolys,
} from "../../Common/Geo/Geo";
import clsx from "clsx";

const containerStyle = {
  width: "100%",
  height: "60vh",
};

const center = {
  lat: Number(process.env.REACT_APP_ORG_LAT_LNG.split(",")[0]) || 37.98381,
  lng: Number(process.env.REACT_APP_ORG_LAT_LNG.split(",")[1]) || 23.727539,
};

const GoogleMapID = "google-map-element";
const ApiKey = process.env.REACT_APP_GOOGLE_MAPS_API_KEY;
const DETAIL_ZOOM = 16;
const DEFAULT_INITIAL_ZOOM = 9;

const PolygonOptions = {
  fillColor: "red",
  fillOpacity: 0.1,
  strokeColor: "red",
  strokeOpacity: 1,
  strokeWeight: 2,
  clickable: false,
  draggable: false,
  editable: false,
  geodesic: false,
};

const MapGoogle = ({
  editable = false,
  initialZoom = DEFAULT_INITIAL_ZOOM,
  mapData,
  markerPosition,
  setMarkerPosition,
  setAddress,
  positionError,
  setPositionError,
  customCenter = null,
}) => {
  const turfPolygon = useMemo(() => {
    if (!editable) {
      return null;
    }

    return convertPolygonToClosedPolygon(mapData);
  }, [editable, mapData]);
  const [libraries] = useState(["places"]);
  const { isLoaded, loadError } = useJsApiLoader({
    id: GoogleMapID,
    googleMapsApiKey: ApiKey,
    libraries,
  });
  const [autocomplete, setAutocomplete] = useState(null);
  const [searchText, setSearchText] = useState("");
  const [map, setMap] = useState();
  const [zoom, setZoom] = useState(initialZoom);
  const containerClass = clsx([
    "rounded-md p-1 border-2",
    positionError ? "border-red-500" : "border-transparent",
  ]);

  const onLoadMap = useCallback(
    (mapInstance) => {
      mapInstance.setOptions({
        draggableCursor: "pointer",
        draggingCursor: "grabbing",
        disableDoubleClickZoom: true,
      });

      setMap(mapInstance);

      if (!editable) {
        return;
      }

      const convertedPolygon = convertPolygonToGooglePaths(mapData);

      const poly = new window.google.maps.Polygon({
        paths: convertedPolygon,
        ...PolygonOptions,
      });

      poly.setMap(mapInstance);
    },
    [editable, mapData]
  );

  const onUnmount = useCallback(() => {
    setMap(null);
    setAutocomplete(null);
  }, []);

  const getPlaceOnClick = useCallback(async ([lng, lat]) => {
    return await fetch(
      `https://maps.googleapis.com/maps/api/geocode/json?latlng=${lat},${lng}&key=${ApiKey}`
    )
      .then(async (response) => {
        const json = await response.json();
        if (json?.results[1]?.formatted_address) {
          return json?.results[1]?.formatted_address;
        }

        return "";
      })
      .catch((error) => {
        console.error(error);
      });
  }, []);

  const onMapClick = useCallback(
    async (event) => {
      if (!editable || !map) {
        return;
      }

      const point = [event?.latLng?.lng(), event?.latLng?.lat()];
      const formattedAddress = await getPlaceOnClick(point);
      setSearchText(formattedAddress);
      setAddress(formattedAddress);

      if (!isPointInMultiPolys(point, turfPolygon)) {
        setPositionError(true);
      } else {
        setPositionError(false);
      }

      const position = {
        lat: event?.latLng?.lat(),
        lng: event?.latLng?.lng(),
      };

      setMarkerPosition(position);
      map?.panTo(position);
    },
    [
      editable,
      map,
      getPlaceOnClick,
      setAddress,
      turfPolygon,
      setMarkerPosition,
      setPositionError,
    ]
  );

  const onPlaceChanged = useCallback(() => {
    if (!autocomplete) {
      return;
    }

    const place = autocomplete?.getPlace(searchText);

    if (!place?.geometry || !place?.name) {
      return;
    }

    setSearchText(place?.name);
    setAddress(place?.name);

    const point = [
      place?.geometry?.location?.lng(),
      place?.geometry?.location?.lat(),
    ];

    if (!isPointInMultiPolys(point, turfPolygon)) {
      setPositionError(true);
    } else {
      setPositionError(false);
    }

    const position = {
      lat: place?.geometry?.location?.lat(),
      lng: place?.geometry?.location?.lng(),
    };

    setMarkerPosition(position);
    map?.panTo(position);
    setZoom(DETAIL_ZOOM);
  }, [
    autocomplete,
    searchText,
    setAddress,
    turfPolygon,
    setMarkerPosition,
    map,
    setPositionError,
  ]);

  const onLoadAutocomplete = useCallback((autocompleteInstance) => {
    setAutocomplete(autocompleteInstance);
  }, []);

  const onAutocompleteUnmount = useCallback((autocompleteInstance) => {
    setAutocomplete(null);
  }, []);

  const handleTextInput = useCallback(
    (e) => {
      setMarkerPosition(undefined);
      setPositionError(false);
      setSearchText(e.target.value);
    },
    [setMarkerPosition, setPositionError]
  );

  // On unmount, make sure map and autocomplete are both removed from memory
  //
  useEffect(() => {
    return () => {
      setMap(null);
      setAutocomplete(null);
    };
  }, []);

  if (loadError) {
    return (
      <span>
        Παρουσιάστηκε σφάλμα κατά τη φόρτωση του χάρτη. Παρακαλώ δοκιμάστε ξανά.
      </span>
    );
  }

  if (!isLoaded) {
    return null;
  }
  return (
    <div className={containerClass}>
      {editable && (
        <div className="mb-3">
          <Autocomplete
            onLoad={onLoadAutocomplete}
            onUnmount={onAutocompleteUnmount}
            onPlaceChanged={onPlaceChanged}
            onChange={onPlaceChanged}
            onKeyDown={(e) => {
              if (e.key === "Enter") {
                e.stopPropagation();
              }
            }}
          >
            <TextField
              fullWidth
              placeholder="Αναζήτηση διεύθυνσης στο χάρτη..."
              value={searchText}
              onChange={handleTextInput}
            />
          </Autocomplete>
        </div>
      )}
      <GMap
        mapContainerStyle={containerStyle}
        center={customCenter || center}
        zoom={zoom}
        onClick={onMapClick}
        onDblClick={() => {}}
        onLoad={onLoadMap}
        onUnmount={onUnmount}
      >
        {!!markerPosition && <MarkerF position={markerPosition} />}
        {positionError && (
          <Alert className="absolute bottom-2 left-2" severity="error">
            Έχετε επιλέξει σημείο εκτός ορίων!
          </Alert>
        )}
      </GMap>
    </div>
  );
};

export default memo(MapGoogle);
