import { useCallback } from "react";
import ImageGrid from "../ImageGrid/ImageGrid";
import UploadFileInput from "../UploadFileInput/UploadFileInput";
import { error } from "../../Common/Toast/Toast";

const ImageUploader = ({ images, setImages }) => {
  const onChange = useCallback((event) => {
    const file = event?.target?.files[0];

    if (!file) {
      return;
    }

    //This expression checks if the file size is greater than 5 megabytes by converting 5 MB to bytes (5 * 1024 * 1024).
    if (file?.size > 5 * 1024 * 1024) {
      error("Το μέγεθος του αρχείου πρέπει να είναι εώς 5MB.");
      return;
    }

    setImages((prev) => {
      if (prev.find((item) => item?.name === file?.name)) {
        return prev;
      }

      return [...prev, file];
    });

    // We need this! Input value should be cleared to trigger onchange for same image.
    //
    event.target.value = "";
  }, []);

  const onImageRemove = useCallback((image) => {
    setImages((prev) => prev.filter((item) => item?.name !== image?.name));
  }, []);

  return (
    <>
      <ImageGrid editable images={images} onRemove={onImageRemove} />
      <UploadFileInput
        accept="image/*"
        className="mt-5"
        label="Ανέβασμα εικόνας"
        onChange={onChange}
      />
    </>
  );
};

export default ImageUploader;
