import { action, computed, makeObservable, observable, reaction } from "mobx";
import { createContext, useContext } from "react";
import AuthSetAgent from "../Api/AuthSetAgent";
import { setBearerToken } from "../Common/HttpClient/HttpClient";
import { decodeJwtToken } from "../Common/Encryption/Encryption";
import { success } from "../Common/Toast/Toast";
// import {
//   getRefreshTokenFromSessionStorage,
//   getTokenFromSessionStorage,
//   saveRefreshTokenToSessionStorage,
//   saveTokenToSessionStorage,
// } from "../Common/Storage/SessionStorage";
import i18n from "../Config/Localization/i18n";
import { clearCookie, getCookie, setCookie } from "../Common/Storage/Cookies";

class AuthStore {
  authUser = {};

  token = undefined;

  refreshToken = null;

  navigate = null;

  intendedUrl = null;

  userDto = {};

  userDto2 = {};

  phoneNumberConfirmed = null;

  constructor() {
    makeObservable(this, {
      authUser: observable,
      userAuthenticated: computed,
      token: observable,
      refreshToken: observable,
      userDto: observable,
      phoneNumberConfirmed: observable,
      userDto2: observable,
      logout: action,
      setUserData: action,
      setConfirmationSmsResp: action,
      // login: action,
      initiateTaxisLogin: action,
      callback: action,
      updateContactDetails: action,
      confirmPhoneNumber: action,
      confirmEmail: action,
      setUserDataPhone: action,
      sendVerificationEmail: action,
      sendVerificationSms: action,
      setAuthUser: action,
      initializeTokens: action,
      isCitizen: computed,
      authUserIsNotLoaded: computed,
    });

    reaction(() => this.token, detectTokenValidity.bind(this));
    reaction(() => this.token, getUserProfile.bind(this));
    reaction(
      () => this.refreshToken,
      (refreshToken) => setCookie("refreshToken", refreshToken, 90)
    );

    this.initializeTokens();
  }

  initializeTokens() {
    // this.refreshToken = getRefreshTokenFromSessionStorage();
    // this.token = getTokenFromSessionStorage();
    this.token = getCookie("token");
    this.refreshToken = getCookie("refreshToken");
  }

  logout() {
    this.authUser = null;
    sessionStorage.clear();
    localStorage.clear();
    clearCookie("token");
    clearCookie("refreshToken");
    setBearerToken(null);
  }

  get userAuthenticated() {
    return !!this.authUser;
  }

  // async login({ email, password }) {
  //   try {
  //     const loginResp = await AuthSetAgent.user.login(email, password);
  //     if (!loginResp) {
  //       return;
  //     }
  //     const token = loginResp.token;
  //     const refreshToken = loginResp.refreshToken;

  //     if (!token || !refreshToken) {
  //       return;
  //     }
  //     this.saveTokens(token, refreshToken);
  //     const decoded = JSON.parse(atob(token.split(".")[1]));
  //     success("Welcome, " + decoded?.email);
  //   } catch (error) {}
  // }

  // async register(body) {
  //   try {
  //     const registerResp = await AuthSetAgent.taxis.register(body);
  //     if (!registerResp) {
  //       return;
  //     }
  //     const token = registerResp.token;
  //     const refreshToken = registerResp.refreshToken;

  //     if (!token || !refreshToken) {
  //       return;
  //     }
  //     this.saveTokens(token, refreshToken);
  //     // const decoded = JSON.parse(atob(token.split(".")[1]));
  //     // success("Welcome, " + decoded?.email);
  //   } catch (error) {}
  // }

  async callback(code) {
    try {
      const callbackResp = await AuthSetAgent.taxis.callback(code);
      if (!callbackResp) {
        return;
      }

      const token = callbackResp?.token;
      const refreshToken = callbackResp?.refreshToken;

      if (!token) {
        return this.logout();
      }
      this.saveTokens(token, refreshToken);
    } catch (error) {}
  }

  updateContactDetails(body) {
    return AuthSetAgent.taxis.updateContactDetails(body);
  }

  sendVerificationEmail(afm, email) {
    return AuthSetAgent.taxis.sendVerificationEmail(afm, email);
  }

  sendVerificationSms(afm, phoneNumber) {
    return AuthSetAgent.taxis.sendVerificationSms(afm, phoneNumber);
  }

  confirmPhoneNumber(afm, code) {
    return AuthSetAgent.taxis.confirmPhoneNumber(afm, code);
  }

  confirmEmail(afm, code) {
    return AuthSetAgent.taxis.confirmEmail(afm, code);
  }

  setUserData(userDto = {}) {
    this.userDto = userDto;
  }

  setUserDataPhone(userDto2 = {}) {
    this.userDto2 = userDto2;
  }

  setConfirmationSmsResp(phoneNumberConfirmed) {
    this.phoneNumberConfirmed = phoneNumberConfirmed;
  }

  async initiateTaxisLogin() {
    return await AuthSetAgent.taxis.gsisLogin();
  }

  saveTokens(token, refreshToken) {
    // saveTokenToSessionStorage(token);
    setCookie("token", token, 90);
    setCookie("refreshToken", refreshToken, 90);
    // saveRefreshTokenToSessionStorage(refreshToken);
    this.token = token;
    this.refreshToken = refreshToken;
  }

  setAuthUser(user) {
    this.authUser = user;
  }

  updateProfile(phoneNumber) {
    AuthSetAgent.user
      .updateProfile(phoneNumber)
      .then((response) => {
        success(i18n.t("profile.ProfileWasSuccessfullyUpdated"));
      })
      .catch((e) => {});
  }

  updatePassword(currentPassword, newPassword, confirmNewPassword) {
    return AuthSetAgent.user
      .changePassword(currentPassword, newPassword, confirmNewPassword)
      .then((response) => {
        success(i18n.t("forms.PasswordWasSuccessfullyUpdated"));
      })
      .catch((e) => {});
  }

  forgotPassword(email) {
    return AuthSetAgent.user
      .forgotPassword(email)
      .then((resp) => {
        success(i18n.t("forgotPassword.ConfirmationEmail"));
      })
      .catch((e) => {});
  }

  resetPassword(data, token) {
    return AuthSetAgent.user
      .resetPassword(data, token)
      .then((resp) => {
        success(i18n.t("resetPassword.YouHaveSuccessfullyChangedYourPassword"));
      })
      .catch((e) => {});
  }

  async updateToken() {
    try {
      const token = getCookie("token");
      const refreshToken = getCookie("refreshToken");

      if (!token || !refreshToken) {
        return;
      }

      const params = {
        token: token,
        refreshToken: refreshToken,
      };

      const resp = await AuthSetAgent.user.refreshToken(params);
      setCookie("token", resp.token, 90);
      setCookie("refreshToken", resp.refreshToken, 90);
    } catch (error) {
      this.logout();
    }
  }

  navigateToIntendedUrl() {
    if (this.navigate && this.intendedUrl) {
      this.navigate(this.intendedUrl);
      this.navigate = null;
      this.intendedUrl = null;
    }
  }

  get authUserIsNotLoaded() {
    return !Object.keys(this.authUser).length;
  }

  get isCitizen() {
    return this.authUser?.roles?.includes("Citizen");
  }
}

export const authStore = new AuthStore();
export const AuthStoreContext = createContext(authStore);
export const AuthStoreProvider = ({ children, store }) => (
  <AuthStoreContext.Provider value={store}>
    {children}
  </AuthStoreContext.Provider>
);
export const useAuthStore = () => useContext(AuthStoreContext);

function detectTokenValidity() {
  let sessionToken = getCookie("token");
  if (!sessionToken) {
    return this.logout();
  }
  try {
    setBearerToken(sessionToken);
    const payload = decodeJwtToken(sessionToken);
    if (payload.exp <= Math.floor(Date.now() / 1000)) {
      return this.updateToken();
    }
  } catch (e) {
    this.logout();
  }
}

function getUserProfile(token) {
  //The line below is needed to ensure proper functionality of the app
  if (!token) return this.logout();
  AuthSetAgent.user
    .getProfile()
    .then(({ userDto, roles, claims }) => {
      if (
        userDto !== undefined &&
        roles !== undefined &&
        claims !== undefined
      ) {
        const userObject = {
          ...userDto,
          roles,
          claims,
        };
        this.setAuthUser(userObject);
        this.navigateToIntendedUrl();
      } else {
        return this.logout();
      }
    })
    .catch((e) => this.logout());
}
