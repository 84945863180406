import { observer } from "mobx-react";
import GovGrToolTipHeader from "../../../Components/GovGrToolTipHeader/GovGrToolTipHeader";
import { organizationId } from "../../../Config/constant";
import { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useRantevouStore } from "../../../Stores/RantevouStore";
import { useServicesStore } from "../../../Stores/ServicesStore";

const CertificateResults = () => {
  const [certificateId, setCertificateId] = useState(null);
  const location = useLocation();
  const navigate = useNavigate();
  const rantevouStore = useRantevouStore();
  const servicesStore = useServicesStore();
  const certificateSubmission = location.state.certificate;
  const applicationCategoryFromSubmission =
    certificateSubmission?.certificate.applicationCategoryId;
  const matchingApplicationCategory =
    rantevouStore.allApplicationCategoriesList?.find(
      (elm) => elm.id === applicationCategoryFromSubmission
    )?.title;

  const organizationName =
    parseInt(organizationId) === servicesStore.organizationById?.id
      ? servicesStore.organizationById?.name
      : "---";

  useEffect(() => {
    if (organizationId) {
      servicesStore
        .getOrganizationById(organizationId)
        .then((resp) => {})
        .catch((error) => {})
        .finally(() => {});
    }
  }, [servicesStore]);

  useEffect(() => {
    if (location?.state?.certificateId) {
      setCertificateId(location.state.certificateId);
    }
  }, [location]);

  useEffect(() => {
    rantevouStore.getAllApplicationCategories();
  }, [rantevouStore.allApplicationCategoriesList?.length]);

  return (
    <>
      <GovGrToolTipHeader />
      <div className="flex justify-center items-center mt-16">
        <img className="w-20" src="success.png" alt="success" />
      </div>
      <div className="flex justify-center items-center mt-20">
        <p className="govgr-body-l">
          Η αίτησή σας για την υπηρεσία{" "}
          <b>
            {matchingApplicationCategory ? matchingApplicationCategory : "-"}
          </b>{" "}
          έχει υποβληθεί επιτυχώς.
        </p>
      </div>
      <div className="flex justify-center items-center mt-4">
        {" "}
        <p className="govgr-body-l">Θα λάβετε σχετικό email επιβεβαίωσης.</p>
      </div>
      <div className="flex justify-center items-center mt-4">
        <b>
          {" "}
          <p className="govgr-body-l font-bold">
            Κωδικός αίτησης: {certificateId}
          </p>
        </b>
      </div>
      <div className="flex justify-center items-center mt-4">
        <p className="govgr-body-l">
          Μπορείτε να παρακολουθείτε την εξέλιξη του αιτήματος έκδοσης <br />{" "}
          <p className="pl-28 mt-1">
            {" "}
            ηλεκτρονικού πιστοποιητικού επιλέγοντας{" "}
          </p>{" "}
          <p className="pl-32 mt-1">
            {" "}
            από το μενού <b>"Τα πιστοποιητικά μου" </b>{" "}
          </p>
        </p>
      </div>
      <div className="flex justify-center items-center mt-6">
        <p className="govgr-body-l ml-4">
          Για επιστροφή στην αρχική σελίδα των υπηρεσιών της {organizationName},
          πατήστε
        </p>
        <div
          className="govgr-masthead ml-1 govgr-link text-[#003375] text-[21px]"
          onClick={() => {
            navigate("/dashboard");
          }}
        >
          εδώ
        </div>
      </div>
    </>
  );
};

export default observer(CertificateResults);
