import { useCallback, useEffect, useState } from "react";
import { observer } from "mobx-react";
import GovGrToolTipHeader from "../../../Components/GovGrToolTipHeader/GovGrToolTipHeader";
import ArrowCircleLeftOutlinedIcon from "@mui/icons-material/ArrowCircleLeftOutlined";
import { useNavigate } from "react-router-dom";
import { useRantevouStore } from "../../../Stores/RantevouStore";
import { Box, CircularProgress, Modal } from "@mui/material";
import CancelIcon from "@mui/icons-material/Cancel";
import MainCard from "../../../Components/Theme/Cards/MainCard";
import { success } from "../../../Common/Toast/Toast";
import { formatDateInDateMonthYear } from "../../../Common/formatDate/formatDate";
import { AddToCalendarButton } from "add-to-calendar-button-react";
import dayjs from "dayjs";
import CircularSpinner from "../../../Components/Theme/Spinners/CircularSpinner";
import CustomPagination from "../../../Components/CustomPagination/CustomPagination";
import { GovGRCTAButton } from "../../../Components/GovGrCTAButton/GovGrCTAButton";

const PAGE_SIZE = 15;

const MyRantevouList = () => {
  const rantevouStore = useRantevouStore();
  const navigate = useNavigate();
  const [selectedAppointments, setSelectedAppointments] = useState([]);
  const [cancelationIndex, setCancelationIndex] = useState(-1);
  const [loading, setLoading] = useState(false);
  const [totalPages, setTotalPages] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPagesCancelled, setTotalPagesCancelled] = useState(0);
  const [currentPageCancelled, setCurrentPageCancelled] = useState(1);
  const [appointments, setAppointments] = useState([]);
  const [cancelledAppointments, setCancelledAppointments] = useState([]);

  const loader = (
    <Box className="flex justify-center items-center p-20">
      <CircularProgress size={50} />
    </Box>
  );

  const handleViewDetails = (appointment) => {
    if (selectedAppointments.includes(appointment)) {
      setSelectedAppointments(
        selectedAppointments.filter((item) => item !== appointment)
      );
    } else {
      setSelectedAppointments([...selectedAppointments, appointment]);
    }
  };

  const handleCancellation = (index) => {
    setCancelationIndex(index);
  };

  const closeCancelationModal = () => {
    setCancelationIndex(-1);
  };

  const updateRantevouStatus = (rantevouId) => {
    const payload = {
      status: 4,
    };

    setLoading(true);
    rantevouStore
      .updateRantevouAppointment(payload, rantevouId)
      .then(() => {
        refreshList();
        success("Το ραντεβού ακυρώθηκε επιτυχώς");
        closeCancelationModal();
      })
      .catch(() => {})
      .finally(() => {
        setLoading(false);
      });
  };

  const refreshList = useCallback(async () => {
    setLoading(true);

    await Promise.all([
      rantevouStore
        .getRantevouAppointments(currentPage, PAGE_SIZE, [0, 1, 2, 3])
        .then((res) => {
          const totalPages = Math.ceil(
            res.result.totalCount / res.result.pageSize
          );
          setTotalPages(totalPages);
          setAppointments(res.result.items);
        })
        .catch((error) => {
          console.error(error);
        }),
      rantevouStore
        .getRantevouAppointments(currentPageCancelled, PAGE_SIZE, [4]) // only cancelled appointments
        .then((res) => {
          const totalPages = Math.ceil(
            res.result.totalCount / res.result.pageSize
          );
          setTotalPagesCancelled(totalPages);
          setCancelledAppointments(res.result.items);
        }),
    ]).finally(() => {
      setLoading(false);
    });
  }, [currentPage, currentPageCancelled, rantevouStore]);

  useEffect(() => {
    setLoading(true);
    rantevouStore
      .getRantevouAppointments(currentPage, PAGE_SIZE, [0, 1, 2, 3])
      .then((res) => {
        const totalPages = Math.ceil(
          res.result.totalCount / res.result.pageSize
        );
        setTotalPages(totalPages);
        setAppointments(res.result.items);
      })
      .catch((error) => {})
      .finally(() => {
        setLoading(false);
      });
  }, [currentPage]);

  useEffect(() => {
    setLoading(true);
    rantevouStore
      .getRantevouAppointments(currentPageCancelled, PAGE_SIZE, [4]) // only cancelled appointments
      .then((res) => {
        const totalPages = Math.ceil(
          res.result.totalCount / res.result.pageSize
        );
        setTotalPagesCancelled(totalPages);
        setCancelledAppointments(res.result.items);
      })
      .catch((error) => {})
      .finally(() => {
        setLoading(false);
      });
  }, [currentPageCancelled]);

  return (
    <>
      <div>
        <GovGrToolTipHeader />
      </div>
      {loading ? (
        loader
      ) : (
        <>
          {" "}
          <div className="ml-40 flex items-center">
            <ArrowCircleLeftOutlinedIcon
              onClick={() => navigate("/my-rantevous")}
              style={{
                fill: "#004a75",
                marginRight: "14px",
                marginBottom: "10px",
                fontSize: "50px",
                cursor: "pointer",
              }}
              fontSize="large"
            />
            <h1 className="govgr-heading-l mt-6">Όλα τα ραντεβού μου</h1>
          </div>
          {appointments?.map((appointment, index) => (
            <div key={appointment?.id}>
              <div className="ml-40 mt-6 flex items-center">
                <img
                  className="mr-2"
                  width="55px"
                  src="/rantevou.png"
                  alt="rantevou"
                />
                <p className="govgr-body-l">
                  στις{" "}
                  <b>
                    {" "}
                    {formatDateInDateMonthYear(
                      appointment?.timeFrom.slice(0, 10)
                    )}
                  </b>
                  , στις <b> {appointment?.timeFrom.slice(11, 16)}</b>, ΤΜΗΜΑ{" "}
                  {appointment.subDepartment}
                </p>
                <div className="flex items-center justify-center ml-2">
                  {new Date(appointment?.timeFrom) > new Date() && (
                    <button
                      className="govgr-btn bg-[#b60202] text-[#FFFF] mt-4 ml-8"
                      onClick={() => handleCancellation(index)}
                    >
                      Ακύρωση
                    </button>
                  )}
                  <button
                    className="govgr-btn bg-[#003375] text-[#FFFF] mt-4 ml-12"
                    onClick={() => handleViewDetails(appointment)}
                  >
                    Προβολή
                  </button>
                </div>
              </div>
              {selectedAppointments.includes(appointment) && (
                <div className="ml-40 mt-4">
                  <h1 className="govgr-heading-l mb-0 ml-4">
                    Στοιχεία του ραντεβού σας
                  </h1>

                  {(dayjs(appointment?.timeFrom.slice(0, 10)).isAfter(
                    dayjs()
                  ) ||
                    dayjs(appointment?.timeFrom.slice(0, 10)).isSame(
                      dayjs(),
                      "day"
                    )) && (
                    <div className="flex items-center justify-left mt-2">
                      <AddToCalendarButton
                        name={appointment?.appointmentService}
                        startDate={appointment?.timeFrom.slice(0, 10)}
                        startTime={appointment?.timeFrom.slice(11, 16)}
                        endDate={appointment?.timeTo.slice(0, 10)}
                        endTime={appointment?.timeTo.slice(11, 16)}
                        options={[
                          "Google",
                          "Apple",
                          "MicrosoftTeams",
                          "Outlook.com",
                          "iCal",
                        ]}
                        description={
                          `Κωδικός Ραντεβού: ${appointment?.id} \n` +
                          `Τμήμα: ${appointment?.subDepartment} \n` +
                          `Υπηρεσία: ${appointment?.appointmentService} \n` +
                          `Ημερομηνία: ${formatDateInDateMonthYear(
                            appointment?.timeFrom.slice(0, 10)
                          )} \n` +
                          `Ώρα: ${appointment?.timeFrom.slice(
                            11,
                            16
                          )} - ${appointment?.timeTo.slice(11, 16)} \n` +
                          `Διεύθυνση: ${appointment?.address}`
                        }
                        timeZone="currentBrowser"
                        location={appointment?.address}
                        label="Προσθήκη στο ημερολόγιο"
                        buttonStyle="round"
                        lightMode="bodyScheme"
                        language="el"
                        hideCheckmark
                      ></AddToCalendarButton>
                    </div>
                  )}

                  <div className="ml-4 mt-4">
                    <b>
                      {" "}
                      <p className="govgr-body-l">
                        {"● ΚΩΔΙΚΟΣ ΡΑΝΤΕΒΟΥ: " + appointment.id}
                      </p>{" "}
                    </b>
                    <p className="govgr-body-l mt-4">
                      {"● ΤΜΗΜΑ: " + appointment.subDepartment}
                    </p>
                    <p className="govgr-body-l mt-4">
                      {"● ΥΠΗΡΕΣΙΑ: " + appointment.appointmentService}
                    </p>
                    <p className="govgr-body-l mt-4">
                      {"● ΗΜΕΡΟΜΗΝΙΑ: " +
                        formatDateInDateMonthYear(
                          appointment?.timeFrom.slice(0, 10)
                        )}
                    </p>
                    <p className="govgr-body-l mt-4">
                      {"● ΩΡΑ: " + appointment?.timeFrom.slice(11, 16)} -{" "}
                      {appointment?.timeTo.slice(11, 16)}
                    </p>
                    <p className="govgr-body-l mt-4">
                      {"● ΔΙΕΥΘΥΝΣΗ: " + appointment.sectorAddress}
                    </p>
                  </div>
                </div>
              )}

              <Modal
                className="flex items-center justify-center"
                open={cancelationIndex === index}
                onClose={closeCancelationModal}
              >
                <MainCard className="m-4 min-w-[25vw] min-h-[15vw]">
                  <div className="flex items-end justify-end">
                    <CancelIcon
                      className="modal-close-icon cursor-pointer"
                      onClick={closeCancelationModal}
                    />
                  </div>
                  <div className="flex items-center justify-center mt-12">
                    <p className="govgr-body-l text-center">
                      Θα ακυρώσετε το ραντεβού σας. <br /> θέλετε να συνεχίσετε;
                    </p>
                  </div>
                  <div className="flex items-center justify-center">
                    <button
                      onClick={() => updateRantevouStatus(appointment.id)}
                      className="govgr-btn bg-[#003375] text-[#FFFF] mt-8"
                    >
                      {loading ? (
                        <CircularSpinner
                          size="25px"
                          color="inherit"
                          sx={{ marginRight: "2px" }}
                        />
                      ) : (
                        "Ναι"
                      )}
                    </button>
                  </div>
                </MainCard>
              </Modal>
            </div>
          ))}
          {appointments?.length <= 0 && (
            <div className="flex justify-center items-center p-10">
              Δεν υπάρχουν ραντεβού
            </div>
          )}
          {appointments?.length > 0 && (
            <div className="flex justify-center items-center my-4">
              <CustomPagination
                className={"mt-4 flex justify-center items-center"}
                color={"#003375"}
                currentPage={currentPage}
                totalPages={totalPages}
                onPageChange={(page) => setCurrentPage(page)}
              />
            </div>
          )}
          {cancelledAppointments?.length > 0 && (
            <div className="ml-44 mt-12">
              {appointments?.length > 0 &&
                cancelledAppointments?.length > 0 && (
                  <hr className="border-1 border-[#231F20] w-1/3" />
                )}

              <div>
                <h2 className="govgr-heading-m mt-6">Ακυρωμένα ραντεβού</h2>
              </div>
            </div>
          )}
          {cancelledAppointments?.map((appointment, index) => (
            <div className="ml-44 mt-6 flex items-center" key={index}>
              <p className="govgr-body-l">
                στις {appointment?.timeFrom.slice(0, 10)}, στις{" "}
                {appointment?.timeFrom.slice(11, 16)}, ΤΜΗΜΑ{" "}
                {appointment.subDepartment}
              </p>
            </div>
          ))}
          {cancelledAppointments?.length <= 0 && (
            <div className="flex justify-center items-center p-10">
              Δεν υπάρχουν ακυρομένα ραντεβού
            </div>
          )}
          {cancelledAppointments?.length > 0 && (
            <div className="flex justify-center items-center my-4">
              <CustomPagination
                className={"mt-4 flex justify-center items-center"}
                color={"#003375"}
                currentPage={currentPageCancelled}
                totalPages={totalPagesCancelled}
                onPageChange={(page) => setCurrentPageCancelled(page)}
              />
            </div>
          )}{" "}
        </>
      )}
    </>
  );
};

export default observer(MyRantevouList);
