import React, { useState, useEffect } from "react";
import { observer } from "mobx-react";
import ArrowCircleRightOutlinedIcon from "@mui/icons-material/ArrowCircleRightOutlined";
import ArrowCircleLeftOutlinedIcon from "@mui/icons-material/ArrowCircleLeftOutlined";
import { useNavigate } from "react-router";
import { useScheduleStore } from "../../Stores/ScheduleStore";
import { formatDateInDateMonthYear } from "../../Common/formatDate/formatDate";
import dayjs from "dayjs";
import { toJS } from "mobx";
import { Typography } from "@mui/material";
import { useLocation } from "react-router-dom";

const RantevouCalendar = () => {
  const { state } = useLocation();
  const scheduleStore = useScheduleStore();
  const navigate = useNavigate();
  const [page, setPage] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const pageSize = 5;
  const [selectedSlots, setSelectedSlots] = useState(false);
  const [scheduleList, setScheduleList] = useState([]);
  const [slotGroups, setSlotGroups] = useState([]);

  useEffect(() => {
    setScheduleList(
      scheduleStore.scheduleData?.sort((a, b) =>
        dayjs(b.timeFrom).isBefore(dayjs(a.timeFrom)) ? 1 : -1
      )
    );
  }, [scheduleStore.scheduleData]);

  useEffect(() => {
    const current = dayjs();

    const totalDays = scheduleList
      .reduce((acc, curr) => {
        return [...acc, ...curr.days];
      }, [])
      .filter((date) => dayjs(date.slice(0, 10)).isAfter(current))
      .sort((a, b) =>
        dayjs(b.slice(0, 10)).isBefore(dayjs(a.slice(0, 10))) ? 1 : -1
      );

    const weeks = groupIntoWeeks(totalDays);
    setTotalPages(weeks.length);

    const slotGroups = scheduleList?.reduce((acc, curr) => {
      return [...acc, ...toJS(curr.slotGroups)];
    }, []);
    const filteredSlotGroups = slotGroups.filter((item) => {
      const timeTo = dayjs(item.timeTo);
      return timeTo.isAfter(current);
    });
    setSlotGroups(filteredSlotGroups);
  }, [scheduleList]);

  const groupIntoWeeks = (days) => {
    const weeks = [];
    let week = [];

    days
      ?.map((day) => day.slice(0, 10))
      .forEach((currentDay) => {
        // If the week is empty or the currentDay is the day after the last day in the week, add it to the week.
        if (
          !week.length ||
          dayjs(currentDay).diff(dayjs(week[0]), "days") < 5
        ) {
          week.push(currentDay);
        } else {
          // If there's a gap, finalize the current week and start a new one.
          weeks.push([...week]);
          week = [currentDay];
        }

        // If we've reached a Friday, finalize the week.
        if (dayjs(currentDay).day() === 5) {
          weeks.push([...week]);
          week = [];
        }
      });

    // Add any remaining days as a partial week.
    if (week.length) weeks.push([...week]);

    return weeks;
  };

  const handleNextClick = () => {
    const selectedSlotGroup = slotGroups?.find(
      (slotGroup) => slotGroup.id === selectedSlots.slotGroupId
    );

    scheduleStore.setSlotGroupId(selectedSlots.slotGroupId);

    navigate("/rantevou", {
      state: {
        timeFrom: selectedSlotGroup?.timeFrom,
        timeTo: selectedSlotGroup?.timeTo,
        selectedSeatName: state?.selectedSeatName,
        appointmentServiceId: state?.appointmentServiceId,
        slotGroupId: selectedSlots?.slotGroupId,
        selectedSubOrganization: state?.selectedSubOrganization,
        applicationCategoryId: state?.applicationCategoryId,
      },
    });
  };

  const handleSlotClick = (slotGroupId) => {
    const slotGroupID = { slotGroupId };
    const isSlotSelected =
      selectedSlots && selectedSlots.slotGroupId === slotGroupId;

    if (isSlotSelected) {
      setSelectedSlots(null);
    } else {
      setSelectedSlots(slotGroupID);
    }
  };

  const handlePageChange = (direction) => {
    if (direction === "prev") {
      // decrease counter
      let myPage = page > 0 ? page + -1 : 0;
      setPage(myPage);
    } else if (direction === "next") {
      // increase counter
      let myPage = 0;
      if (page + 1 <= totalPages - 1) {
        myPage = page + 1;
      } else {
        myPage = totalPages - 1;
      }
      setPage(myPage);
    }
  };

  const renderRantevouBadge = () => {
    return (
      <div className="flex justify-evenly items-center mt-8 space-x-4">
        <div className="flex items-center">
          <span className="inline-flex items-center justify-center px-4 py-2 text-xs font-bold leading-none text-white bg-[#003375] rounded-full"></span>
          <span className="ml-2">Διαθέσιμα ραντεβού</span>
        </div>
        <div className="flex items-center">
          <span className="inline-flex items-center justify-center px-4 py-2 text-xs font-bold leading-none text-gray-800 bg-gray-200 rounded-full"></span>
          <span className="ml-2">Μη διαθέσιμα ραντεβού</span>
        </div>
      </div>
    );
  };

  const calculateStartMonday = (currentPage) => {
    const myDays = scheduleList
      ?.reduce((acc, curr) => {
        return [...acc, ...curr.days];
      }, [])
      .filter((date) => dayjs(date.slice(0, 10)).isAfter(dayjs()));
    const firstDay = dayjs(myDays[0]?.slice(0, 10));
    const daysSinceLastMonday = firstDay.day() === 0 ? 6 : firstDay.day() - 1; // 0 for Sunday, 1 for Monday, etc.
    const lastMonday = firstDay.subtract(daysSinceLastMonday, "day");
    const validLastMonday =
      firstDay.day() === 5 ? lastMonday.add(7, "day") : lastMonday;
    return validLastMonday.add(currentPage * 7, "day");
  };

  const startMonday = calculateStartMonday(page);

  const generateWeekDays = (startMonday) => {
    const days = [];
    for (let i = 0; i < 5; i++) {
      days.push(startMonday.add(i, "days").format("YYYY-MM-DD"));
    }
    return days;
  };

  const weekDays = generateWeekDays(startMonday);

  const renderRantevouCalendar = () => {
    const myDays = scheduleList
      ?.reduce((acc, curr) => {
        return [...acc, ...curr.days];
      }, [])
      .filter((date) => dayjs(date.slice(0, 10)).isAfter(dayjs()));
    return (
      <>
        <div
          className="grid mt-6 gap-2 grid-cols-7 w-full justify-between items-start overflow-auto"
          style={{
            gridTemplateColumns: "4rem 14rem 14rem 14rem 14rem 14rem 4rem",
          }}
        >
          <div className="flex items-center justify-end h-4/5 w-full">
            {page > 0 && (
              <div onClick={() => handlePageChange("prev")}>
                <ArrowCircleLeftOutlinedIcon
                  style={{
                    fill: "#003375",
                    cursor: "pointer",
                    fontSize: "3.5rem",
                  }}
                />
              </div>
            )}
          </div>
          {weekDays?.map((day, dayIndex) => {
            const hasData = myDays
              .map((item) => item.slice(0, 10))
              .includes(day);
            const daySlots = slotGroups?.filter(
              (slotGroup) =>
                slotGroup.timeFrom.slice(0, 10) === day.slice(0, 10)
            );

            return (
              <div key={day + dayIndex} className="relative">
                <p className="flex justify-center items-center font-bold text-[1.3em] text-sm mb-2">
                  {formatDateInDateMonthYear(day)}
                </p>
                <h3 className="flex justify-center items-center text-lg mb-2">
                  {new Date(day).toLocaleDateString("el-GR", {
                    weekday: "long",
                  })}
                </h3>

                <div className="flex flex-wrap justify-center items-center">
                  {hasData ? (
                    daySlots.map((slotGroup) => {
                      const timeFrom = slotGroup.timeFrom.slice(11, 16);
                      const timeTo = slotGroup.timeTo.slice(11, 16);

                      return (
                        <>
                          <button
                            disabled={
                              !slotGroup.slots.some(
                                (slot) => slot.isAvailable === true
                              )
                            }
                            key={slotGroup.id}
                            className={`px-4 sm:px-8 py-[10px] rounded-full min-w-[93%] max-w-[93%] ${
                              selectedSlots?.slotGroupId === slotGroup.id
                                ? "bg-green-500 text-white"
                                : slotGroup.slots.some(
                                    (slot) => slot.isAvailable === true
                                  )
                                ? "bg-[#003375] text-white"
                                : "bg-[#c9c9c9] text-black"
                            } text-xs sm:text-sm mb-2`}
                            onClick={() => handleSlotClick(slotGroup.id)}
                          >
                            <Typography variant="h5">
                              {timeFrom} - {timeTo}
                            </Typography>
                          </button>
                        </>
                      );
                    })
                  ) : (
                    // display a short message in a grey background that would say "Δεν υπάρχουν διαθέσιμα ραντεβού για αυτή την ημέρα" and will extend to the whole width and height of the column
                    <div className="flex justify-center items-center text-xs sm:text-sm mb-2 bg-[#c9c9c9] text-black px-4 sm:px-8 py-[10px] rounded-lg min-w-[93%] max-w-[93%]">
                      <Typography variant="h5" align="center">
                        Δεν υπάρχουν διαθέσιμα ραντεβού για αυτή την ημέρα
                      </Typography>
                    </div>
                  )}
                </div>
              </div>
            );
          })}
          <div className="flex items-center justify-start h-4/5">
            {page + 1 < totalPages && (
              <div
                className="flex items-center"
                onClick={() => handlePageChange("next")}
              >
                <ArrowCircleRightOutlinedIcon
                  style={{
                    fill: "#003375",
                    cursor: "pointer",
                    fontSize: "3.5rem",
                  }}
                />
              </div>
            )}
          </div>
        </div>
        {renderRantevouBadge()}
      </>
    );
  };

  return (
    <>
      <div className="w-full">
        {renderRantevouCalendar()}
        <div className="ml-8 sm:ml-20">
          {!selectedSlots ? (
            <button className="govgr-btn bg-gray-200 text-[#FFFF] mt-12">
              <div className="mb-1 w-full">Συνέχεια</div>
              <svg
                viewBox="0 0 24 24"
                className="govgr-arrow--right govgr-svg-icon"
                focusable="false"
                aria-hidden="true"
              >
                <path
                  d="M8.5,2L6.1,4.3l7.6,7.7l-7.6,7.6L8.5,22l10-10L8.5,2z"
                  fill="white"
                />
              </svg>
            </button>
          ) : (
            <button
              onClick={handleNextClick}
              className="govgr-btn bg-[#003375] text-[#FFFF] mt-12"
            >
              <div className="mb-1 w-full">Συνέχεια</div>
              <svg
                viewBox="0 0 24 24"
                className="govgr-arrow--right govgr-svg-icon"
                focusable="false"
                aria-hidden="true"
              >
                <path
                  d="M8.5,2L6.1,4.3l7.6,7.7l-7.6,7.6L8.5,22l10-10L8.5,2z"
                  fill="white"
                />
              </svg>
            </button>
          )}
        </div>
      </div>
    </>
  );
};

export default observer(RantevouCalendar);
