import { observer } from "mobx-react";
import GovGrToolTipHeader from "../../../Components/GovGrToolTipHeader/GovGrToolTipHeader";
import { TextField } from "@mui/material";
import { useCertificatesStore } from "../../../Stores/CertificatesStore";
import { useNavigate } from "react-router";
import { useEffect, useState } from "react";
import Accordions from "../../../Components/Accordion/Accordion";
import { useDepartmentStore } from "../../../Stores/DepartmentStore";
import IconText from "../../../Components/IconText/IconText";
import { normalizeSearchQuery, organizationId } from "../../../Config/constant";
import { toJS } from "mobx";

const CertificatesSelection = () => {
  const certificatesStore = useCertificatesStore();
  const departmentStore = useDepartmentStore();
  const navigate = useNavigate();
  const [dataFetched, setDataFetched] = useState(false);
  const [selectedCertificateId, setSelectedCertificateId] = useState(null);
  const [selectedSubOrganization, setSelectedSubOrganization] = useState(null);
  const [selectedApplicationCategoryId, setSelectedApplicationCategoryId] =
    useState(null);
  const [isAccordionOpen, setIsAccordionOpen] = useState(true);
  const [searchCertificate, setSearchCertificate] = useState("");

  const onCertificateClick = (
    certificateID,
    subOrganizationId,
    applicationCategoryId
  ) => {
    setSelectedCertificateId(certificateID);
    setSelectedSubOrganization(subOrganizationId);
    setSelectedApplicationCategoryId(applicationCategoryId);
  };

  const handleAccordionToggle = (isExpanded) => {
    setIsAccordionOpen(isExpanded);
  };

  const handleNext = () => {
    const payload = {
      subOrganizationId: selectedSubOrganization,
      certificateId: selectedCertificateId,
    };

    departmentStore
      .getDepartmentsBySubOrganizationAndCertificateId(payload)
      .then((resp) => {
        certificatesStore.setSubOrganizationId(selectedSubOrganization);
        certificatesStore.setCertificateId(selectedCertificateId);
        navigate("/certificates-seat-selection", {
          state: {
            selectedApplicationCategoryId: selectedApplicationCategoryId,
          },
        });
      });
  };

  const filteredCertificates = certificatesStore.certificatesList?.map(
    (subOrg) => ({
      ...subOrg,
      certificates: subOrg.certificates?.filter((certificate) => {
        const certificateTitleNormalized = normalizeSearchQuery(
          certificate?.name || ""
        );
        const searchQueryNormalized = normalizeSearchQuery(searchCertificate);
        return certificateTitleNormalized.includes(searchQueryNormalized);
      }),
    })
  );

  useEffect(() => {
    if (searchCertificate && !isAccordionOpen) {
      setIsAccordionOpen(true);
    }
  }, [searchCertificate, isAccordionOpen]);

  useEffect(() => {
    if (!dataFetched && organizationId) {
      certificatesStore.getCertificatesUnderSubOrganization(organizationId);
      setDataFetched(true);
    }
  }, [certificatesStore.certificatesList?.length, dataFetched]);

  return (
    <>
      <div>
        <GovGrToolTipHeader />
      </div>
      <div className="ml-40 mr-40">
        <IconText
          navigation={"/my-certificates"}
          text={" Δημιουργία νέας αίτησης | Επιλογή πιστοποιητικού"}
        />
        <p className="govgr-body-l">
          Επιλέξτε το πιστοποιητικό / βεβαίωση / έγγραφο που θέλετε να εκδώσετε
          ηλεκτρονικά με χρήση της <b>Αναζήτησης</b> ή επιλέξτε το από την
          <b> λίστα με βάση την Κατηγορία/ Γενική Διεύθυνση </b> που υπάγεται
          και πατήστε "Συνέχεια":
        </p>

        <div className="mt-6 mr-12">
          <TextField
            fullWidth
            label="Πληκτρολογήστε για αναζήτηση ..."
            value={searchCertificate}
            onChange={(e) => setSearchCertificate(e.target.value)}
            sx={{
              "& .MuiOutlinedInput-root": {
                fontSize: "1.2rem",
              },
            }}
          />
        </div>

        {filteredCertificates?.map((subOrg, index) => (
          <div key={index}>
            <div className="flex items-center">
              {certificatesStore.certificatesList?.length === 1 ? (
                <div className="mt-5">
                  {subOrg?.certificates
                    ?.filter((certificate) => certificate?.isEnabled === true)
                    ?.map((certificate, index) => (
                      <p
                        onClick={() =>
                          onCertificateClick(
                            certificate?.id,
                            subOrg?.id,
                            certificate?.applicationCategoryId
                          )
                        }
                        className={`govgr-body-m p-1 text-[1.2rem] cursor-pointer  ${
                          selectedCertificateId === certificate?.id
                            ? "ring-blue-500 ring-offset-blue-200 ring-1 ring-transparent ring-offset-1"
                            : ""
                        }`}
                        key={index}
                      >
                        ● {certificate?.name}
                      </p>
                    ))}
                </div>
              ) : (
                <Accordions
                  isOpen={isAccordionOpen}
                  onToggle={handleAccordionToggle}
                  text={
                    certificatesStore.certificatesList?.length === 1
                      ? null
                      : subOrg?.name
                  }
                >
                  {subOrg?.certificates
                    ?.filter((certificate) => certificate?.isEnabled === true)
                    ?.map((certificate, index) => (
                      <p
                        onClick={() =>
                          onCertificateClick(
                            certificate?.id,
                            subOrg?.id,
                            certificate?.applicationCategoryId
                          )
                        }
                        className={`govgr-body-m p-1 text-[1.2rem] cursor-pointer  ${
                          selectedCertificateId === certificate?.id
                            ? "ring-blue-500 ring-offset-blue-200 ring-1 ring-transparent ring-offset-1"
                            : ""
                        }`}
                        key={index}
                      >
                        ● {certificate?.name}
                      </p>
                    ))}
                </Accordions>
              )}
            </div>
          </div>
        ))}

        {!selectedCertificateId ? (
          <button
            disabled
            className="govgr-btn bg-gray-200 text-[#FFFF] govgr-btn-cta mt-12"
          >
            <div className="mb-1">Συνέχεια</div>
            <svg
              viewBox="0 0 24 24"
              className="govgr-arrow--right govgr-svg-icon"
              focusable="false"
              aria-hidden="true"
            >
              <path
                d="M8.5,2L6.1,4.3l7.6,7.7l-7.6,7.6L8.5,22l10-10L8.5,2z"
                fill="white"
              />
            </svg>
          </button>
        ) : (
          <button
            onClick={handleNext}
            className="govgr-btn bg-[#003375] text-[#FFFF] govgr-btn-cta mt-12"
          >
            <div className="mb-1">Συνέχεια</div>
            <svg
              viewBox="0 0 24 24"
              className="govgr-arrow--right govgr-svg-icon"
              focusable="false"
              aria-hidden="true"
            >
              <path
                d="M8.5,2L6.1,4.3l7.6,7.7l-7.6,7.6L8.5,22l10-10L8.5,2z"
                fill="white"
              />
            </svg>
          </button>
        )}
      </div>
    </>
  );
};

export default observer(CertificatesSelection);
