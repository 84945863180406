import { observer } from "mobx-react";
import GovGrHeader from "../../../Components/GovGrHeader/GovGrHeader";
import { useNavigate } from "react-router";
import { useAuthStore } from "../../../Stores/AuthStore";

const UserDetailsOverview = () => {
  const navigate = useNavigate();
  const authStore = useAuthStore();

  const onContinue = () => {
    navigate("/user-confirmation");
  };

  return (
    <>
      <div className="w-full">
        <GovGrHeader />
      </div>

      <div className="ml-40 w-full">
        <h1 className="govgr-heading-l mt-6">Επισκόπηση των στοιχείων σας</h1>
        <p className="govgr-body-l">
          Επιβεβαιώστε ότι τα παρακάτω στοιχεία, με τα οποία έχετε συνδεθεί
          είναι σωστά:
        </p>
        {authStore.authUser?.isCompany ? null : (
          <div className="grid grid-cols-6 mt-8">
            <p className="govgr-body-l">Όνομα</p>
            <p className="govgr-body-l">{authStore.authUser?.name || "---"}</p>
          </div>
        )}
        <div className="grid grid-cols-6 mt-8">
          <p className="govgr-body-l">
            {authStore.authUser?.isCompany ? "Επωνυμία" : "Επώνυμο"}
          </p>
          <p className="govgr-body-l">{authStore.authUser?.surname || "---"}</p>
        </div>
        <div className="grid grid-cols-6 mt-8">
          <p className="govgr-body-l">Α.Φ.Μ</p>
          <p className="govgr-body-l">{authStore.authUser?.afm || "---"}</p>
        </div>

        <div>
          <button
            onClick={onContinue}
            className="govgr-btn bg-[#003375] text-[#FFFF] govgr-btn-cta mt-12"
          >
            <div className="mb-1">Συνέχεια</div>
            <svg
              viewBox="0 0 24 24"
              className="govgr-arrow--right govgr-svg-icon"
              focusable="false"
              aria-hidden="true"
            >
              <path
                d="M8.5,2L6.1,4.3l7.6,7.7l-7.6,7.6L8.5,22l10-10L8.5,2z"
                fill="white"
              />
            </svg>
          </button>
        </div>
      </div>
    </>
  );
};

export default observer(UserDetailsOverview);
