import { formatDateInDateMonthYear } from "../../../Common/formatDate/formatDate";
import { useAuthStore } from "../../../Stores/AuthStore";
import { observer } from "mobx-react";

const ProfileTable = () => {
  const authStore = useAuthStore();

  return (
    <div className="ml-[12%] mt-2">
      <dl class="govgr-summary-list w-[90%]">
        {authStore.authUser?.isCompany ? null : (
          <div class="govgr-summary-list__row">
            <dt class="govgr-summary-list__key">Όνομα</dt>
            <dd class="govgr-summary-list__value">
              {authStore.authUser?.name ?? "-"}
            </dd>
          </div>
        )}
        <div class="govgr-summary-list__row ">
          <dt class="govgr-summary-list__key">
            {authStore.authUser?.isCompany ? "Επωνυμία" : "Επώνυμο"}
          </dt>
          <dd class="govgr-summary-list__value">
            {authStore.authUser?.surname ?? "-"}
          </dd>
        </div>
        {authStore.authUser?.isCompany ? null : (
          <>
            {" "}
            <div class="govgr-summary-list__row ">
              <dt class="govgr-summary-list__key">Όνομα Πατέρα</dt>
              <dd class="govgr-summary-list__value">
                {" "}
                {authStore.authUser?.fatherName ?? "-"}{" "}
              </dd>
            </div>
            <div class="govgr-summary-list__row ">
              <dt class="govgr-summary-list__key">Όνομα Μητέρας</dt>
              <dd class="govgr-summary-list__value">
                {authStore.authUser?.motherName ?? "-"}
              </dd>
            </div>{" "}
          </>
        )}
        <div class="govgr-summary-list__row ">
          <dt class="govgr-summary-list__key">Α.Φ.Μ</dt>
          <dd class="govgr-summary-list__value">
            {" "}
            {authStore.authUser?.afm ?? "-"}
          </dd>
        </div>
        <div class="govgr-summary-list__row">
          <dt class="govgr-summary-list__key">Τηλέφωνο</dt>
          <dd class="govgr-summary-list__value">
            {authStore.authUser?.phoneNumber ?? "-"}
          </dd>
        </div>
        {authStore.authUser?.isCompany ? null : (
          <>
            {" "}
            <div class="govgr-summary-list__row">
              <dt class="govgr-summary-list__key">Ημερομηνία Γέννησης</dt>
              <dd class="govgr-summary-list__value">
                {formatDateInDateMonthYear(authStore.authUser?.birthYear) ??
                  "-"}
              </dd>
            </div>
            <div class="govgr-summary-list__row">
              <dt class="govgr-summary-list__key">Α.Δ.Τ</dt>
              <dd class="govgr-summary-list__value">
                {authStore.authUser?.identity ?? "-"}
              </dd>
            </div>
            <div class="govgr-summary-list__row">
              <dt class="govgr-summary-list__key">Τύπος Α.Δ.Τ</dt>
              <dd class="govgr-summary-list__value">
                {authStore.authUser?.identityType ?? "-"}
              </dd>
            </div>{" "}
          </>
        )}
      </dl>
    </div>
  );
};

export default observer(ProfileTable);
