import GovGrToolTipHeader from "../../../Components/GovGrToolTipHeader/GovGrToolTipHeader";
import { observer } from "mobx-react";
import GovSelect from "../../../Components/GovSelect/GovSelect";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import { useDepartmentStore } from "../../../Stores/DepartmentStore";
import { useScheduleStore } from "../../../Stores/ScheduleStore";
import { useLocation } from "react-router-dom";
import IconText from "../../../Components/IconText/IconText";

const SeatSelection = () => {
  const { state } = useLocation();
  const scheduleStore = useScheduleStore();
  const departmentStore = useDepartmentStore();
  const navigate = useNavigate();
  const [selectedValue, setSelectedValue] = useState(
    departmentStore?.selectedSeat || ""
  );

  const handleSelectChange = (event) => {
    setSelectedValue(event.target.value);
    departmentStore.setSelectedSeat(event.target.value);
  };

  const mappedOptions = departmentStore.seatsOptions?.map((option) => ({
    value: option.appointmentServiceId,
    label: option.name,
  }));

  const onSeatSelectionClick = () => {
    const selectedSeatName = departmentStore.seatsOptions?.find(
      (seat) => seat.appointmentServiceId?.toString() === selectedValue
    )?.name;
    navigate("/schedule", {
      state: {
        appointmentServiceId: parseInt(selectedValue),
        selectedSeatName,
        selectedSubOrganization: state?.selectedSubOrganization,
        applicationCategoryId: state?.selectedCertificateId,
      },
    });
  };

  useEffect(() => {
    scheduleStore.resetScheduleData();
  }, [scheduleStore]);

  useEffect(() => {
    departmentStore.getDepartmentsBySubOrgAndAppCategory({
      subOrganizationId: state?.selectedSubOrganization,
      applicationCategoryId: state?.selectedCertificateId,
    });
  }, [state, departmentStore]);

  useEffect(() => {
    if (departmentStore.seatsOptions?.length === 1) {
      setSelectedValue(departmentStore.seatsOptions[0]?.appointmentServiceId);
      departmentStore.setSelectedSeat(
        departmentStore.seatsOptions[0]?.appointmentServiceId
      );
    }
  }, [departmentStore.seatsOptions]);

  return (
    <>
      <div>
        <GovGrToolTipHeader />
      </div>
      <div className="ml-40">
        <IconText
          onClick={() => navigate(-1)}
          text={"Ηλεκτρονικό αίτημα ραντεβού"}
        />
        <p className="govgr-body-l">
          Επιλέξτε το <b>Τμήμα / Περιοχή</b> για την εξυπηρέτηση του ραντεβού
          σας:
        </p>
      </div>
      <div className="ml-40">
        <div className="mt-12">
          <GovSelect
            name="select-list"
            label="Επιλέξτε..."
            value={selectedValue}
            options={mappedOptions}
            onChange={handleSelectChange}
          />
        </div>

        {!selectedValue ? (
          <button
            disabled
            className="govgr-btn bg-gray-200 text-[#FFFF] govgr-btn-cta mt-12"
          >
            <div className="mb-1">Συνέχεια</div>
            <svg
              viewBox="0 0 24 24"
              className="govgr-arrow--right govgr-svg-icon"
              focusable="false"
              aria-hidden="true"
            >
              <path
                d="M8.5,2L6.1,4.3l7.6,7.7l-7.6,7.6L8.5,22l10-10L8.5,2z"
                fill="white"
              />
            </svg>
          </button>
        ) : (
          <button
            onClick={onSeatSelectionClick}
            className="govgr-btn bg-[#003375] text-[#FFFF] govgr-btn-cta mt-12"
          >
            <div className="mb-1">Συνέχεια</div>
            <svg
              viewBox="0 0 24 24"
              className="govgr-arrow--right govgr-svg-icon"
              focusable="false"
              aria-hidden="true"
            >
              <path
                d="M8.5,2L6.1,4.3l7.6,7.7l-7.6,7.6L8.5,22l10-10L8.5,2z"
                fill="white"
              />
            </svg>
          </button>
        )}
      </div>
    </>
  );
};

export default observer(SeatSelection);
