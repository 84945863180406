import { useCallback, useEffect, useMemo, useState } from "react";
import MapGoogle from "../../../Components/GoogleMap/GoogleMap";
import ImageGrid from "../../../Components/ImageGrid/ImageGrid";
import { formatDateInDateMonthYear } from "../../../Common/formatDate/formatDate";
import { getTicketStatusLiteral } from "../../../Config/TicketHelpers/TicketHelpers";
import GovGrToolTipHeader from "../../../Components/GovGrToolTipHeader/GovGrToolTipHeader";
import ArrowCircleLeftOutlinedIcon from "@mui/icons-material/ArrowCircleLeftOutlined";
import { useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";
import AuthSetAgent from "../../../Api/AuthSetAgent";
import { Box, CircularProgress } from "@mui/material";

const UnorderedListItem = ({ children }) => (
  <li className="govgr-body-l my-4">{children}</li>
);

const TicketView = () => {
  const navigate = useNavigate();
  const params = useParams();
  const ticketId = params?.id;
  const [ticket, setTicket] = useState({});
  const [loading, setLoading] = useState(false);
  const displayProperty = useCallback((property) => {
    if (!property) {
      return " ";
    }

    return property;
  }, []);

  useEffect(() => {
    setLoading(true);
    AuthSetAgent.tickets
      .getTicketById(ticketId)
      .then((resp) => {
        setTicket(resp?.result);
      })
      .catch((error) => {})
      .finally(() => {
        setLoading(false);
      });
  }, [ticketId]);

  const markerPosition = useMemo(
    () => ({ lat: ticket?.lat, lng: ticket?.lon }),
    [ticket]
  );

  if (!ticket) {
    return null;
  }

  const loader = (
    <Box className="flex justify-center items-center p-20">
      <CircularProgress size={50} />
    </Box>
  );

  return (
    <div>
      <GovGrToolTipHeader />
      {loading ? (
        loader
      ) : (
        <>
          <div className="w-full lg:w-2/3 md:ml-40 sm:ml-20 ml-10">
            <div className="flex items-center">
              <ArrowCircleLeftOutlinedIcon
                onClick={() => {
                  navigate(-1);
                }}
                style={{
                  fill: "#004a75",
                  marginRight: "14px",
                  marginBottom: "14px",
                  fontSize: "50px",
                  cursor: "pointer",
                }}
                fontSize="large"
              />
              <h1 className="govgr-heading-l mt-6">
                {" "}
                Στοιχεία του αιτήματός σας
              </h1>
            </div>

            <ul className="list-disc ml-10">
              <UnorderedListItem>
                ΚΩΔΙΚΟΣ ΑΙΤΗΜΑΤΟΣ: {displayProperty(ticket?.id)}
              </UnorderedListItem>
              <UnorderedListItem>
                ΗΜ/ΝΙΑ ΚΑΤΑΧΩΡΗΣΗΣ:{" "}
                {displayProperty(
                  ticket?.entityCreatedAt
                    ? formatDateInDateMonthYear(ticket.entityCreatedAt)
                    : null
                )}
              </UnorderedListItem>
              <UnorderedListItem>
                ΚΑΤΗΓΟΡΙΑ: {displayProperty(ticket?.ticketCategoryTitle)}
              </UnorderedListItem>
              <UnorderedListItem>
                ΥΠΗΡΕΣΙΑ / ΠΡΟΒΛΗΜΑ:{" "}
                {displayProperty(ticket?.ticketServiceTitle)}
              </UnorderedListItem>
              <UnorderedListItem>
                ΣΧΟΛΙΟ: {displayProperty(ticket?.ticketNoteFromCitizen)}
              </UnorderedListItem>
              <UnorderedListItem>
                ΚΑΤΑΣΤΑΣΗ:{" "}
                {displayProperty(getTicketStatusLiteral(ticket?.status))}
              </UnorderedListItem>
              <UnorderedListItem>
                ΗΜ/ΝΙΑ ΟΛΟΚΛΗΡΩΣΗΣ:{" "}
                {displayProperty(
                  ticket?.dateOfCompletion
                    ? formatDateInDateMonthYear(ticket.dateOfCompletion)
                    : null
                )}
              </UnorderedListItem>
            </ul>

            <section className="pt-5 pb-5 ml-4">
              <h1 className="govgr-body-l font-bold mb-5">
                Απάντηση υπηρεσίας
              </h1>
              <p className="govgr-body-l ml-4">
                {displayProperty(ticket?.ticketNoteToCitizen)}
              </p>
            </section>

            <section className="pt-5 pb-5 ml-4">
              <h1 className="govgr-body-l font-bold mb-5">Θέση στο χάρτη</h1>
              <p className="govgr-body-l ml-2 pb-2">
                ΔΙΕΥΘΥΝΣΗ: {displayProperty(ticket?.formattedAddress)}
              </p>
              <MapGoogle
                markerPosition={markerPosition}
                customCenter={markerPosition}
                initialZoom={16}
              />
            </section>

            <section className="pt-5 pb-5 ml-4">
              <h1 className="govgr-body-l font-bold mb-5">Φωτογραφίες</h1>
              <ImageGrid images={ticket.images} isBase64 />
            </section>
          </div>
        </>
      )}
    </div>
  );
};

export default TicketView;
