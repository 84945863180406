import { observer } from "mobx-react";
import { BrowserRouter } from "react-router-dom";
import GuestRoutes from "../Routes/GuestRoutes/GuestRoutes";
import AuthenticatedRoutes from "../Routes/AuthenticatedRoutes/AuthenticatedRoutes";
import { useAuthStore } from "../Stores/AuthStore";
import AuthenticatedContent from "./AuthenticatedContent";
import GuestContent from "./GuestContent";

const AppContent = () => {
  const authStore = useAuthStore();

  return (
    <>
      <BrowserRouter>
        {authStore.userAuthenticated ? (
          <AuthenticatedContent>
            <AuthenticatedRoutes />
          </AuthenticatedContent>
        ) : (
          <GuestContent>
            <GuestRoutes />
          </GuestContent>
        )}
      </BrowserRouter>
    </>
  );
};

export default observer(AppContent);
