import { observer } from "mobx-react";
import { useEffect, useState } from "react";
import { useCertificatesStore } from "../../../Stores/CertificatesStore";
import { useNavigate } from "react-router";
import { formatDateInDateMonthYear } from "../../../Common/formatDate/formatDate";

const CertificateSubmissionActionsTable = () => {
  const certificatesStore = useCertificatesStore();
  const navigate = useNavigate();
  const [
    certificatesSubmissionFilteredList,
    setCertificatesSubmissionFilteredList,
  ] = useState([]);

  useEffect(() => {
    certificatesStore.getCertificateSubmission(1, 3, [5]).then((res) => {
      setCertificatesSubmissionFilteredList(
        res.result.items.sort(
          (a, b) => new Date(b.createdAt) - new Date(a.createdAt)
        )
      );
    });
  }, [certificatesStore]);

  return (
    <div className="w-1/2 pb-full rounded-xl bg-lightgray">
      <div className="bg-[#003375] min-w-[90%] w-[90%] px-4 py-2 flex justify-center items-center text-white text-lg">
        <p>Απαιτούμενες ενέργειες - Εκκρεμότητες</p>
        <img
          src="info-icon-red.png"
          className="ml-4 h-[28px]"
          alt="info icon"
        />
      </div>
      <div className="bg-gray-300 px-4 pt-4 min-w-[90%] w-[90%] justify-center items-center text-lg">
        <div className="ml-4">
          {certificatesSubmissionFilteredList?.length > 0 ? (
            <div>
              {certificatesSubmissionFilteredList?.map((item) => (
                <div key={item?.id}>
                  <p className="text-lg">
                    Κωδ. {item.id} | {item?.certificate?.name} | Ημερ.{" "}
                    {formatDateInDateMonthYear(item?.createdAt.slice(0, 10))}
                  </p>
                  <p className="text-lg mb-2 font-bold">
                    Σχόλια: {item?.adminComment ?? "-"}{" "}
                  </p>
                </div>
              ))}
              <div
                onClick={() => navigate("/certificates-submissions-action")}
                className="flex justify-end items-end pb-4 pr-6 text-blue-400 cursor-pointer hover:underline"
              >
                περισσότερα ...
              </div>
            </div>
          ) : (
            <p className="text-lg text-center pb-4">
              Δεν υπάρχουν εκκρεμότητες
            </p>
          )}
        </div>
      </div>
    </div>
  );
};

export default observer(CertificateSubmissionActionsTable);
