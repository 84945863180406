import { observer } from "mobx-react";
import { organizationId } from "../../Config/constant";
import { useEffect, useState } from "react";
import { useServicesStore } from "../../Stores/ServicesStore";
import { Box, CircularProgress } from "@mui/material";

const GovGrHeader = () => {
  const [loading, setLoading] = useState(false);
  const servicesStore = useServicesStore();
  const organizationName =
    parseInt(organizationId) === servicesStore.organizationById?.id
      ? servicesStore.organizationById?.name
      : "---";

  const loader = (
    <Box className="flex justify-center items-center p-20">
      <CircularProgress size={50} />
    </Box>
  );

  useEffect(() => {
    if (organizationId) {
      setLoading(true);
      servicesStore
        .getOrganizationById(organizationId)
        .then((resp) => {})
        .catch((error) => {})
        .finally(() => {
          setLoading(false);
        });
    }
  }, [servicesStore]);

  return (
    <header className="govgr-header">
      {loading ? (
        loader
      ) : (
        <div className="govgr-header__container md:ml-44 sm:ml-24 ml-14">
          <div className="govgr-header__content flex items-center">
            {/* <span>
              <img
                className="govgr-header-logo"
                src="https://www.gov.gr/gov_gr_logo.svg"
                alt="gov.gr logo"
              />
            </span>
            <div className="govgr-header-divider w-1 h-12 bg-white mx-4" /> */}
            <div className="govgr-header-title mb-2">{organizationName}</div>
          </div>
        </div>
      )}
    </header>
  );
};

export default observer(GovGrHeader);
