export const TICKET_STATUS_INTERNAL = {
  UNDER_REVIEW: 0,
  REJECTED: 1,
  NEW: 2,
  IN_PROGRESS: 3,
  COMPLETED: 4,
};

export const TICKET_STATUS_CITIZEN = {
  SUBMIT: {
    id: 0,
    label: "Υποβολή",
  },
  REJECTED: {
    id: 1,
    label: "Απορρίφθηκε",
  },
  IN_PROGRESS: { id: 3, label: "Σε εξέλιξη" },
  COMPLETED: { id: 4, label: "Ολοκληρώθηκε" },
};

export const getTicketStatusLiteral = (status) => {
  if (
    status === TICKET_STATUS_INTERNAL.UNDER_REVIEW ||
    status === TICKET_STATUS_INTERNAL.NEW
  ) {
    return TICKET_STATUS_CITIZEN.SUBMIT.label;
  }

  if (status === TICKET_STATUS_INTERNAL.REJECTED) {
    return TICKET_STATUS_CITIZEN.REJECTED.label;
  }

  if (status === TICKET_STATUS_INTERNAL.COMPLETED) {
    return TICKET_STATUS_CITIZEN.COMPLETED.label;
  }

  if (status === TICKET_STATUS_INTERNAL.IN_PROGRESS) {
    return TICKET_STATUS_CITIZEN.IN_PROGRESS.label;
  }
};
