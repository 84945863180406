import { requests } from "../Common/HttpClient/HttpClient";
import { taxisBaseUrl, authUrl } from "../Config/constant";

const {
  post,
  get,
  patch,
  put,
  delete: deleteRequest,
  getFile,
  getGeneral,
} = requests;

/**************************************** authentication Endpoints ****************************************/
const user = {
  login: (email, password) => post("/Auth/login", { email, password }),
  getProfile: () => get("/Auth/profile"),
  updateProfile: (phoneNumber) =>
    patch("/Auth/profile", { phoneNumber: `${phoneNumber}` }),
  changePassword: (currentPassword, newPassword, confirmNewPassword) =>
    patch("/Auth/updatePassword", {
      currentPassword,
      newPassword,
      confirmNewPassword,
    }),
  forgotPassword: (email) =>
    post("/Auth/forgotPassword", { email: `${email}` }),
  resetPassword: (data, token) =>
    post("/Auth/resetPassword", { ...data, token }),
  refreshToken: (body) => post("/Auth/refreshToken", body),
};

const applicationCategory = {
  getGroupedApplicationCategories: (id) => {
    const url = `/ApplicationCategory/GetGroupedApplicationCategories?organizationId=${id}`;
    return get(url);
  },
  getAllApplicationCategories: (pageNumber = null, pageSize = null) => {
    const url =
      `/ApplicationCategory` +
      (pageNumber && pageSize
        ? `?PageNumber=${pageNumber}&PageSize=${pageSize}`
        : "");
    return get(url);
  },
};

const rantevou = {
  createNewRantevouAppointment: (body) =>
    post("/Rantevou/CreateNewRantevouAppointment", body),
  getRantevouAppointments: (pageNumber = null, pageSize = null, statuses) => {
    const url =
      `/Rantevou/GetRantevouAppointments` +
      (pageNumber && pageSize
        ? `?PageNumber=${pageNumber}&PageSize=${pageSize}`
        : "") +
      (statuses?.length > 0
        ? statuses.map((s) => `&statuses=${s}`).join("")
        : "");
    return get(url);
  },
  updateRantevouAppointment: (body, id) =>
    put(`/Rantevou/UpdateRantevouAppointment?id=${id}`, body),
};

const department = {
  getDepartmentsBySubOrgAndAppCategory: ({
    subOrganizationId,
    applicationCategoryId,
    pageNumber = null,
    pageSize = null,
  }) => {
    const url =
      `/Department/GetDepartmentsBySubOrgAndAppCategory?subOrganizationId=${subOrganizationId}&applicationCategoryId=${applicationCategoryId}` +
      (pageNumber && pageSize
        ? `&PageNumber=${pageNumber}&PageSize=${pageSize}`
        : "");
    return get(url);
  },
  getDepartmentsBySubOrganizationAndCertificateId: ({
    subOrganizationId,
    certificateId,
    pageNumber = null,
    pageSize = null,
  }) => {
    const url =
      `/Department/GetDepartmentsBySubOrganizationAndCertificateId?subOrganizationId=${subOrganizationId}&certificateId=${certificateId}` +
      (pageNumber && pageSize
        ? `&PageNumber=${pageNumber}&PageSize=${pageSize}`
        : "");
    return get(url);
  },
};

const certificates = {
  getCertificatesUnderSubOrganization: (id) => {
    const url = `/Certificate/GetGroupedCertificates?organizationId=${id}`;
    return get(url);
  },
  getCertificateById: (certificateId) => get(`/Certificate/${certificateId}`),
};

const certificateSubmission = {
  getCertificateSubmission: (
    pageNumber = null,
    pageSize = null,
    statuses = []
  ) => {
    const url =
      `/CertificateSubmission` +
      (pageNumber && pageSize
        ? `?PageNumber=${pageNumber}&PageSize=${pageSize}`
        : "") +
      (statuses.length > 0
        ? statuses.map((s) => `&statuses=${s}`).join("")
        : "");
    return get(url);
  },
  getCertificateSubmissionById: (certificateSubmissionId) =>
    get(`/CertificateSubmission/${certificateSubmissionId}`),
  getTotalSubmissions: () => get("/CertificateSubmission/total-submissions"),
  getFormFieldsByCertificateSubmissionId: (certificateSubmissionId) =>
    get(`/CertificateSubmission/${certificateSubmissionId}/form-fields`),
  getFormFieldsValues: (certificateSubmissionId) =>
    get(
      `/CertificateSubmission/${certificateSubmissionId}/form-submission-values`
    ),
  getEmptyFormFields: (certificateSubmissionId) =>
    get(`/CertificateSubmission/${certificateSubmissionId}/empty-form`),
  startCertificateSubmission: ({ certificateId, departmentId }) =>
    post(
      `/CertificateSubmission/${certificateId}/start?departmentId=${departmentId}`
    ),
  completeCertificateSubmission: (certificateSubmissionId, body) =>
    post(`/CertificateSubmission/${certificateSubmissionId}/complete`, body),
  uploadFile: (businessKey, fieldName, certificateSubmissionId, formData) =>
    post(
      `/CertificateSubmission/upload-file?certificateSubmissionId=${certificateSubmissionId}&businessKey=${businessKey}&fieldName=${fieldName}`,
      formData
    ),
  dowloadFile: (fileName) =>
    getFile(`/CertificateSubmission/download-file?fileName=${fileName}`),
  removeFile: (fileName) =>
    get(`/CertificateSubmission/remove-file?fileName=${fileName}`),
  getCertificateSubmissionAttachments: (id) =>
    get(`/CertificateSubmission/${id}/attachments`),
};

const schedule = {
  getScheduleByAppointmentServiceId: ({ appointmentServiceId }) =>
    get(
      `/Schedule/GetScheduleByAppointmentServiceId?appointmentServiceId=${appointmentServiceId}`
    ),
};

const tickets = {
  getMyTickets: (pageNumber = null, pageSize = null, statuses = null) => {
    const url =
      `/Ticket/GetMyTickets` +
      (pageNumber && pageSize
        ? `?PageNumber=${pageNumber}&PageSize=${pageSize}`
        : "") +
      (statuses?.length > 0
        ? statuses.map((s) => `&statuses=${s}`).join("")
        : "");

    return get(url);
  },
  getTicketById: (id) => get(`/Ticket?id=${id}`),
  getTicketCategoriesAndMap: (id) =>
    get(`/Ticket/GetTicketCategoriesWithServicesAndMap?organizationId=${id}`),
  createTicket: ({
    ticketCategoryId,
    ticketServiceId,
    formattedAddress,
    lat,
    lon,
    notes,
    foo,
  }) => {
    const formData = new FormData();

    formData.append("TicketCategoryId", ticketCategoryId);
    formData.append("OrgId", foo);
    formData.append("TicketServiceId", ticketServiceId);
    formData.append("FormattedAddress", formattedAddress);
    formData.append("Lat", lat);
    formData.append("Lon", lon);
    formData.append("Alt", 0); // hard-coded value, Alt not in use
    formData.append("Notes", notes);

    return post("/Ticket/CreateTicket", formData, {
      headers: { "Content-Type": "multipart/form-data" },
    });
  },
  uploadTicketFile: ({ ticketId, files }) => {
    const formData = new FormData();

    for (const file of files) {
      formData.append("files", file);
    }

    return post(`/Ticket/upload-files?ticketId=${ticketId}`, formData, {
      headers: { "Content-Type": "multipart/form-data" },
    });
  },
  dowloadTicketImage: (fileName) =>
    getFile(`/Ticket/download-file?fileName=${fileName}`),
};

const taxis = {
  gsisLogin: () => get("/Auth/gsis-login"),
  register: (body) => post("/Auth/register", body),
  callback: (code) => get(`/Auth/gsis-callback?code=${code}`),
  updateContactDetails: (body) => put("/Auth/contact-details", body),
  sendVerificationEmail: (afm, email) =>
    post(`/Auth/send-verification-email?afm=${afm}`, email),
  sendVerificationSms: (afm, phoneNumber) =>
    post(`/Auth/send-verification-sms?afm=${afm}`, phoneNumber),
  confirmPhoneNumber: (afm, code) =>
    post(`/Auth/ConfirmPhoneNumber?afm=${afm}&code=${code}`),
  confirmEmail: (afm, code) =>
    getGeneral(`/Auth/ConfirmEmail?afm=${afm}&code=${code}`),
};

const organization = {
  getOrganizationById: (id) => get(`/Organization/${id}`),
};

const AuthSetAgent = {
  user,
  applicationCategory,
  department,
  schedule,
  rantevou,
  certificates,
  certificateSubmission,
  tickets,
  taxis,
  organization,
};

export default AuthSetAgent;
