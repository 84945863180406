import { observer } from "mobx-react";
import ContactInfoConfirmation from "../ContantInfoConfirmation/ContactInfoConfirmation";

const ContactInfoEdit = () => {
  return (
    <>
      <div>
        <ContactInfoConfirmation isEdit={true} />
      </div>
    </>
  );
};

export default observer(ContactInfoEdit);
