import { makeObservable, observable, action } from "mobx";
import { createContext, useContext } from "react";
import AuthSetAgent from "../Api/AuthSetAgent";

class RantevouStore {
  appointments = [];

  applicationCategories = [];

  allApplicationCategoriesList = [];

  subOrganizationId = null;

  applicationCategoryId = null;

  appointmentServiceId = null;

  rantevouId = null;

  constructor() {
    makeObservable(this, {
      appointments: observable,
      applicationCategories: observable,
      subOrganizationId: observable,
      applicationCategoryId: observable,
      allApplicationCategoriesList: observable,
      rantevouId: observable,
      appointmentServiceId: observable,
      getRantevouAppointments: action,
      getGroupedApplicationCategories: action,
      getAllApplicationCategories: action,
      setSubOrganizationId: action,
      setApplicationCategoryId: action,
      setRantevouId: action,
      createNewRantevouAppointment: action,
      updateRantevouAppointment: action,
      setAppointmentServiceId: action,
    });
  }

  setSubOrganizationId(subOrganizationId) {
    this.subOrganizationId = subOrganizationId;
  }

  setRantevouId(rantevouId) {
    this.rantevouId = rantevouId;
  }

  setAppointmentServiceId(appointmentServiceId) {
    this.appointmentServiceId = appointmentServiceId;
  }

  setApplicationCategoryId(applicationCategoryId) {
    this.applicationCategoryId = applicationCategoryId;
  }

  async createNewRantevouAppointment(body) {
    return await AuthSetAgent.rantevou.createNewRantevouAppointment(body);
  }

  async updateRantevouAppointment(payload, rantevouId) {
    try {
      const response = await AuthSetAgent.rantevou.updateRantevouAppointment(
        payload,
        rantevouId
      );

      const updatedAppointment = response.result;

      const index = this.appointments.findIndex(
        (appointment) => appointment.id === rantevouId
      );

      if (index !== -1) {
        this.appointments[index] = updatedAppointment;
      }

      return response;
    } catch (error) {}
  }

  getRantevouAppointments(pageNumber = null, pageSize = null, status = null) {
    return AuthSetAgent.rantevou
      .getRantevouAppointments(pageNumber, pageSize, status)
      .then((appointments) => {
        this.appointments = appointments.result.items;
        return appointments;
      })
      .catch((error) => {
        console.error(error);
      });
  }

  getGroupedApplicationCategories(id) {
    AuthSetAgent.applicationCategory
      .getGroupedApplicationCategories(id)
      .then((applicationCategories) => {
        this.applicationCategories = applicationCategories.result;
      })
      .catch((e) => {});
  }

  getAllApplicationCategories(pageNumber = null, pageSize = null) {
    AuthSetAgent.applicationCategory
      .getAllApplicationCategories(pageNumber, pageSize)
      .then((applicationCategories) => {
        this.allApplicationCategoriesList = applicationCategories.result.items;
      });
  }
}

export const rantevouStore = new RantevouStore();
export const RantevouStoreContext = createContext(rantevouStore);
export const useRantevouStore = () => useContext(RantevouStoreContext);
