import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useCertificatesStore } from "../../Stores/CertificatesStore";
import IconText from "../../Components/IconText/IconText";
import GovGrToolTipHeader from "../../Components/GovGrToolTipHeader/GovGrToolTipHeader";
import { Box, Icon, LinearProgress, Tooltip, Typography } from "@mui/material";
import Accordions from "../../Components/Accordion/Accordion";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import ErrorOutlineOutlinedIcon from "@mui/icons-material/ErrorOutlineOutlined";
import { toJS } from "mobx";
import { observer } from "mobx-react";
import parse from "html-react-parser";
import FileUploader from "../../Components/FileUploader/FileUploader";
import pistopoiitika from "../../assets/pistopoiitika.png";
import FileViewer from "../../Components/FileViewer/FileViewer";
import { formatDateInDateMonthYear } from "../../Common/formatDate/formatDate";
import AuthSetAgent from "../../Api/AuthSetAgent";

const bannerColor = {
  0: "#00b0f0", // Αποθηκευμένη
  1: "#0054ba", // Υποβληθείσα
  2: "#6a6a6a", // Ακυρωμένη
  3: "#0054ba", // Σε εξέλιξη
  4: "#006736", // Ολοκληρωμένη
  5: "#b60202", // Σε εκκρεμότητα
  6: "#b60202", // Απορριφθείσα
};

const CertificatesDetails = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const certificatesStore = useCertificatesStore();
  const [certificate, setCertificate] = useState(null);
  const [loading, setLoading] = useState(true);
  const [backToLink, setBackToLink] = useState(null);
  const [isSubmittingForm, setIsSubmittingForm] = useState(false);
  const [isChecked, setIsChecked] = useState(false);
  const [
    certificateSubmissionAttachments,
    setCertificateSubmissionAttachments,
  ] = useState([]);

  const handleCheckboxChange = (event) => {
    setIsChecked(event.target.checked);
  };

  const onCompleteSubmission = async () => {
    setLoading(true);

    const payload = {
      variables: {
        action: { value: "complete" },
      },
    };

    await certificatesStore
      .completeCertificateSubmission(certificate.id, payload)
      .then((response) => {
        sessionStorage.clear();
        navigate("/certificate-results", {
          state: {
            certificateId: certificate.id,
            certificate: certificate,
            selectedApplicationCategoryId:
              location.state.selectedApplicationCategoryId,
          },
        });
        setLoading(false);
      })
      .catch((error) => {
        console.log("error", error);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const onEdit = () => {
    setLoading(true);

    certificatesStore
      .getFormFieldsByCertificateSubmissionId(certificate.id)
      .then(() => {
        if (certificatesStore.formFieldsList?.name === "Προβολή αίτησης") {
          const payload = {
            variables: {
              action: { value: "back" },
            },
            withVariablesInReturn: false,
          };

          certificatesStore
            .completeCertificateSubmission(certificate.id, payload)
            .then((response) => {
              navigate("/certificates-create", {
                state: {
                  certificateSubmissionId: certificate.id,
                  formSubmittedValues: toJS(certificatesStore.formFieldsValues),
                  certificate: certificate,
                  backResponse: response?.result?.formValues,
                },
              });
            })
            .catch((error) => {
              console.log("error", error);
            })
            .finally(() => {
              setLoading(false);
            });
        } else {
          navigate("/certificates-create", {
            state: {
              certificateSubmissionId: certificate.id,
              formSubmittedValues: toJS(certificatesStore.formFieldsValues),
              certificate: certificate,
              backResponse: Object.keys(
                certificatesStore.formFieldsValues
              )?.reduce((acc, item) => {
                acc[item] = certificatesStore.formFieldsValues[item]?.value;
                return acc;
              }, {}),
            },
          });
        }
      })
      .catch((error) => {
        console.log("error", error);
      });
  };

  const onEditAfterPreview = () => {
    const payload = {
      variables: {
        action: { value: "back" },
      },
      withVariablesInReturn: false,
    };

    setLoading(true);
    certificatesStore
      .completeCertificateSubmission(certificate.id, payload)
      .then((response) => {
        navigate("/certificates-create", {
          state: {
            certificateSubmissionId: certificate.id,
            formSubmittedValues: toJS(certificatesStore.formFieldsValues),
            certificate: certificate,
            backResponse: response?.result?.formValues,
          },
        });
      })
      .catch((error) => {
        console.log("error", error);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    if (location.state?.certificate) {
      setCertificate(location.state.certificate);
    }
    if (location.state?.backToLink) {
      setBackToLink(location.state.backToLink);
    }
    if (location.state?.isSubmittingForm) {
      setIsSubmittingForm(location.state.isSubmittingForm);
    }
  }, [location]);

  useEffect(() => {
    if (certificate?.id) {
      certificatesStore
        .getEmptyFormFields(location.state.certificate.id)
        .then(() => {
          certificatesStore
            .getFormFieldsValues(location.state.certificate.id)
            .then(() => {})
            .catch((error) => {
              console.log("error", error);
            })
            .finally(() => {
              setLoading(false);
            });
        })
        .catch((error) => {
          console.log("error", error);
        });
    }
  }, [certificate]);

  useEffect(() => {
    if (location.state.certificate.id) {
      setLoading(true);
      AuthSetAgent.certificateSubmission
        .getCertificateSubmissionAttachments(location.state.certificate.id)
        .then((resp) => {
          setCertificateSubmissionAttachments(resp?.result);
        })
        .catch(() => {})
        .finally(() => {
          setLoading(false);
        });
    }
  }, [location.state.certificate.id]);

  return (
    <>
      <div>
        <GovGrToolTipHeader />
        {loading && <LinearProgress />}
      </div>
      <div className="ml-5 lg:ml-40 lg:mr-40 flex flex-col">
        {!loading && (
          <>
            <IconText
              navigation={isSubmittingForm ? null : backToLink}
              text={`Οι αιτήσεις μου | ${certificate.certificate?.name ?? "-"}`}
            />
            <div className="mt-4 max-w-full pl-16">
              <div>
                {!isSubmittingForm && (
                  <div
                    className="h-28 mx-4 flex flex-col md:flex-row justify-between items-center"
                    style={{
                      backgroundColor: bannerColor[certificate?.status],
                    }}
                  >
                    <div className="flex flex-col py-4 pl-4 h-24 justify-evenly">
                      <span className="text-white font-bold text-[1.1rem]">
                        Αρ. Αίτησης: {certificate.id ?? "-"}
                      </span>
                      <span className="text-white font-bold text-[1.1rem] mt-2">
                        Ημερομηνία{" "}
                        {certificate?.status === 0 ? "αποθήκευσης" : "υποβολής"}
                        :{" "}
                        {formatDateInDateMonthYear(
                          certificate.updatedAt.slice(0, 10)
                        )}
                      </span>
                    </div>
                    <div className="flex flex-col py-4 pr-4">
                      <span className="text-white font-bold text-[1.1rem]">
                        Κατάσταση:{" "}
                        {
                          certificatesStore?.statusTranslations[
                            certificate?.status
                          ]
                        }
                      </span>
                    </div>
                  </div>
                )}
              </div>
              {certificate?.status === 4 && (
                <div className="mt-4 mx-4 px-4 pt-4 flex flex-col justify-center items-center border-2 border-[#006736]">
                  <div className="text-center text-lg">
                    Η διαδικασία ψηφιακής έκδοσης του πιστοποιητικού/εγγράφου
                    που αιτηθήκατε έχει ολοκληρωθεί επιτυχώς.
                  </div>
                  <div className="flex justify-center">
                    <img
                      width="100px"
                      src={pistopoiitika}
                      alt="pistopoiitika"
                    />
                  </div>
                  <div className="text-center text-lg">
                    Πατήστε λήψη για να κατεβάσετε το πιστοποιητικό / έγγραφο.
                  </div>
                  <div className="flex justify-center mb-6">
                    <FileViewer
                      businessKey={certificate.businessKey}
                      certificateSubmissionId={certificate.id}
                      fieldName={"st3_file_signedCertificate"}
                      buttonOnly
                      buttonTitle={"Λήψη πιστοποιητικού"}
                      buttonColor={"#006736"}
                      certificateSubmissionAttachments={
                        certificateSubmissionAttachments
                      }
                    />
                  </div>
                </div>
              )}
              {certificate?.status === 5 && (
                <>
                  <div className="flex justify-start items-center">
                    <Icon
                      className="mt-6 ml-4"
                      component={ErrorOutlineOutlinedIcon}
                      sx={{
                        color: "#b60202",
                        fontSize: 40,
                      }}
                    />
                    <Typography
                      variant="h3"
                      fontWeight={"bold"}
                      className="pt-8 pb-2 px-4 flex flex-col justify-center items-start"
                    >
                      Απαιτούμενες ενέργειες - Εκκρεμότητες
                    </Typography>
                  </div>
                  <div
                    className={`mt-4 mx-4 px-4 py-4 flex flex-col justify-center items-center border-2 border-[${
                      bannerColor[certificate?.status]
                    }]`}
                  >
                    <div className="text-center text-lg">
                      Μετά από έλεγχο της αίτησή σας, ο αρμόδιος υπάλληλος έχει
                      υποβάλλει το παρακάτω σχόλιο:
                    </div>
                    <div className="text-center text-lg my-4">
                      <b>{certificate?.adminComment}</b>
                    </div>

                    {/* <div className="flex flex-col justify-center items-center">
                      <FileViewer
                        businessKey={certificate.businessKey}
                        fieldName={"admin_file"}
                        title={
                          "Δείτε το συννημένο αρχείο κάνοντας κλικ στο κουμπί 'λήψη':"
                        }
                         certificateSubmissionAttachments={
                        certificateSubmissionAttachments
                      }
                      />
                    </div> */}
                  </div>
                </>
              )}

              <Accordions
                className="mt-4 w-full"
                text={`Οδηγίες αίτησης`}
                defaultExpanded={false}
                icon={<InfoOutlinedIcon className="mr-2" fontSize="large" />}
              >
                <div className="py-4 px-2">
                  <div className="govgr-body-l text-[20px]">
                    Η αίτηση επέχει θέση υπεύθυνης δήλωσης του άρθρου 8
                    Ν.1599/1986. Η ακρίβεια των στοιχείων που υποβάλλονται με
                    αυτή τη δήλωση μπορεί να ελεγχθεί με βάση το αρχείο άλλων
                    υπηρεσιών.
                  </div>

                  <div className="govgr-body-l text-[20px] mt-2">
                    Με ατομική μου ευθύνη και γνωρίζοντας τις κυρώσεις (1), που
                    προβλέπεται από τις διατάξεις της παρ.6 του άρθρου 22 του
                    Ν.1599/1986, δηλώνη ότι:
                  </div>

                  <div className="govgr-body-l text-[20px] mt-2">
                    (1) Όποιος εν γνώσει του δηλώνει ψευδή γεγονότα ή αρνείτε ή
                    αποκρύπτει τα αληθινά με έγγραφη υπεύθυνη δήλωση του άρθρου
                    8 τιμωρείται με φυλάκιση τουλάχιστον τριών μηνών. Εάν ο
                    υπαίτιος αυτών των πράξεων σκόπευε να προσπορίσει στον
                    εαυτόν του ή σε άλλον περιουσιακό όφελος τρίτον ή σκόπευε να
                    βλάψει άλλον, τιμωρείται με κάθειρξη μέχρι 10 ετών.
                  </div>
                </div>
              </Accordions>

              {certificatesStore.formFieldsList?.length > 0 &&
                certificatesStore.formFieldsList
                  ?.filter((formItem) => {
                    return formItem?.components?.some((formField) => {
                      const key = formField?.key;
                      const submittedValue =
                        certificatesStore.formFieldsValues[key]?.value || "";
                      return submittedValue.trim() !== "";
                    });
                  })
                  ?.map((formItem, formIndex) => (
                    <Accordions
                      key={formIndex}
                      className="mt-4"
                      text={formItem?.name}
                    >
                      <div className="govgr-body-l text-[20px] mt-4">
                        {formItem?.components[0]?.text &&
                        formItem?.components?.length > 0
                          ? parse(
                              formItem?.components?.length > 0 &&
                                formItem?.components[0]?.text + ":"
                            )
                          : null}
                      </div>
                      <div className="mt-4 pb-4 grid grid-cols-3 gap-4">
                        {formItem?.components?.map((formField, index) => {
                          if (formField.type === "group") {
                            const groupComponents = formField.components || [];

                            const groupFields = groupComponents?.filter(
                              (groupField) =>
                                groupField?.type !== "text" &&
                                groupField?.type !== "button"
                            );

                            return groupFields?.map(
                              (groupField, groupIndex) => {
                                const key = groupField?.key;
                                const baseKey = key?.split("_index")[0];

                                const formValueKeys = Object?.keys(
                                  certificatesStore.formFieldsValues
                                )?.filter((valueKey) =>
                                  valueKey?.startsWith(baseKey)
                                );

                                return formValueKeys?.map(
                                  (formValueKey, formValueIndex) => {
                                    const submittedValue =
                                      certificatesStore.formFieldsValues[
                                        formValueKey
                                      ]?.value || "";

                                    return (
                                      <div
                                        key={`${index}-${groupIndex}-${formValueIndex}`}
                                        className="flex flex-col w-full items-start justify-between"
                                        style={{
                                          minHeight: "max-content",
                                        }}
                                      >
                                        <Typography
                                          variant="body1"
                                          fontWeight={"bold"}
                                          p={2}
                                          pb={0}
                                          m={1}
                                          mb={0}
                                        >
                                          {`${groupField?.label}  ${formValueIndex}`}
                                        </Typography>

                                        <Tooltip
                                          title={
                                            formField.key
                                              ?.toString()
                                              ?.includes("file")
                                              ? "Πατήστε για να δείτε το αρχείο"
                                              : submittedValue
                                              ? formField?.type === "select"
                                                ? formField?.values?.find(
                                                    (item) =>
                                                      item?.value ===
                                                      submittedValue
                                                  )?.label
                                                : submittedValue.trim() !== ""
                                                ? submittedValue
                                                : "-"
                                              : "-"
                                          }
                                          placement="bottom"
                                        >
                                          {formField.key
                                            ?.toString()
                                            ?.includes("file") ? (
                                            <Box p={2} m={1} width={"100%"}>
                                              <FileUploader
                                                certificateSubmissionId={
                                                  certificate?.id
                                                }
                                                businessKey={
                                                  certificate?.businessKey
                                                }
                                                fieldName={formField?.key}
                                                isEditMode={true}
                                                formFieldsValues={
                                                  certificatesStore.formFieldsValues
                                                }
                                                certificateSubmissionAttachments={
                                                  certificateSubmissionAttachments
                                                }
                                              />
                                            </Box>
                                          ) : (
                                            <Typography
                                              variant="body1"
                                              border={1}
                                              borderColor="#555555"
                                              color={"#555555"}
                                              fontStyle={"italic"}
                                              p={2}
                                              m={1}
                                              mx={2}
                                              width={"100%"}
                                              height={"100%"}
                                              overflow={"auto"}
                                            >
                                              {submittedValue
                                                ? formField?.type === "select"
                                                  ? formField?.values?.find(
                                                      (item) =>
                                                        item?.value ===
                                                        submittedValue
                                                    )?.label
                                                  : submittedValue.trim() !== ""
                                                  ? submittedValue
                                                  : "-"
                                                : "-"}
                                            </Typography>
                                          )}
                                        </Tooltip>
                                      </div>
                                    );
                                  }
                                );
                              }
                            );
                          } else if (formField.id !== "description") {
                            const key = formField?.key;
                            const submittedValue =
                              certificatesStore.formFieldsValues[key]?.value ||
                              "";
                            if (submittedValue.trim() !== "") {
                              return (
                                <div
                                  key={index}
                                  className="flex flex-col w-full items-start justify-between"
                                  style={{
                                    minHeight: "max-content",
                                  }}
                                >
                                  <Typography
                                    variant="body1"
                                    fontWeight={"bold"}
                                    p={2}
                                    pb={0}
                                    m={1}
                                    mb={0}
                                  >
                                    {formField?.label}
                                  </Typography>

                                  <Tooltip
                                    title={
                                      formField.key
                                        ?.toString()
                                        ?.includes("file")
                                        ? "Πατήστε για να δείτε το αρχείο"
                                        : certificatesStore.formFieldsValues[
                                            formField?.key
                                          ]?.value
                                        ? formField?.type === "select"
                                          ? formField?.values?.find(
                                              (item) =>
                                                item?.value ===
                                                certificatesStore
                                                  .formFieldsValues[
                                                  formField?.key
                                                ]?.value
                                            )?.label
                                          : certificatesStore.formFieldsValues[
                                              formField?.key
                                            ]?.value.trim() !== ""
                                          ? certificatesStore.formFieldsValues[
                                              formField?.key
                                            ]?.value
                                          : "-"
                                        : "-"
                                    }
                                    placement="bottom"
                                  >
                                    {formField.key
                                      ?.toString()
                                      ?.includes("file") ? (
                                      <Box p={2} m={1} width={"100%"}>
                                        <FileUploader
                                          certificateSubmissionId={
                                            certificate?.id
                                          }
                                          businessKey={certificate?.businessKey}
                                          fieldName={formField?.key}
                                          isEditMode={true}
                                          formFieldsValues={
                                            certificatesStore.formFieldsValues
                                          }
                                          certificateSubmissionAttachments={
                                            certificateSubmissionAttachments
                                          }
                                        />
                                      </Box>
                                    ) : (
                                      <Typography
                                        variant="body1"
                                        border={1}
                                        borderColor="#555555"
                                        color={"#555555"}
                                        fontStyle={"italic"}
                                        p={2}
                                        m={1}
                                        mx={2}
                                        width={"100%"}
                                        height={"100%"}
                                        overflow={"auto"}
                                      >
                                        {certificatesStore.formFieldsValues[
                                          formField?.key
                                        ]?.value
                                          ? formField?.type === "select"
                                            ? formField?.values?.find(
                                                (item) =>
                                                  item?.value ===
                                                  certificatesStore
                                                    .formFieldsValues[
                                                    formField?.key
                                                  ]?.value
                                              )?.label
                                            : certificatesStore.formFieldsValues[
                                                formField?.key
                                              ]?.value.trim() !== ""
                                            ? certificatesStore
                                                .formFieldsValues[
                                                formField?.key
                                              ]?.value
                                            : "-"
                                          : "-"}
                                      </Typography>
                                    )}
                                  </Tooltip>
                                </div>
                              );
                            } else {
                              return null;
                            }
                          } else {
                            return null;
                          }
                        })}
                      </div>
                    </Accordions>
                  ))}
            </div>

            {!isSubmittingForm &&
              (certificate?.status === 0 || certificate?.status === 5) && (
                <div className="mr-4 pr-4 mt-4 flex justify-start">
                  <button
                    type="button"
                    className="govgr-btn bg-[#003375] text-[#FFFF]"
                    onClick={() => onEdit()}
                  >
                    <p>Επεξεργασία</p>
                  </button>
                </div>
              )}

            {isSubmittingForm && (
              <>
                <div className="govgr-checkboxes__item ml-24 mt-4">
                  <label className="govgr-label govgr-checkboxes__label">
                    Έχω διαβάσει και συμφωνώ με{" "}
                    <span style={{ display: "inline-block" }}>
                      τους
                      <div
                        className="govgr-masthead ml-2"
                        style={{ display: "inline-block" }}
                      >
                        <div className="govgr-link text-[#003375] hover:underline">
                          όρους χρήσης
                        </div>
                      </div>
                      <span> της υπηρεσίας</span>
                    </span>
                  </label>
                  <input
                    className="govgr-checkboxes__input"
                    type="checkbox"
                    name="none"
                    onChange={handleCheckboxChange}
                  />
                </div>
                <div className="mr-4 pr-4 ml-20 mt-6 flex justify-between">
                  <button
                    type="button"
                    className="govgr-btn bg-[#003375] text-[#FFFF]"
                    onClick={() => onEditAfterPreview()}
                  >
                    <p>Επεξεργασία</p>
                  </button>

                  {!isChecked ? (
                    <button
                      disabled={!isChecked}
                      className="govgr-btn bg-gray-200 cursor-not-allowed text-[#FFFF]"
                    >
                      <p>Υποβολή</p>
                      <svg
                        viewBox="0 0 24 24"
                        className="govgr-arrow--right govgr-svg-icon"
                        focusable="false"
                        aria-hidden="true"
                      >
                        <path
                          d="M8.5,2L6.1,4.3l7.6,7.7l-7.6,7.6L8.5,22l10-10L8.5,2z"
                          fill="white"
                        />
                      </svg>
                    </button>
                  ) : (
                    <button
                      type="button"
                      className="govgr-btn bg-[#168D8B] text-[#FFFF]"
                      onClick={() => onCompleteSubmission()}
                    >
                      <p>Υποβολή</p>
                      <svg
                        viewBox="0 0 24 24"
                        className="govgr-arrow--right govgr-svg-icon"
                        focusable="false"
                        aria-hidden="true"
                      >
                        <path
                          d="M8.5,2L6.1,4.3l7.6,7.7l-7.6,7.6L8.5,22l10-10L8.5,2z"
                          fill="white"
                        />
                      </svg>
                    </button>
                  )}
                </div>
              </>
            )}
          </>
        )}
      </div>
    </>
  );
};

export default observer(CertificatesDetails);
