import GovGrToolTipHeader from "../../../Components/GovGrToolTipHeader/GovGrToolTipHeader";
import { observer } from "mobx-react";
import GovSelect from "../../../Components/GovSelect/GovSelect";
import { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router";
import { useDepartmentStore } from "../../../Stores/DepartmentStore";
import IconText from "../../../Components/IconText/IconText";
import { certificatesStore } from "../../../Stores/CertificatesStore";
import CircularSpinner from "../../../Components/Theme/Spinners/CircularSpinner";

const CertificatesSeatSelection = () => {
  const location = useLocation();
  const departmentStore = useDepartmentStore();
  const navigate = useNavigate();
  const [selectedValue, setSelectedValue] = useState("");
  const [departmentId, setDepartmentId] = useState(null);
  const [loading, setLoading] = useState(false);
  const [certificateSubmissionId, setCertificateSubmissionId] = useState(null);

  const handleSelectChange = (event) => {
    setSelectedValue(event.target.value);
    const selectedOption = departmentStore.certificatesSeatsOptions.find(
      (option) => option.certificateId === parseInt(event.target.value)
    );
    if (selectedOption) {
      setDepartmentId(selectedOption.id);
    }
  };

  const mappedOptions = departmentStore.certificatesSeatsOptions?.map(
    (option) => ({
      value: option.certificateId,
      label: option.name,
      id: option.id,
    })
  );

  const onSeatSelectionClik = async () => {
    const payload = {
      certificateId: parseInt(selectedValue),
      departmentId: departmentId,
    };

    try {
      setLoading(true);
      const resp = await certificatesStore.startCertificateSubmission(payload);
      const result =
        await certificatesStore.getFormFieldsByCertificateSubmissionId(
          resp?.result?.id
        );

      if (result) {
        setCertificateSubmissionId(resp?.result?.id);
        navigate("/certificates-create", {
          state: {
            certificateSubmissionId: resp?.result?.id,
            selectedApplicationCategoryId:
              location.state.selectedApplicationCategoryId,
          },
        });
      } else {
        console.log("error", result);
      }
    } catch (error) {
      console.error(error);
    }
    setLoading(false);
  };

  useEffect(() => {
    if (departmentStore.certificatesSeatsOptions?.length === 1) {
      setSelectedValue(
        departmentStore.certificatesSeatsOptions[0]?.certificateId
      );
      setDepartmentId(departmentStore.certificatesSeatsOptions[0]?.id);
    }
  }, [departmentStore.certificatesSeatsOptions]);

  return (
    <>
      <div>
        <GovGrToolTipHeader />
      </div>
      <div className="ml-40 mr-40">
        <IconText
          navigation={"/certificates"}
          text={" Δημιουργία νέας αίτησης | Επιλογή περιοχής"}
        />
        <p className="govgr-body-l">
          Επιλέξτε το <b>Τμήμα / Περιοχή</b> στην οποία υπάγεστε για την έκδοση
          του πιστοποιητικού σας:
        </p>
      </div>
      <div className="ml-40">
        <div className="mt-12">
          <GovSelect
            name="select-list"
            label="Επιλέξτε..."
            value={selectedValue}
            options={mappedOptions}
            onChange={handleSelectChange}
          />
        </div>

        <button
          disabled={!selectedValue || loading}
          onClick={() => onSeatSelectionClik()}
          className={`govgr-btn ${
            selectedValue ? "bg-[#003375]" : "bg-gray-200"
          } text-[#FFFF] govgr-btn-cta mt-12`}
        >
          <div className="mb-1">Συνέχεια</div>

          {loading ? (
            <CircularSpinner
              size="20px"
              color="inherit"
              sx={{ marginRight: "15px" }}
            />
          ) : (
            <svg
              viewBox="0 0 24 24"
              className="govgr-arrow--right govgr-svg-icon"
              focusable="false"
              aria-hidden="true"
            >
              <path
                d="M8.5,2L6.1,4.3l7.6,7.7l-7.6,7.6L8.5,22l10-10L8.5,2z"
                fill="white"
              />
            </svg>
          )}
        </button>
      </div>
    </>
  );
};

export default observer(CertificatesSeatSelection);
