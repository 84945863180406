import { observer } from "mobx-react";
import { useRantevouStore } from "../../../Stores/RantevouStore";
import GovGrToolTipHeader from "../../../Components/GovGrToolTipHeader/GovGrToolTipHeader";
import { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router";
import { useScheduleStore } from "../../../Stores/ScheduleStore";
import { useForm, Controller } from "react-hook-form";
import { Box, CircularProgress, TextField } from "@mui/material";
import { error, success } from "../../../Common/Toast/Toast";
import dayjs from "dayjs";
import CircularSpinner from "../../../Components/Theme/Spinners/CircularSpinner";
import IconText from "../../../Components/IconText/IconText";
import { formatDateInDateMonthYear } from "../../../Common/formatDate/formatDate";

const RantevouDetails = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const rantevouStore = useRantevouStore();
  const scheduleStore = useScheduleStore();
  const [isChecked, setIsChecked] = useState(false);
  const [loading, setLoading] = useState(false);
  const [extraFields, setExtraFields] = useState([]);

  const dateFrom = location.state?.timeFrom.slice(0, 10);
  const hourFrom = location.state?.timeFrom.slice(11, 16);
  const hourTo = location.state?.timeTo.slice(11, 16);
  const selectedSeatName = location.state?.selectedSeatName;
  const appointmentServiceId = location.state?.appointmentServiceId;
  const slotGroupId = location.state?.slotGroupId;
  const subOrganizationId = location.state?.selectedSubOrganization;
  const applicationCategoryId = location.state?.applicationCategoryId;

  const loader = (
    <Box className="flex justify-center items-center p-20">
      <CircularProgress size={60} />
    </Box>
  );

  const { handleSubmit, control } = useForm();

  const onSubmit = (data) => {
    setLoading(true);
    const extraFieldValues = extraFields?.map((field, index) => ({
      name: field.name || "",
      value: data[`extraField${index}`]?.toString() || "",
    }));

    const payload = {
      extraFields:
        extraFieldValues.length > 0
          ? extraFieldValues?.filter((field) => field.value !== "")
          : [],
      subOrganizationId,
      applicationCategoryId,
      appointmentServiceId: parseInt(appointmentServiceId),
      slotGroupId,
      citizenComments: data.comment,
    };

    rantevouStore
      .createNewRantevouAppointment(payload)
      .then((resp) => {
        if (resp?.result?.id) {
          const appointmentId = resp?.result?.id;
          const rantevouData = resp?.result;
          navigate("/rantevou-details", {
            state: { appointmentId, rantevouData },
          });
          success("Υποβάλατε επιτυχώς το ραντεβού σας");
        } else {
          error("Κάτι πήγε στραβά. Παρακαλώ προσπαθήστε ξανά");
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleCheckboxChange = (event) => {
    setIsChecked(event.target.checked);
  };

  const parseValue = (value) => {
    const parsedValue = parseFloat(value);
    return isNaN(parsedValue) ? value : parsedValue;
  };

  useEffect(() => {
    if (
      scheduleStore.scheduleData?.find(
        (item) =>
          dayjs(dateFrom).isBefore(
            dayjs(item?.timeTo.slice(0, 10)).add(1, "day")
          ) &&
          dayjs(dateFrom).isAfter(
            dayjs(item?.timeFrom.slice(0, 10)).subtract(1, "day")
          )
      )?.appointmentService?.extraFields
    ) {
      setExtraFields(
        JSON.parse(
          scheduleStore.scheduleData?.find(
            (item) =>
              dayjs(dateFrom).isBefore(
                dayjs(item?.timeTo.slice(0, 10)).add(1, "day")
              ) &&
              dayjs(dateFrom).isAfter(
                dayjs(item?.timeFrom.slice(0, 10)).subtract(1, "day")
              )
          )?.appointmentService?.extraFields
        )
      );
    }
  }, [scheduleStore.scheduleData]);

  useEffect(() => {
    if (appointmentServiceId) {
      setLoading(true);
      scheduleStore
        .getScheduleByAppointmentServiceId({ appointmentServiceId })
        .then((resp) => {})
        .finally(() => {
          setLoading(false);
        });
    }
  }, [appointmentServiceId, scheduleStore, scheduleStore.scheduleData?.length]);

  return (
    <>
      <div>
        <GovGrToolTipHeader />
      </div>

      {loading ? (
        loader
      ) : (
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="ml-40">
            <IconText
              onClick={() =>
                navigate(-1, { state: rantevouStore.appointmentServiceId })
              }
              text={`Ηλεκτρονικό αίτημα ραντεβού | ${selectedSeatName || "-"}`}
            />
            <p className="govgr-body-l font-bold">Στοιχεία του ραντεβού σας</p>
            <ul className="mt-6">
              {scheduleStore.scheduleData?.find(
                (item) =>
                  dayjs(dateFrom).isBefore(
                    dayjs(item?.timeTo.slice(0, 10)).add(1, "day")
                  ) &&
                  dayjs(dateFrom).isAfter(
                    dayjs(item?.timeFrom.slice(0, 10)).subtract(1, "day")
                  )
              )?.appointmentService?.subDepartmentName && (
                <p className="govgr-body-l mt-4">
                  ● ΤΜΗΜΑ:{" "}
                  {
                    scheduleStore.scheduleData?.find(
                      (item) =>
                        dayjs(dateFrom).isBefore(
                          dayjs(item?.timeTo.slice(0, 10)).add(1, "day")
                        ) &&
                        dayjs(dateFrom).isAfter(
                          dayjs(item?.timeFrom.slice(0, 10)).subtract(1, "day")
                        )
                    )?.appointmentService?.subDepartmentName
                  }{" "}
                </p>
              )}

              {scheduleStore.scheduleData?.find(
                (item) =>
                  dayjs(dateFrom).isBefore(
                    dayjs(item?.timeTo.slice(0, 10)).add(1, "day")
                  ) &&
                  dayjs(dateFrom).isAfter(
                    dayjs(item?.timeFrom.slice(0, 10)).subtract(1, "day")
                  )
              )?.appointmentService?.title && (
                <p className="govgr-body-l mt-4">
                  ● ΥΠΗΡΕΣΙΑ:{" "}
                  {
                    scheduleStore.scheduleData?.find(
                      (item) =>
                        dayjs(dateFrom).isBefore(
                          dayjs(item?.timeTo.slice(0, 10)).add(1, "day")
                        ) &&
                        dayjs(dateFrom).isAfter(
                          dayjs(item?.timeFrom.slice(0, 10)).subtract(1, "day")
                        )
                    )?.appointmentService?.title
                  }{" "}
                </p>
              )}

              {dateFrom && (
                <p className="govgr-body-l mt-4">
                  ● ΗΜΕΡΟΜΗΝΙΑ: {formatDateInDateMonthYear(dateFrom)}{" "}
                </p>
              )}

              {hourFrom && hourTo ? (
                <p className="govgr-body-l mt-4">
                  ● ΩΡΑ: {hourFrom} - {hourTo}{" "}
                </p>
              ) : null}

              {scheduleStore.scheduleData?.find(
                (item) =>
                  dayjs(dateFrom).isBefore(
                    dayjs(item?.timeTo.slice(0, 10)).add(1, "day")
                  ) &&
                  dayjs(dateFrom).isAfter(
                    dayjs(item?.timeFrom.slice(0, 10)).subtract(1, "day")
                  )
              )?.appointmentService?.sectorAddress && (
                <p className="govgr-body-l mt-4">
                  ● ΔΙΕΥΘΥΝΣΗ:{" "}
                  {
                    scheduleStore.scheduleData?.find(
                      (item) =>
                        dayjs(dateFrom).isBefore(
                          dayjs(item?.timeTo.slice(0, 10)).add(1, "day")
                        ) &&
                        dayjs(dateFrom).isAfter(
                          dayjs(item?.timeFrom.slice(0, 10)).subtract(1, "day")
                        )
                    )?.appointmentService?.sectorAddress
                  }{" "}
                </p>
              )}
            </ul>

            {scheduleStore.scheduleData?.find(
              (item) =>
                dayjs(dateFrom).isBefore(
                  dayjs(item?.timeTo.slice(0, 10)).add(1, "day")
                ) &&
                dayjs(dateFrom).isAfter(
                  dayjs(item?.timeFrom.slice(0, 10)).subtract(1, "day")
                )
            )?.appointmentService?.description && (
              <>
                <p className="govgr-body-l font-bold mt-16">
                  Οδηγίες για το ραντεβού σας
                </p>

                <p className="govgr-body-l mt-6">
                  {
                    scheduleStore.scheduleData?.find(
                      (item) =>
                        dayjs(dateFrom).isBefore(
                          dayjs(item?.timeTo.slice(0, 10)).add(1, "day")
                        ) &&
                        dayjs(dateFrom).isAfter(
                          dayjs(item?.timeFrom.slice(0, 10)).subtract(1, "day")
                        )
                    )?.appointmentService?.description
                  }
                </p>
              </>
            )}

            {scheduleStore.scheduleData?.find(
              (item) =>
                dayjs(dateFrom).isBefore(
                  dayjs(item?.timeTo.slice(0, 10)).add(1, "day")
                ) &&
                dayjs(dateFrom).isAfter(
                  dayjs(item?.timeFrom.slice(0, 10)).subtract(1, "day")
                )
            )?.appointmentService?.extraFields && (
              <p className="govgr-body-l font-bold mt-16">
                Συμπληρώστε επιπλέον τα παρακάτω στοιχεία:
              </p>
            )}

            {extraFields
              ? extraFields?.map((field, index) => (
                  <ul className="list-disc ml-2" key={index}>
                    <li>
                      <Controller
                        name={`extraField${index}`}
                        control={control}
                        defaultValue={field.value}
                        rules={{
                          required: {
                            value: field.required,
                            message: "Το πεδίο είναι υποχρεωτικό",
                          },
                          pattern: {
                            value:
                              field.type === "number"
                                ? /^[0-9]*\.?[0-9]*$/
                                : /.*/,
                            message:
                              field.type === "number"
                                ? "Το πεδίο δέχεται μόνο αριθμούς και όχι κείμενο"
                                : "",
                          },
                        }}
                        render={({
                          field: { onChange, value },
                          fieldState: { error },
                        }) => (
                          <p className="govgr-body-l mt-6">
                            {field.type === "text" ? (
                              <>
                                <p className="govgr-body-l mb-4">
                                  {field.name}
                                  {field.required ? "*" : null}
                                </p>
                                <TextField
                                  className="w-[31%]"
                                  {...field}
                                  value={value}
                                  type="text"
                                  required={field.required}
                                  placeholder={field.name}
                                  size="small"
                                  onChange={(e) => onChange(e.target.value)}
                                  error={Boolean(error)}
                                  helperText={error ? error.message : null}
                                  FormHelperTextProps={{
                                    sx: {
                                      marginLeft: "0px",
                                      fontSize: "14px",
                                    },
                                  }}
                                  InputProps={{
                                    style: {
                                      fontSize: "20px",
                                    },
                                  }}
                                />
                              </>
                            ) : field.type === "number" ? (
                              <>
                                <p className="govgr-body-l mb-4">
                                  {field.name}
                                  {field.required ? "*" : null}
                                </p>
                                <TextField
                                  className="w-[31%]"
                                  {...field}
                                  value={value}
                                  type="text"
                                  placeholder={field.name}
                                  required={field.required}
                                  size="small"
                                  onChange={(e) => {
                                    onChange(parseValue(e.target.value));
                                  }}
                                  error={Boolean(error)}
                                  helperText={error ? error.message : null}
                                  FormHelperTextProps={{
                                    sx: {
                                      marginLeft: "0px",
                                      fontSize: "14px",
                                    },
                                  }}
                                  InputProps={{
                                    style: {
                                      fontSize: "20px",
                                    },
                                  }}
                                />
                              </>
                            ) : (
                              field.type === "textarea" && (
                                <>
                                  <p className="govgr-body-l mb-4">
                                    {field.name}
                                    {field.required ? "*" : null}
                                  </p>
                                  <TextField
                                    className="w-[31%]"
                                    {...field}
                                    value={value}
                                    size="small"
                                    multiline={true}
                                    maxRows={3}
                                    required={field.required}
                                    type="text"
                                    placeholder={field.name}
                                    onChange={(e) => onChange(e.target.value)}
                                    error={Boolean(error)}
                                    helperText={error ? error.message : null}
                                    FormHelperTextProps={{
                                      sx: {
                                        marginLeft: "0px",
                                        fontSize: "14px",
                                      },
                                    }}
                                    InputProps={{
                                      style: {
                                        fontSize: "20px",
                                      },
                                    }}
                                  />
                                </>
                              )
                            )}
                          </p>
                        )}
                      />
                    </li>
                  </ul>
                ))
              : null}

            <Controller
              name="comment"
              control={control}
              defaultValue=""
              render={({
                field: { onChange, value },
                fieldState: { error },
              }) => (
                <p className="govgr-body-l mt-14">
                  <p className="govgr-body-l mb-4 font-bold">Σχόλιο</p>
                  <TextField
                    className="w-[70%]"
                    value={value}
                    size="small"
                    multiline={true}
                    maxRows={3}
                    type="text"
                    placeholder="Προσθέστε το σχόλιο σας"
                    onChange={(e) => onChange(e.target.value)}
                    error={Boolean(error)}
                    helperText={error ? error.message : null}
                    FormHelperTextProps={{
                      sx: {
                        marginLeft: "0px",
                        fontSize: "14px",
                      },
                    }}
                    InputProps={{
                      style: {
                        fontSize: "20px",
                      },
                    }}
                  />
                </p>
              )}
            />
          </div>

          <div className="govgr-checkboxes__item ml-[155px] mt-6">
            <label className="govgr-label govgr-checkboxes__label mt-6">
              Έχω διαβάσει και συμφωνώ με{" "}
              <span style={{ display: "inline-block" }}>
                τους
                <div
                  className="govgr-masthead ml-2"
                  style={{ display: "inline-block" }}
                >
                  <div className="govgr-link text-[#003375] hover:underline">
                    όρους χρήσης
                  </div>
                </div>
                <span> της υπηρεσίας</span>
              </span>
            </label>
            <input
              className="govgr-checkboxes__input mt-6"
              type="checkbox"
              name="none"
              onChange={handleCheckboxChange}
            />
          </div>
          {!isChecked && (
            <button
              disabled={!isChecked}
              className="govgr-btn bg-gray-200 text-[#FFFF] govgr-btn-cta mt-16  ml-36 cursor-not-allowed"
            >
              <div className="mb-1">Υποβολή αίτησης</div>
              <svg
                viewBox="0 0 24 24"
                className="govgr-arrow--right govgr-svg-icon"
                focusable="false"
                aria-hidden="true"
              >
                <path
                  d="M8.5,2L6.1,4.3l7.6,7.7l-7.6,7.6L8.5,22l10-10L8.5,2z"
                  fill="white"
                />
              </svg>
            </button>
          )}

          {isChecked && (
            <div className="mt-16  ml-36">
              <button
                type="submit"
                className="govgr-btn bg-[#003375] text-[#FFFF] govgr-btn-cta"
              >
                <div className="mb-1">Υποβολή αίτησης</div>
                {loading ? (
                  <CircularSpinner
                    size="25px"
                    color="inherit"
                    sx={{ marginRight: "15px" }}
                  />
                ) : (
                  <svg
                    viewBox="0 0 24 24"
                    className="govgr-arrow--right govgr-svg-icon"
                    focusable="false"
                    aria-hidden="true"
                  >
                    <path
                      d="M8.5,2L6.1,4.3l7.6,7.7l-7.6,7.6L8.5,22l10-10L8.5,2z"
                      fill="white"
                    />
                  </svg>
                )}
              </button>
            </div>
          )}
        </form>
      )}
    </>
  );
};

export default observer(RantevouDetails);
