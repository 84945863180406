import ArrowCircleLeftOutlinedIcon from "@mui/icons-material/ArrowCircleLeftOutlined";
import { observer } from "mobx-react";
import { useNavigate } from "react-router";

const IconText = ({ text, navigation = null, onClick = null }) => {
  const navigate = useNavigate();

  return (
    <div className="flex items-center">
      {(navigation || onClick) && (
        <ArrowCircleLeftOutlinedIcon
          onClick={onClick ? () => onClick() : () => navigate(navigation)}
          style={{
            fill: "#004a75",
            marginRight: "14px",
            marginBottom: "10px",
            fontSize: "50px",
            cursor: "pointer",
          }}
          fontSize="large"
        />
      )}
      <h1 className="govgr-heading-l mt-6">{text}</h1>
    </div>
  );
};

export default observer(IconText);
